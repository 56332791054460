import { ErrorString, InvalidOperationException } from "../../shared";
export const min = (source, selector) => {
  if (selector) {
    return min2(source, selector);
  } else {
    return min1(source);
  }
};
const min1 = async source => {
  let minValue = null;
  for await (const item of source) {
    minValue = Math.min(minValue || Number.POSITIVE_INFINITY, item);
  }
  if (minValue === null) {
    throw new InvalidOperationException(ErrorString.NoElements);
  } else {
    return minValue;
  }
};
const min2 = async (source, selector) => {
  let minValue = null;
  for await (const item of source) {
    minValue = Math.min(minValue || Number.POSITIVE_INFINITY, selector(item));
  }
  if (minValue === null) {
    throw new InvalidOperationException(ErrorString.NoElements);
  } else {
    return minValue;
  }
};