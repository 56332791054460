import { Component, ContentChildren, QueryList, OnInit, NgZone, Renderer2, AfterViewInit, OnDestroy } from "@angular/core";

import { CdkDragDrop, CdkDrag, moveItemInArray } from "@angular/cdk/drag-drop";
import _ from "lodash";
import { Subject, Subscription, fromEvent } from "rxjs";
import { SidebarComponent } from "../../sidebar/sidebar.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import swal from "sweetalert2";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { State, process } from "@progress/kendo-data-query";
import { tap, take } from "rxjs/operators";
import { RowClassArgs } from "@progress/kendo-angular-grid";
import { IDashboard } from "projects/shared-lib/src/lib/_models/dashboard.model";
import { DashboardCopyComponent } from "projects/shared-lib/src/lib/components/dashboard-copy/dashboard-copy.component";
import { DashboardEditComponent } from "projects/shared-lib/src/lib/components/dashboard-edit/dashboard-edit.component";

const tableRow = (node) => node.tagName.toLowerCase() === "tr";

const closest = (node, predicate) => {
	while (node && !predicate(node)) {
		node = node.parentNode;
	}

	return node;
};

@Component({
	templateUrl: "./dashboard-list.component.html",
	styleUrls: ["./dashboard-list.component.scss"]
})
export class DashboardListComponent implements OnInit, AfterViewInit, OnDestroy {
	public static returned: Subject<any> = new Subject();

	public dashboards: any;
	cdkDragData: any;
	private swalWithBootstrapButtons: any;
	private updatingDashboardList: boolean = false;
	private finishedWithUpdatingDashboardList: boolean = true;
	private componentName: string = "dashboard-list: ";
	public state: State = {
		skip: 0
	};
	public gridData: any;

	private currentSubscription: Subscription;

	constructor(private dataService: DataService, public dialog: MatDialog, private router: Router, private toastr: ToastrService, private utilityService: UtilityService, private renderer: Renderer2, private zone: NgZone) {
		DashboardListComponent.returned.subscribe((res) => {
			this.getDashboardListForUser();
		});
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success"
			},
			buttonsStyling: false
		});
	}

	ngOnInit() {
		this.getDashboardListForUser();
	}

	ngAfterViewInit() {
		this.currentSubscription = this.handleDragAndDrop();
	}

	public dataStateChange(state: State): void {
		this.state = state;
		this.gridData = process(this.dashboards, this.state);
		if(this.currentSubscription){
			this.currentSubscription.unsubscribe();

		}
		this.zone.onStable.pipe(take(1)).subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
	}

	public rowCallback(context: RowClassArgs) {
		return {
			dragging: context.dataItem.dragging
		};
	}

	private handleDragAndDrop(): Subscription {
		const sub = new Subscription(() => {});
		let draggedItemIndex;

		const tableRows = Array.from(document.querySelectorAll(".k-grid tr"));
		tableRows.forEach((item) => {
			this.renderer.setAttribute(item, "draggable", "true");
			const dragStart = fromEvent<DragEvent>(item, "dragstart");
			const dragOver = fromEvent(item, "dragover");
			const dragEnd = fromEvent(item, "dragend");

			sub.add(
				dragStart
					.pipe(
						tap(({ dataTransfer }) => {
							try {
								const dragImgEl = document.createElement("span");
								dragImgEl.setAttribute("style", "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;");
								document.body.appendChild(dragImgEl);
								dataTransfer.setDragImage(dragImgEl, 0, 0);
							} catch (err) {
								// IE doesn't support setDragImage
							}
							try {
								// Firefox won't drag without setting data
								dataTransfer.setData("application/json", "");
							} catch (err) {
								// IE doesn't support MIME types in setData
							}
						})
					)
					.subscribe(({ target }) => {
						const row: HTMLTableRowElement = <HTMLTableRowElement>target;
						draggedItemIndex = row.rowIndex;
						const dataItem = this.gridData.data[draggedItemIndex];
						dataItem.dragging = true;
					})
			);

			sub.add(
				dragOver.subscribe((e: any) => {
					e.preventDefault();
					const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
					const dropIndex = closest(e.target, tableRow).rowIndex;
					const dropItem = this.gridData.data[dropIndex];

					draggedItemIndex = dropIndex;
					this.zone.run(() => this.gridData.data.splice(dropIndex, 0, dataItem));
				})
			);

			sub.add(
				dragEnd.subscribe((e: any) => {
					e.preventDefault();
					const dataItem = this.gridData.data[draggedItemIndex];
					dataItem.dragging = false;

					let ordinalValue = 1;
					var countOfDashboards = 0;
					var newDashboardList = null;
					this.updatingDashboardList = true;
					var finishedWithUpdate = false;
					var requestJSON: any = [];
					this.gridData.data.forEach((d: any) => {
						d.Ordinal = ordinalValue;
						ordinalValue++;
						var sqlStatement = this.dataService.addOrUpdateDashboardRecord(d);
						var dashboardSQLObject = {
							label: "Dashboard-" + d.Ordinal,
							sqlStatement: sqlStatement
						};
						requestJSON.push(dashboardSQLObject);
						countOfDashboards++;
					});
					Global.User.DebugMode && console.log(this.componentName + "Dashboard List drag-and-drop requestJSON = %O", requestJSON);
					this.dataService.SQLMultiAction(requestJSON).then((data: any) => {
						Global.User.DebugMode && console.log(this.componentName + "SQLMultiAction for requestJSON = %O", data);
						var collections: any = data;
						collections.forEach((collection: any) => {
							if (collection.label == "Dashboard-" + countOfDashboards) {
								newDashboardList = collection.data; //-- get last update of the dashboards for the final list.
								this.updateMenu(newDashboardList); //-- only when we're finished updating all of the dashboards for the new ordinal value do we need to notify the Sidebar menu to rebuild itself.
							}
						});
					});
				})
			);
		});

		return sub;
	}

	openDashboardEdit(id: number): void {
		const dashboardEditModal = this.dialog.open(DashboardEditComponent, {
			width: "60%",
			height: "80%",
			data: id,
			panelClass: "no-overflow"
		});

		dashboardEditModal.afterClosed().subscribe((result) => {
			//if not submitted, then result is undefined
			Global.User.DebugMode && console.log(this.componentName + "dashboardEditModal result = %O", result);
			// if (result !== undefined) {
			this.getDashboardListForUser();
			// }
		});
	}

	deleteDashboard(dashboard: IDashboard) {
		var sqlStatement = "API.DeleteDashboard " + dashboard.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then(
			// remaining dashboards for this user will be returned from the delete dashboard stored procedure.  Update the list with the data that is returned. --Kirk T. Sherer, August 28, 2020.
			(data: any) => {
				Global.User.DebugMode && console.log(this.componentName + "Dashboard id " + dashboard.Id + " deleted.");
				this.dataService.buildDashboardAndDashboardObjectAfterEdit(data);
				this.dataService.setUserDashboardList(data);

				this.getDashboardListForUser();
				// then alerting the user the dashboard has been deleted.
				this.utilityService.showToastMessageShared({
					type: "info",
					message: "'" + dashboard.Name + "' has been DELETED.",
					title: "Dashboards"
				});
				// this.toastr.info("'" + dashboard.Name + "' has been DELETED.", "Dashboards");
				_.remove(this.dashboards, function (n: any) {
					return n.Id === dashboard.Id;
				});
			}
		);
	}

	openCopyDialog(dashboard: any): void {
		const dashboardCopyModal = this.dialog.open(DashboardCopyComponent, {
			width: "50%",
			data: dashboard,
			panelClass: "overflow-y"
		});

		dashboardCopyModal.afterClosed().subscribe((result) => {
			//if not submitted, then result is undefined
			Global.User.DebugMode && console.log(this.componentName + "dashboardCopyModal result = %O", result);
			if (result !== undefined && result != 999999) {
				this.copyDashboard(dashboard, result);
			}
		});
	}

	copyDashboard(dashboard: any, listOfUserIds?: any) {
		Global.User.DebugMode && console.log(this.componentName + "copyDashboard -> listOfUserIds = " + listOfUserIds + ", Global.User.currentUser.Id = " + Global.User.currentUser.Id);
		var singleCopyToCurrentLoggedInUser = listOfUserIds.indexOf(",") <= 0 && +listOfUserIds == Global.User.currentUser.Id;
		var sqlStatement = "API.Dashboards_CopyDashboard @DashboardId=" + dashboard.Id;
		var currentUserIsPartOfCopyOperation = false;
		var dataArray: any = null;
		if (!singleCopyToCurrentLoggedInUser) {
			var arrayOfUsersIds = listOfUserIds.split(",");
			var requestJSON: any = [];
			arrayOfUsersIds.forEach((userId:string) => {
				var jsonObject = {
					label: "User_" + userId,
					sqlStatement: sqlStatement + ", @ToUserId=" + userId
				};
				requestJSON.push(jsonObject);
				if (+userId == Global.User.currentUser.Id) {
					currentUserIsPartOfCopyOperation = true;
				}
			});
			console.log("requestJSON = %O", requestJSON);
			this.dataService.SQLMultiAction(requestJSON).then((data: any) => {
				var collections: any = data;
				Global.User.DebugMode && console.log(this.componentName + "Dashboard id " + dashboard.Id + " copied to selected users.");
				this.utilityService.showToastMessageShared({
					type: "info",
					message: "'" + dashboard.Name + (dashboard.Description != "null" ? " : " + dashboard.Description : "")  + "' has been copied to the selected list of people.",
					title: "Dashboards"
				});
				//-- Need to find in the data which one might be tied to the current logged-in user and provide the updated Dashboard List from that set of data. --Kirk T. Sherer, June 27, 2023
				if (currentUserIsPartOfCopyOperation) {
					var data = collections.firstOrDefault((collection:any) => { return collection.label == "User_" + Global.User.currentUser.Id }).data;
					this.getDashboardListForUser(data);
				}
			});
		}
		else {
			this.dataService.SQLActionAsPromise(sqlStatement).then(
				// newest list of dashboards with the copied dashboard will be returned for this user in the copy dashboard stored procedure.  Update the list with the data that is returned. --Kirk T. Sherer, August 28, 2020.
				(data: any) => {
					Global.User.DebugMode && console.log(this.componentName + "Dashboard id " + dashboard.Id + " copied. New dashboard list: %O", data);
					this.utilityService.showToastMessageShared({
						type: "info",
						message: "'" + dashboard.Name + (dashboard.Description != "null" ? " : " + dashboard.Description : "")  + "' has been copied.",
						title: "Dashboards"
					});
					//   this.toastr.info("'" + dashboard.Name + "' has been copied.", "Dashboards");
					this.getDashboardListForUser(data);
				}
			);
		}
	}

	getDashboardListForUser(updatedDashboardList?: any) {
		if (!updatedDashboardList) {
			//-- if we haven't made any changes to the list of dashboards, then we haven't been sent a new updatedDashboardList. So get the list from the Global.User.currentUser.Dashboards list. --Kirk T. Sherer, November 29, 2021.
			this.setDashboardListForUser(Global.User.currentUser.Dashboards);
		} else {
			//--the delete stored procedure (API.DeleteDashboard) is already running the stored procedure for retrieving dashboards for this user, so we already have the dashboard list. --Kirk T. Sherer, August 29, 2020.
			Global.User.DebugMode && console.log(this.componentName + "updatedDashboardList = %O", updatedDashboardList);
			this.updateMenu(updatedDashboardList);
		}
	}

	updateMenu(dashboardList: any) {
		if (this.updatingDashboardList) {
			//-- user has updated their dashboard list, so set the Global.User.currentUser.Dashboards to the new list and notify the SidebarComponent that the dashboard was updated. --Kirk T. Sherer, December 1, 2021.
			this.dataService.buildDashboardAndDashboardObjectAfterEdit(dashboardList);
			SidebarComponent.dashboardUpdated$.next(true);
			this.updatingDashboardList = false;
		}
		if (Global.User.needToBuildMenu) {
			this.dataService.needToBuildMenu$.next(Global.User.needToBuildMenu);
		}

		this.setDashboardListForUser(dashboardList);
	}

	setDashboardListForUser(dashboardList: any) {
		if (dashboardList.length == 0) {
			// user no longer has any more dashboards since they deleted all of them.  Re-route to the demo dashboard. --Kirk T. Sherer, April 22, 2020.
			Global.CurrentMenuItem = "Welcome Screen";

			this.router.navigate(["layout/dashboard-demo"]);
		}
		this.dashboards = dashboardList;
		this.gridData = process(this.dashboards, this.state);
	}

	updateMenuItem(dashboard: any) {
		Global.CurrentMenuItem = dashboard.Name;
	}

	onDelete(dashboard: IDashboard) {
		Global.User.DebugMode && console.log(dashboard);
		this.swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html: "This will remove the <strong>" + dashboard.Name + (dashboard.Description != null ? " : " + dashboard.Description : "") + "</strong> dashboard. This will remove all widgets on the dashboard as well as the dashboard itself.  You won't be able to revert this." + (dashboard.IsTemplate == '1' ? " <strong>Warning:</strong> You are about to delete a dashboard template. It will no longer be available as a selection to create new dashboards." : ""),
				showCancelButton: true,
				confirmButtonText: "Delete Dashboard",
				cancelButtonText: "Cancel",
				reverseButtons: false
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					this.deleteDashboard(dashboard);
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.utilityService.showToastMessageShared({
						type: "info",
						message: "'" + dashboard.Name + "' has NOT been deleted.",
						title: "Dashboards"
					});
					//   this.toastr.info("'" + dashboard.Name + "' has NOT been deleted.", "Dashboards");
				}
			});
	}

	ngOnDestroy() {
		if(this.currentSubscription){
			this.currentSubscription.unsubscribe();

		}
	}
}
