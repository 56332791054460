import { Component, AfterViewInit, ViewChild, ElementRef, NgZone } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { filter } from 'rxjs';

import { ResizeResult } from "ngxtension/resize";

import _ from "lodash";

import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";

import { SassHelperComponent } from "../../_helpers/sass-helper/sass-helper.component";

import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { GridSettings } from "projects/shared-lib/src/lib/_models/grid-settings.interface";

import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { SignalRCoreService } from '../../services/signalr-core.service';

import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';

declare var Highcharts: any;

@Component({
	selector: 'lib-ninja-statistics-content',
	templateUrl: './ninja-statistics-content.component.html',
	styleUrls: ['./ninja-statistics-content.component.scss'],
})
export class NinjaStatisticsContentComponent implements AfterViewInit {
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;

	@ViewChild(SassHelperComponent)
	private sassHelper: SassHelperComponent;
	Highcharts: typeof Highcharts = Highcharts;

	public style: any = 'smooth';
	fullDataCacheSubscription: any;
	public global: IGlobal = Global;
	signalRUpdateSubscription: any;
	gridData = [];
	public grid = {
		name: 'Ninja Statistics',
		keepLast50Group: new FormGroup({
			keepLast50: new FormControl(true),
		}),
		keepLast50: true,
		title: 'Ninja Statistics',
		data: [],
		chartData: [],
		gridSettings: {
			state: {
				skip: 0,
				filter: {
					logic: 'and',
					filters: [],
				},
				take: 15,
			},
			columnsConfig: [],
		} as GridSettings,
	};
	public seriesArray: any[] = [];
	public AverageObservationsPerSecondChartData = [];
	public DeletionQueueLengthChartData = [];
	public ObservationInsertQueueLengthChartData = [];
	public PausedChartData = [];
	public TagStructureQueueLengthChartData = [];

	public isLoading: boolean = false;
	@ViewChild('kendoGrid') kendoGrid;
	@ViewChild('calculatedDataRow') calculatedDataRow: ElementRef;
	rowHeight = 26;
	eventDataPageSize: number;
	gridLineColor: string = 'white';
	axisItemColor: string = 'white';
	chartBackgroundColor: string = '#27304C';

	colorChangedSubscription: any;
	theme: string;
	chartLabelColors: string;
	chart: any;
	chartHasBeenDrawn: boolean = false;
	gridOrderedArray: any[];
	NinjaUpdateInterval: any;
	public componentName: string = 'ninja-statistics: ';
	tableData: any[];
	parentSystemToJoin: any;
	public widgetGroupSettings: any;

	constructor(
		private dataService: DataService,
		private zone: NgZone,
		private signalRCore: SignalRCoreService
	) {}

	ngAfterViewInit() {
		this.parentSystemToJoin = 964255;
		this.isLoading = true;

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}
	}

	initialize() {
		Global.User.DebugMode &&
			console.log(this.componentName + 'intializing Ninja Statistics...');
		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === 'light') {
					this.CreateTheme('light');
				} else {
					this.CreateTheme('dark');
				}
				this.setupAndDeployChart(true);
			});
		if (Global.Theme === 'light') {
			this.CreateTheme('light');
		} else {
			this.CreateTheme('dark');
		}

		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(
				this.parentSystemToJoin
			)
			.subscribe((data) => {
				console.log(data);
				let assetObjectInCache =
					this.dataService.cache.assetsObject[
						this.parentSystemToJoin
					];


				this.widgetGroupSettings = {
					WidgetId: 999,
					GroupList: 'PS' + assetObjectInCache.ParentSystemId,
					IsPopup: false
				}
				//this.signalRCore.joinParentSystemGroupsThroughSignalR(this.widgetGroupSettings);

				this.seriesArray = [];
				let dt = new Date();
				this.tableData = [];
				let tableDataInsert: any = {};
				console;
				this.dataService.cache.assetsObject[
					this.parentSystemToJoin
				].Tags.forEach((tag: any) => {
					if (
						!this.removeNonTrendableTags(tag) &&
						tag.JBTStandardObservation !== undefined
					) {
						let tagObject = {
							tagId: tag.TagId,
							name: tag.JBTStandardObservation.Name,
							data: [],
						};
						tagObject.data.push([
							dt.getTime(),
							parseInt(tag.Value),
						]);
						this.seriesArray.push(tagObject);
						this.grid.gridSettings.columnsConfig.push({
							field: tagObject.name,
							title: tagObject.name,
							filterable: true,
							_width: 200,
							hidden: !this.evaluateIfSeriesShouldBeShownAtStartUp(
								tagObject
							)
								? true
								: false,
						});
						tableDataInsert[tagObject.name] = parseInt(tag.Value);
					} else {
						console.log(tag);
					}
				});
				tableDataInsert.trueDateObject = dt;
				this.tableData.push(tableDataInsert);

				this.grid.gridSettings.columnsConfig.push({
					field: 'trueDateObject',
					title: 'Current Date Time',
					filterable: true,
					_width: 200,
					hidden: false,
				});
				console.log(this.seriesArray);

				this.setupSubscriberForNinjaStatistics();

				this.signalRCore.broadcastMessages$
					.pipe(
						filter((msg: any) =>
							msg.groupName?.includes('PS103285')
						)
					)
					.subscribe((data) => {
						var signalRData: any = data;
						console.log('SignalR System Data = %O', signalRData);
						this.insertRecordsIntoCharts(
							this.dataService.cache.assetsObject[
								this.parentSystemToJoin
							].Tags
						);
					});
			});
	}

	CreateTheme(theme: string) {
		if (theme === 'light') {
			this.theme = 'light';
			this.chartBackgroundColor = 'white';
			this.chartLabelColors = 'grey';
			this.gridLineColor = 'grey';
			this.axisItemColor = 'grey';
		} else {
			this.theme = 'dark';
			this.chartBackgroundColor = '#27304C';
			this.chartLabelColors = 'white';
			this.gridLineColor = 'white';
			this.axisItemColor = 'white';
		}
	}

	insertRecordsIntoCharts(records) {
		let dt = new Date();
		let tableDataInsert: any = {};
		records.forEach((tag) => {
			if (
				!this.removeNonTrendableTags(tag) &&
				tag.JBTStandardObservation !== undefined
			) {
				let seriesIndex = this.seriesArray.findIndex((series) => {
					return series.tagId === tag.TagId;
				});
				let dataUpdate = [dt.getTime(), parseInt(tag.Value)];

				this.seriesArray[seriesIndex].data.push(dataUpdate);
				tableDataInsert[tag.JBTStandardObservation.Name] = parseInt(
					tag.Value
				);
			}
		});
		tableDataInsert.trueDateObject = dt;
		this.tableData.unshift(tableDataInsert);
	}

	setupAndDeployChart(themeChanged?) {
		if (this.chartHasBeenDrawn === false || themeChanged) {
			let chartOptions = {
				chart: {
					backgroundColor:
						this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
					style: {
						fontFamily: 'Poppins, sans-serif',
						color:
							this.theme === 'dark'
								? this.sassHelper.readProperty(
										'body-text-color-light'
								  )
								: this.sassHelper.readProperty(
										'body-text-color-dark'
								  ),
					},
				},

				accessibility: {
					enabled: false,
				},

				time: {
					useUTC: false,
				},
				plotOptions: {
					series: {
						events: {
							legendItemClick: (series) => {
								console.log(series);
								let foundIndex =
									this.grid.gridSettings.columnsConfig.findIndex(
										(column) => {
											return (
												column.field ===
												series.target.name
											);
										}
									);
								if (foundIndex !== -1) {
									this.grid.gridSettings.columnsConfig[
										foundIndex
									].hidden = series.target.visible;
									if (this.tagDataGrid) {
										this.tagDataGrid
											.gridDataSubject.next(this.tableData);
									}
								}
							},
						},
					},
				},
				legend: {
					enabled: true,
					itemStyle: {
						color:
							this.theme === 'dark'
								? this.sassHelper.readProperty(
										'body-text-color-light'
								  )
								: this.sassHelper.readProperty(
										'body-text-color-dark'
								  ),
					},
				},
				credits: { enabled: false },

				rangeSelector: {
					enabled: false,
				},

				exporting: {
					enabled: false,
				},
				labels: {
					style: {
						color:
							this.theme === 'dark'
								? this.sassHelper.readProperty(
										'body-text-color-light'
								  )
								: this.sassHelper.readProperty(
										'body-text-color-dark'
								  ),
					},
				},
				tooltip: {
					pointFormatter: function () {
						let uToFixed = function (
							number: number,
							fractionSize: number
						) {
							if (+number == 0) {
								return number;
							}
							const fixed = +(
								Math.round(
									+(number.toString() + 'e' + fractionSize)
								).toString() +
								'e' +
								-fractionSize
							);
							let fixedNumberAsString = fixed.toString();

							if (fixedNumberAsString.indexOf('.') < 0) {
								fixedNumberAsString += '.0';
							}
							return fixedNumberAsString;
						};
						let tToFixed = function (number, fractionSize) {
							if (+number == 0) {
								return number;
							}
							if (number) {
								var returnNumber = +(
									Math.round(
										+(
											number.toString() +
											'e' +
											fractionSize
										)
									).toString() +
									'e' +
									-fractionSize
								);
								return returnNumber || number;
							}
						};
						return (
							'<span style="color:' +
							this.color +
							'">' +
							this.series.name +
							'</span>: <b>' +
							(this.series.options.isDigital
								? tToFixed(this.y, 0)
								: uToFixed(this.y, 1)) +
							'</b><br/></span>'
						);
					},
					valueDecimals: 2,
					split: true,
					shared: false,
					followPointer: true,
				},

				series: this.seriesArray,
			};

			this.zone.runOutsideAngular(() => {
				this.chart = Highcharts.stockChart(
					'ninjaStatisticsChart',
					chartOptions
				);
			});
			this.chart.series.forEach((currentSeries, index) => {
				if (
					!this.evaluateIfSeriesShouldBeShownAtStartUp(currentSeries)
				) {
					currentSeries.hide();
				}
			});
		} else {
			this.chart.series.forEach((currentSeries, index) => {
				if (currentSeries.name !== 'Navigator 1') {
					if (index !== this.chart.series.length - 2) {
						currentSeries.setData(
							this.seriesArray[index].data,
							false
						);
					} else {
						currentSeries.setData(
							this.seriesArray[index].data,
							true
						);
					}
				}
			});
		}
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.tableData);;
		}

		this.isLoading = false;
	}

	private removeNonTrendableTags(tag): boolean {
		let listOfTagsToIgnoreByShortName = [
			'IOPS|NINJA|STATISTICS|RUNNINGTIME',
			'IOPS|NINJA|STATISTICS|REDISREADERSTATUS',
			'IOPS|NINJA|STATISTICS|HIGHESTREDISLOCKHOLDERCOUNTDESCRIPTION',
			'IOPS|NINJA|STATISTICS|HIGHESTREDISLOCKHOLDERTIMEDESCRIPTION',
			'IOPS|NINJA|STATISTICS|TAGCREATIONS',
		];
		if (listOfTagsToIgnoreByShortName.includes(tag.ShortTagName)) {
			return true;
		} else {
			return false;
		}
	}

	private evaluateIfSeriesShouldBeShownAtStartUp(seriesOrTagObject): boolean {
		if (
			seriesOrTagObject.name.includes('Queue') ||
			seriesOrTagObject.name.includes('Average Observations per Second')
		) {
			return true;
		} else {
			return false;
		}
	}

	tabHasChanged(event) {
		this.setupAndDeployChart();
	}

	setupSubscriberForNinjaStatistics() {
		this.setupAndDeployChart();
		this.chartHasBeenDrawn = true;
		this.NinjaUpdateInterval = setInterval(() => {
			this.setupAndDeployChart();
		}, 10000);
	}

	onResized(event: ResizeResult): void {
		this.eventDataPageSize = Math.floor(((event.height - 120) / this.rowHeight) * 3);
	}

	ngOnDestroy() {
		clearTimeout(this.NinjaUpdateInterval);
		if (!_.isNil(this.widgetGroupSettings)) {
			//this.signalRCore.leaveParentSystemGroupsThroughSignalR(this.widgetGroupSettings);
		}

	}
}
