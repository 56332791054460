import { StrictEqualityComparer } from "../../shared";
/**
 * Determines whether a sequence contains a specified element by using the specified or default IEqualityComparer<T>.
 * @param source A sequence in which to locate a value.
 * @param value The value to locate in the sequence.
 * @param comparer An equality comparer to compare values. Optional.
 * @returns Whether a sequence contains a specified element
 */
export const contains = async (source, value, comparer = StrictEqualityComparer) => {
  for await (const item of source) {
    if (comparer(value, item)) {
      return true;
    }
  }
  return false;
};