import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
/**
 * Produces the set union of two sequences by using scrict equality comparison or a specified IEqualityComparer<T>.
 * @param first An AsyncIterable<T> whose distinct elements form the first set for the union.
 * @param second An AsyncIterable<T> whose distinct elements form the second set for the union.
 * @param comparer The IEqualityComparer<T> to compare values. Optional.
 * @returns An IAsyncEnumerable<T> that contains the elements from both input sequences, excluding duplicates.
 */
export const union = (first, second, comparer) => {
  if (comparer) {
    return union2(first, second, comparer);
  } else {
    return union1(first, second);
  }
};
const union1 = (first, second) => {
  async function* iterator() {
    const set = new Set();
    for await (const item of first) {
      if (set.has(item) === false) {
        yield item;
        set.add(item);
      }
    }
    for await (const item of second) {
      if (set.has(item) === false) {
        yield item;
        set.add(item);
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const union2 = (first, second, comparer) => {
  async function* iterator() {
    const result = [];
    for (const source of [first, second]) {
      for await (const value of source) {
        let exists = false;
        for (const resultValue of result) {
          if (comparer(value, resultValue) === true) {
            exists = true;
            break;
          }
        }
        if (exists === false) {
          yield value;
          result.push(value);
        }
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};