import { fromParallel } from "../../parallel/static/fromParallel";
/**
 * Converts an async iterable to a Parallel Enumerable.
 * @param source AsyncIterable<T> to convert to IParallelEnumerable<T>
 * @returns Parallel Enumerable of source
 */
export const asParallel = source => {
  const generator = async () => {
    const data = [];
    for await (const value of source) {
      data.push(value);
    }
    return data;
  };
  return fromParallel(0 /* PromiseToArray */, generator);
};