import { BasicEnumerable } from "../BasicEnumerable";
/**
 * Concatenates two sequences.
 * @param first The first sequence to concatenate.
 * @param second The sequence to concatenate to the first sequence.
 * @returns An IEnumerable<T> that contains the concatenated elements of the two input sequences.
 */
export const concatenate = (first, second) => {
  function* iterator() {
    yield* first;
    yield* second;
  }
  return new BasicEnumerable(iterator);
};