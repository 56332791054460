import { ArgumentOutOfRangeException, ErrorString, InvalidOperationException } from "../shared";
import { ArrayEnumerable } from "../sync/ArrayEnumerable";
import { BasicEnumerable } from '../sync/BasicEnumerable';
/**
 * @private
 */
export const bindArrayEnumerable = () => {
  var _a;
  const {
    prototype
  } = ArrayEnumerable;
  const propertyNames = Object.getOwnPropertyNames(BasicEnumerable.prototype);
  for (const prop of propertyNames) {
    prototype[prop] = (_a = prototype[prop]) !== null && _a !== void 0 ? _a : BasicEnumerable.prototype[prop];
  }
  prototype.all = function (predicate) {
    return this.every(predicate);
  };
  prototype.any = function (predicate) {
    if (predicate) {
      return this.some(predicate);
    } else {
      return this.length !== 0;
    }
  };
  prototype.count = function (predicate) {
    if (predicate) {
      // eslint-disable-next-line no-shadow
      let count = 0;
      for (let i = 0; i < this.length; i++) {
        if (predicate(this[i]) === true) {
          count++;
        }
      }
      return count;
    } else {
      return this.length;
    }
  };
  prototype.elementAt = function (index) {
    if (index < 0 || index >= this.length) {
      throw new ArgumentOutOfRangeException("index");
    }
    return this[index];
  };
  prototype.elementAtOrDefault = function (index) {
    return this[index] || null;
  };
  prototype.first = function (predicate) {
    if (predicate) {
      const value = this.find(predicate);
      if (value === undefined) {
        throw new InvalidOperationException(ErrorString.NoMatch);
      } else {
        return value;
      }
    } else {
      if (this.length === 0) {
        throw new InvalidOperationException(ErrorString.NoElements);
      }
      return this[0];
    }
  };
  prototype.firstOrDefault = function (predicate) {
    if (predicate) {
      const value = this.find(predicate);
      if (value === undefined) {
        return null;
      } else {
        return value;
      }
    } else {
      return this.length === 0 ? null : this[0];
    }
  };
  prototype.last = function (predicate) {
    if (predicate) {
      for (let i = this.length - 1; i >= 0; i--) {
        const value = this[i];
        if (predicate(value) === true) {
          return value;
        }
      }
      throw new InvalidOperationException(ErrorString.NoMatch);
    } else {
      if (this.length === 0) {
        throw new InvalidOperationException(ErrorString.NoElements);
      }
      return this[this.length - 1];
    }
  };
  prototype.lastOrDefault = function (predicate) {
    if (predicate) {
      for (let i = this.length - 1; i >= 0; i--) {
        const value = this[i];
        if (predicate(value) === true) {
          return value;
        }
      }
      return null;
    } else {
      return this.length === 0 ? null : this[this.length - 1];
    }
  };
  prototype.max = function (selector) {
    if (this.length === 0) {
      throw new InvalidOperationException(ErrorString.NoElements);
    }
    if (selector) {
      // eslint-disable-next-line no-shadow
      let max = Number.NEGATIVE_INFINITY;
      for (let i = 0; i < this.length; i++) {
        max = Math.max(selector(this[i]), max);
      }
      return max;
    } else {
      return Math.max.apply(null, this);
    }
  };
  prototype.min = function (selector) {
    if (this.length === 0) {
      throw new InvalidOperationException(ErrorString.NoElements);
    }
    if (selector) {
      // eslint-disable-next-line no-shadow
      let min = Number.POSITIVE_INFINITY;
      for (let i = 0; i < this.length; i++) {
        min = Math.min(selector(this[i]), min);
      }
      return min;
    } else {
      return Math.min.apply(null, this);
    }
  };
  prototype.reverse = function () {
    Array.prototype.reverse.apply(this);
    return this;
  };
};