import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
/**
 * Inverts the order of the elements in a sequence.
 * @param source A sequence of values to reverse.
 * @returns A sequence whose elements correspond to those of the input sequence in reverse order.
 */
export const reverse = source => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 1 /* ArrayOfPromises */:
      {
        const generator = () => {
          return dataFunc.generator().reverse();
        };
        return new BasicParallelEnumerable({
          generator,
          type: dataFunc.type
        });
      }
    case 2 /* PromiseOfPromises */:
      {
        const generator = async () => {
          const array = await dataFunc.generator();
          return array.reverse();
        };
        return new BasicParallelEnumerable({
          generator,
          type: dataFunc.type
        });
      }
    case 0 /* PromiseToArray */:
      {
        const generator = async () => {
          const array = await dataFunc.generator();
          return array.reverse();
        };
        return new BasicParallelEnumerable({
          generator,
          type: dataFunc.type
        });
      }
  }
};