import { nextIterationAsync } from "./_nextIterationAsync";
/**
 * Returns how many elements in the specified sequence satisfy a condition
 * @param source A sequence that contains elements to be counted.
 * @param predicate A function to test each element for a condition.
 * @returns How many elements in the specified sequence satisfy the provided predicate.
 */
export const countAsync = async (source, predicate) => {
  const data = nextIterationAsync(source, predicate);
  let countPromise;
  switch (data.type) {
    case 1 /* ArrayOfPromises */:
      countPromise = Promise.all(data.generator());
      break;
    case 2 /* PromiseOfPromises */:
      countPromise = Promise.all(await data.generator());
      break;
  }
  let totalCount = 0;
  for (const value of await countPromise) {
    if (value) {
      totalCount++;
    }
  }
  return totalCount;
};