import { fromParallel } from "../../parallel/static/fromParallel";
/**
 * Converts an iterable to @see {IParallelEnumerable}
 * @param source Sequence to convert
 * @returns An IParallelEnumerable<T>
 */
export const asParallel = source => {
  const generator = async () => {
    const array = [];
    for (const value of source) {
      array.push(value);
    }
    return array;
  };
  return fromParallel(0 /* PromiseToArray */, generator);
};