import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import _ from "lodash";

import  moment from "moment";

import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { QuestionBase } from "../../_models/dynamic-fields/questions/question-base";
import { TextboxQuestion } from "../../_models/dynamic-fields/questions/question-textbox";
import { TextareaQuestion } from "../../_models/dynamic-fields/questions/question-textarea";
import { UibButtonQuestion } from "../../_models/dynamic-fields/questions/question-uib-button";
import { ReadOnlyQuestion } from "../../_models/dynamic-fields/questions/question-read-only";
import { DropdownQuestion } from "../../_models/dynamic-fields/questions/question-dropdown";
import { DateQuestion } from "../../_models/dynamic-fields/questions/question-date";
import { SliderYesNoQuestion } from "../../_models/dynamic-fields/questions/question-slider-yes-no";
import { DashboardService } from "../../services/dashboard.service";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";

import swal from "sweetalert2";

@Component({
	selector: "lib-dashboard-edit",
	templateUrl: "./dashboard-edit.component.html",
	styleUrls: ["./dashboard-edit.component.scss"]
})
export class DashboardEditComponent implements OnInit {
	public title: string;
	public subTitle: string;
	private dashboardId: number;
	public formOptions: any;
	public dashboardTimeScopes: any;
	public dashboard: any;

	public addingNewDashboard: boolean = false;
	public editFieldsTemplate: QuestionBase<any>[];
	public editFieldsCustom: QuestionBase<any>[];
	public dashboardTimeScopesOptionsList: any;
	public tacticalDashboardUpdateIntervalOptionsList: any = [];
	public service: any;
	isLoading: boolean;
	public myDialogRef: any;
	public dashboardTypesList: any = [
		{ Id: 1, Name: "Standard" },
		{ Id: 2, Name: "Tactical" }
	];
	public allowedDashboardTypesList: any = [];

	public applicationTypes: any = [
		{ Id: "0", Name: "Desktop" },
		{ Id: "1", Name: "Mobile" }
	];
	dashboardTimeZonesOptionsList: any = [];

	dashboardTemplatesList: any = [];
	dashboardCreationType: any;
	dashboardTemplatesListFullDataWithWidgets:any = [];

	private swalWithBootstrapButtons: any;

	private peopleInOrg: any;
	private numberOfPeopleInOrg: any;
	private listOfUserIds: any;

	loadingMessage:any;
	currentTemplateDescription: any;

	constructor(public dialogRef: MatDialogRef<DashboardEditComponent>, @Inject(MAT_DIALOG_DATA) public id: number, private dataService: DataService, public dashboardService: DashboardService, public utilityService: UtilityService) {
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success"
			},
			buttonsStyling: false
		});
	}

	ngOnInit() {
		this.loadingMessage = "'Loading'";
		this.service = this;
		console.log("this.service  = %O", this.service);
		this.myDialogRef = this.dialogRef;
		console.log("this.myDialogRef  = %O", this.myDialogRef);
		this.isLoading = true;
		console.log("this.id = " + this.id + ", dialogRef = %O", this.dialogRef);
		this.dashboardTimeScopes = Global.User.currentUser.DashboardTimeScopes;
		console.log("getDashboardTimeScopes data = %O", this.dashboardTimeScopes);
		this.dashboardTimeScopesOptionsList = [];
		console.log(Global.User.currentUser.TacticalDashboardUpdateIntervals);
		Global.User.currentUser.TacticalDashboardUpdateIntervals.forEach((t: any) => {
			var obj: any = {};
			obj.Id = t.Id;
			obj.Name = t.Description;
			this.tacticalDashboardUpdateIntervalOptionsList.push(obj);
		});

		Global.User.currentUser.DashboardTimeZones.forEach((t: any) => {
			var obj: any = {};
			obj.Id = t.Id;
			obj.Name = t.Description;
			this.dashboardTimeZonesOptionsList.push(obj);
		});
		this.dashboardTimeScopes.forEach((t: any) => {
			var obj: any = {};
			obj.key = t.Id;
			obj.value = t.Description;
			this.dashboardTimeScopesOptionsList.push(obj);
		});
		console.log(Global.User.currentUser.Security);
		this.dashboardTypesList.forEach((dt: any) => {
			Global.User.currentUser.Security.AllowedDashboardTypeIds.forEach((id: number) => {
				if (dt.Id == id) {
					this.allowedDashboardTypesList.push(dt);
				}
			});
		});

		//
		// dashboard templates
		//

		// if admin - get the list of users in the org
		if(Global.User.currentUser.IsSystemAdministrator == true || Global.User.currentUser.IsOrganizationAdministrator == true) {
			var orgs = this.dataService.cache.Organizations;
			var admin = Global.User.currentUser;
			this.peopleInOrg = this.dataService.cache.people.filter(p => p.OrganizationId == Global.User.currentUser.Organization.Id);
			this.numberOfPeopleInOrg = this.peopleInOrg.length;
			this.listOfUserIds = this.peopleInOrg.map(p => p.UserId).join(',');
			Global.User.DebugMode && console.log("list of user ids = %O", this.listOfUserIds);
		}

		// get the list of templates for the user
		var appType = Global.isMobile == true ? 'mobile' :'desktop';
		var sql = "API.GetDashboardTemplatesForUser @UserId = " + Global.User.currentUser.Id + ", @ApplicationType =" + appType;
		this.dataService.SQLActionAsPromise(sql).then((data: any) => {
			Global.User.DebugMode && console.log("dashboard templates for org = %O", data);
			if(data.length > 0) {

				data.forEach((t: any) => {
					var obj: any = {};
					obj.key = t.Id;
					obj.value = t.Name + (t.Description == null ? '' : ' - ' + t.Description);
					this.dashboardTemplatesList.push(obj);

					this.dashboardTemplatesListFullDataWithWidgets.push(t);

					this.loadExpandedDashboards(t);
				});
			}
			else {
				this.buildEditForm();
			}


		});


	}


	copyDashboardTemplateToUsers(dashboard: any, listOfUserIds?: any) {
		Global.User.DebugMode && console.log("copyDashboard -> listOfUserIds = " + listOfUserIds + ", Global.User.currentUser.Id = " + Global.User.currentUser.Id);
		var singleCopyToCurrentLoggedInUser = listOfUserIds.indexOf(",") <= 0 && +listOfUserIds == Global.User.currentUser.Id;
		var sqlStatement = "API.Dashboards_CopyDashboard @DescriptionForDashboard= '', @DashboardId=" + dashboard.Id;
		var currentUserIsPartOfCopyOperation = false;
		var dataArray: any = null;
		if (!singleCopyToCurrentLoggedInUser) {
			var arrayOfUsersIds = listOfUserIds.split(",");
			var requestJSON: any = [];
			arrayOfUsersIds.forEach((userId:string) => {
				var jsonObject = {
					label: "User_" + userId,
					sqlStatement: sqlStatement + ", @ToUserId=" + userId
				};
				requestJSON.push(jsonObject);
				if (+userId == Global.User.currentUser.Id) {
					currentUserIsPartOfCopyOperation = true;
				}
			});
			console.log("requestJSON = %O", requestJSON);
			this.dataService.SQLMultiAction(requestJSON).then((data: any) => {
				var collections: any = data;
				Global.User.DebugMode && console.log("Dashboard id " + dashboard.Id + " copied to selected users.");
				this.utilityService.showToastMessageShared({
					type: "info",
					message: "'" + dashboard.Name + (dashboard.Description != "null" ? " : " + dashboard.Description : "")  + "' has been copied to the selected list of people.",
					title: "Dashboards"
				});
				//-- Need to find in the data which one might be tied to the current logged-in user and provide the updated Dashboard List from that set of data. --Kirk T. Sherer, June 27, 2023
				if (currentUserIsPartOfCopyOperation) {
					var data = collections.firstOrDefault((collection:any) => { return collection.label == "User_" + Global.User.currentUser.Id }).data;
					//this.getDashboardListForUser(data);
				}
			});
		}
		else {
			this.dataService.SQLActionAsPromise(sqlStatement).then(
				// newest list of dashboards with the copied dashboard will be returned for this user in the copy dashboard stored procedure.  Update the list with the data that is returned. --Kirk T. Sherer, August 28, 2020.
				(data: any) => {
					Global.User.DebugMode && console.log("Dashboard id " + dashboard.Id + " copied. New dashboard list: %O", data);
					this.utilityService.showToastMessageShared({
						type: "info",
						message: "'" + dashboard.Name + (dashboard.Description != "null" ? " : " + dashboard.Description : "")  + "' has been copied.",
						title: "Dashboards"
					});
					//   this.toastr.info("'" + dashboard.Name + "' has been copied.", "Dashboards");
					//this.getDashboardListForUser(data);
				}
			);
		}
	}


	loadExpandedDashboards(dashboard) {
		var sqlStatement = "API.GetExpandedDashboardById " + dashboard.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					"API.GetExpandedDashboardById = %O",
					data
				);

				dashboard.widgets = data;
				dashboard = this.dashboardService.SetDashboardDerivedDatesFromDayCount(data)
				this.buildEditForm();
		});
	}

	public closeWindow(modalInfo): void {
		if (modalInfo !== false) {
			this.dialogRef.close(modalInfo);
		} else {
			this.dialogRef.close();
		}
	}
	public closeDialogWindow(): void {
		this.closeWindow(false);
	}


	buildEditForm(): void {
		console.log("buildEditForm invoked...");
		this.formOptions = {
			submitButtonText: "Save Changes",
			saveValuesPerField: false,
			saveStoredProcedureName: null
		};

		if (this.id == 0) {
			this.title = "Add New Dashboard";
			this.addingNewDashboard = true;
			this.dashboardId = null;
			this.setUpQuestions();
		} else {
			var sqlStatement = this.dataService.getDashboard(this.id);
			this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				this.dashboard = data.first();
				this.dashboardId = this.id;
				const Dashboard: any = this.dashboard;
				this.title = "Editing Dashboard: " + Dashboard.Name;
				this.subTitle = Dashboard.Description != null ? Dashboard.Description : "";
				console.log("editing dashboard: %O", Dashboard);
				// use setValue to set new values
				// this.dashboardForm.setValue({
				//  dashboardName: Dashboard.Name,
				//  description: Dashboard.Description,
				//  dashboardTypeId: Dashboard.DashboardTypeId,
				//  isMobile: Dashboard.IsMobile ? "1" : "0",
				//  timeScopeId: Dashboard.TimeScopeId,
				//  updateIntervalId: Dashboard.UpdateIntervalId,
				//  customStartDate: Dashboard.CustomStartDate,
				//  customEndDate: Dashboard.CustomEndDate,
				// });
				this.setUpQuestions();
			});
		}
	}


	setUpQuestions() {
		let that = this;
		let DashboardGridsterTypes = this.dataService.cache.gridsterTypes.filter((gridsterType) => {
			return gridsterType.Id == 2 || gridsterType.Id == 4;
		})
		//Get today's date using the JavaScript Date object.
		var today = new Date();
		var pastDate = new Date();

		//Change it so that it is 7 days in the past.
		var pd = today.getDate() - 7;
		pastDate.setDate(pd);

		//Log the date to our web console.
		console.log(today);
		console.log(pastDate);
		console.log("this.dashboardTimeScopesOptionsList = %O", this.dashboardTimeScopesOptionsList);
		console.log("setUpQuestions invoked: this.dashboard = %O", this.dashboard);
		this.editFieldsCustom = [
			new TextboxQuestion({
				key: "Name",
				label: "Name",
				value: !_.isNil(this.dashboard) ? this.dashboard.Name : null,
				required: true,
				title: "Please enter the short name for this dashboard.",
				order: 1
			}),
			new TextareaQuestion({
				key: "Description",
				label: "Description",
				value: !_.isNil(this.dashboard) ? this.dashboard.Description : null,
				required: false,
				title: "Please enter the description for this dashboard.",
				order: 2
			}),


			new UibButtonQuestion({
				key: "dashboardHistoricalDataType",
				label: "Time Scope Type",
				value: !_.isNil(this.dashboard) ? (this.dashboard.CustomStartDate != null && this.dashboard.CustomEndDate != null ? 2 : 1) : 1,
				disabled: true,
				listOfValues: [
					{ Id: "1", Name: "Select Preset Date Range" },
					{ Id: "2", Name: "Select Custom Date Range" }
				],
				required: true,
				title: "This will determine if you wish to see data up to today from a previous date, or a historical data set between two past dates.  ",
				order: 5,
				visible: !_.isNil(this.dashboard) ? false : true,

			}),
			new ReadOnlyQuestion({
				key: "DashboardHistoricalDataTypeAsString",
				label: "Time Scope Type",
				value: !_.isNil(this.dashboard) ? (this.dashboard.CustomStartDate != null && this.dashboard.CustomEndDate != null ? "Preset Date Range" : "Custom Date Range") : null,
				title: "This will determine if you wish to see data up to today from a previous date, or a historical data set between two past dates.  ",
				order: 4,
				visible: !_.isNil(this.dashboard) ? true : false,

			}),
			new DropdownQuestion({
				key: "TimeScopeId",
				label: "Date Range Presets",
				value: !_.isNil(this.dashboard) ? (this.dashboard.TimeScopeId != null ? this.dashboard.TimeScopeId : 5) : 5,
				options: this.dashboardTimeScopesOptionsList,
				title: "Select the appropriate date range for your dashboard.",
				visibleFields: [

					{
						field: "dashboardHistoricalDataType",
						operator: "==",
						value: 1
					}
				],
				order: 6
			}),
			new DateQuestion({
				key: "CustomStartDate",
				label: "Custom Start Date",
				value: !_.isNil(this.dashboard) ? this.dashboard.CustomStartDate : pastDate,
				title: "Please enter the Start Date to begin using this dashboard if it's a temporary dashboard.",
				order: 7,
				required: false,

				visibleFields: [
					{
						field: "dashboardHistoricalDataType",
						operator: "==",
						value: 2
					}
				]
			}),
			new DateQuestion({
				key: "CustomEndDate",
				label: "Custom End Date",
				value: !_.isNil(this.dashboard) ? this.dashboard.CustomEndDate : today,
				title: "Please enter the End Date to end using this dashboard if it's a temporary dashboard.",
				order: 8,
				required: false,

				visibleFields: [
					{
						field: "dashboardHistoricalDataType",
						operator: "==",
						value: 2
					}
				]
			}),
			new UibButtonQuestion({
				key: "TimeZoneId",
				label: "Time Selection",
				value: !_.isNil(this.dashboard) ? (this.dashboard.TimeZoneId != null ? this.dashboard.TimeZoneId : 1) : 1,
				listOfValues: this.dashboardTimeZonesOptionsList,
				title: "Set the time that should be used for this dashboard. User Time = the time zone where you are located currently.  Site Time = the time zone where the Site you are viewing is located.",

				order: 9
			}),
			new UibButtonQuestion({
				key: "GridsterTypeId",
				label: "Grid Type",
				value: !_.isNil(this.dashboard) ? (this.dashboard.GridsterTypeId != null ? this.dashboard.GridsterTypeId : 2) : 2,
				listOfValues: DashboardGridsterTypes,
				title: "Set the grid type that should be used for this dashboard. ScrollVertical means that you will never see a horizontal scroll bar for the grid. Fixed means you have more creative freedom for creating a grid layout",

				order: 10
			}),

			new UibButtonQuestion({
				key: "UpdateIntervalId",
				label: "Update Interval",
				value: !_.isNil(this.dashboard) ? (this.dashboard.UpdateIntervalId != null ? this.dashboard.UpdateIntervalId : 2) : 2,
				listOfValues: this.tacticalDashboardUpdateIntervalOptionsList,
				title: "Select how quickly the widgets on your dashboard should be updated.",
				order: 11,

			}),
			new UibButtonQuestion({
				key: "IsMobile",
				label: "Application Type",
				value: !_.isNil(this.dashboard) ? (this.dashboard.IsMobile ? "1" : "0") : Global.isMobile ? "1" : "0",
				title: "Select whether this dashboard is for the desktop version or the mobile version.",
				listOfValues: this.applicationTypes,
				order: 12
			}),
			new SliderYesNoQuestion({
				key: "IsTemplate",
				label: "Is Template?",
				title: "Should this Dashboard be used as a Template?",
				value: !_.isNil(this.dashboard) ? this.dashboard.IsTemplate : "0",
				order: 13,
				visible: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin
			})
		];

		this.editFieldsTemplate = [
			new TextboxQuestion({
				key: "Name",
				label: "Name",
				value: !_.isNil(this.dashboard) ? this.dashboard.Name : null,
				required: true,
				title: "Please enter the short name for this dashboard.",
				order: 1
			}),
			new TextareaQuestion({
				key: "Description",
				label: "Description",
				value: !_.isNil(this.dashboard) ? this.dashboard.Description : null,
				required: false,
				title: "Please enter the description for this dashboard.",
				order: 2
			}),
			new DropdownQuestion({
				key: "DashboardTemplateId",
				label: "Dashboard Template",
				value: !_.isNil(this.dashboard) ? this.dashboard.DashboardTemplateId : null,
				required: true,
				options: this.dashboardTemplatesList,
				title: "Create dashboard from a template.",
				visible: this.addingNewDashboard == true ? true : false,
				order: 3,
				//onChangeFunction: () => this.setDashboardTemplateDescription(),

				onChangeFunction: function() {
					Global.User.DebugMode && console.log("onChangeFunction newValue = %O", this.newValue);
					Global.User.DebugMode && console.log("onChangeFunction component = %O", that);

					var selectedTemplate = 	that.dashboardTemplatesListFullDataWithWidgets.find(t=> t.Id == this.newValue);

					var widgetNames = [];
					selectedTemplate.widgets.forEach(w => {
						widgetNames.push(w.WidgetName);
					});

					var widgetNamesAsString = widgetNames.join(", ");

					that.currentTemplateDescription = "This is a template with widgets " + widgetNamesAsString;


					var swalWithBootstrapButtons = swal.mixin({
							customClass: {
								confirmButton: "btn btn-danger",
								cancelButton: "btn btn-success"
							},
							buttonsStyling: false
						});

					swalWithBootstrapButtons
							.fire({
								title: "Dashboard Template",
								html: "Dashboard template you have selected has the following " + that.currentTemplateDescription,
								showCancelButton: false,
								confirmButtonText: "Ok",
								//cancelButtonText: "Cancel",
								reverseButtons: false
							})
							.then((result) => {
							});
				},


			}),

		];

		// Add RO fields - 1 for each template
		this.dashboardTemplatesListFullDataWithWidgets.forEach(t => {
			var widgetNames = [];
			t.widgets?.forEach(w => {
				widgetNames.push(w.WidgetName);
			});

			var widgetNamesAsString = widgetNames.join(", ");

			var currentTemplateDescription = "This is a template with widgets " + widgetNamesAsString;

			var roq = new ReadOnlyQuestion({
				key: "TemplateDescription",
				label: "Template Description",
				value: currentTemplateDescription,
				visible: true,
				title: "",
				readonly: true,
				order: 4,
				visibleFields: [
					{
						field: "DashboardTemplateId",
						operator: "==",
						value: t.Id
					},

				],
			})
			this.editFieldsTemplate.push(roq);
		});

		this.isLoading = false;
	}


	submitForm(submittedValues: string) {
		this.loadingMessage = "'Creating Dashboard'";
		this.isLoading = true;
		Global.User.DebugMode && console.log("dashboard-edit.component.ts: submitForm: submittedValues = %O", submittedValues);
		var fieldListAsString = submittedValues.replace("{", "").replace("}", "").replace(/:/g, "=").replace(/\"/g, "");
		Global.User.DebugMode && console.log("dashboard-edit.component.ts: fieldListAsString = " + fieldListAsString);
		var submittedValuesObject = JSON.parse(submittedValues);
		Global.User.DebugMode && console.log("submittedValuesObject = %O", submittedValuesObject);
		Global.User.DebugMode && console.log("Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));

		if(submittedValuesObject.DashboardTemplateId != null) {
			// create from template
			var sql = "API.Dashboards_CopyDashboard @DescriptionForDashboard= '" + submittedValuesObject.Description + "', @NameForDashboard= '" + submittedValuesObject.Name + "', @DashboardId=" + submittedValuesObject.DashboardTemplateId + ", @ToUserId = " + Global.User.currentUser.Id
			this.dataService.SQLActionAsPromise(sql).then((data: any) => {

				var sqlGetDashboards = "API.GetDashboardsForUser @UserId = " + Global.User.currentUser.Id
				this.dataService.SQLActionAsPromise(sqlGetDashboards).then((dashboards: any) => {
					Global.User.DebugMode && console.log("dashboard created from template = %O", dashboards);
					if (Global.isMobile) {
						dashboards = dashboards.filter((item) => {
							return item.Type === "Mobile";
						});
					}
					this.dataService.buildDashboardAndDashboardObjectAfterEdit(dashboards);
					this.dataService.setUserDashboardList(dashboards);
					this.isLoading = false;
					this.closeWindow(dashboards);
				});
			});

		}
		else {
			// if IsTemplate and check prev value is not
			// this code does a copy of the dashboard as it is at this point in time
			if(submittedValuesObject.IsTemplate == 1 && this.id != 0 && Global.User.currentUser.DashboardsObject[this.id]?.IsTemplate == 0) {
				// are you sure?

				this.swalWithBootstrapButtons
					.fire({
						title: "Are you sure?",
						html: "<strong>Warning:</strong> This will create a dashboard for all " + this.numberOfPeopleInOrg + " users in your organization.",
						showCancelButton: true,
						confirmButtonText: "Yes, Create " + this.numberOfPeopleInOrg + " Dashboards",
						cancelButtonText: "No, just make it a template",
						reverseButtons: false
					})
					.then((result) => {
						if (result.value) {
							// logic for copy dashboard.

							Global.User.DebugMode && console.log("copyDashboard %0 to users = %1", this.dashboard.Id, this.listOfUserIds);
							this.copyDashboardTemplateToUsers(this.dashboard, this.listOfUserIds);
							this.finishSubmittingForm(submittedValuesObject);

						} else if (result.dismiss === swal.DismissReason.cancel) {
							this.utilityService.showToastMessageShared({
								type: "info",
								message: "Dashboards have NOT been created.",
								title: "Dashboards"
							});
							this.isLoading = false;
							this.finishSubmittingForm(submittedValuesObject);
							this.closeWindow(null);
						}
					});

			}
			else {
				this.finishSubmittingForm(submittedValuesObject);
			}



		}
	}

	finishSubmittingForm(submittedValuesObject) {
		var keys: Array<any> = Object.keys(submittedValuesObject);
			//-- building a parameter list to attach to the end of the stored procedure to execute it. --Kirk T. Sherer, April 7, 2020.
			var fieldListAsString: string = "";
			var countOfFields = 1;
			keys.forEach((key: any) => {
				if(key == 'DashboardTemplateId') {
					return;
				}
				var questions = this.editFieldsCustom;

				questions.forEach((question: any) => {
					if (key == question.key && question.key != "dashboardHistoricalDataType" && question.visible == true && (question.type == "parent-key-field" || (question.controlType != "read-only" && question.type != "parent-key-field"))) {
						var value = submittedValuesObject[key];
						if (question.controlType == "uib-button-multi-select") {
							value = question.listOfValues
								?.where((d: any) => {
									return d.IsSelected == 1;
								})
								.select((vals: any) => {
									return vals.Id;
								})
								.toArray()
								.join(","); //-- multi-select will be comma-separated list of Id numbers to pass to the stored procedure. --Kirk T. Sherer, September 9, 2021.
						}
						fieldListAsString += "@" + key + "=";
						Global.User.DebugMode && console.log("key: " + key + ", value: " + value);
						if (typeof value == "object" && value != null) {
							//--if we made it here with an entire object, then get the Id value of the object since it came from a selector. --Kirk T. Sherer, May 25, 2021.
							Global.User.DebugMode && console.log("value = %O", value);
							var idFieldValue = value["Id"];
							fieldListAsString += idFieldValue; //submittedValuesObject[key].Id;
						} else {
							if (value == null) {
								fieldListAsString += "null";
							} else {
								if (question.controlType == "slider-yes-no" || question.controlType == "slider-true-false" || question.controlType == "slider" || question.controlType == "true-false-button") {
									fieldListAsString += value;
								} else {
									if (isNaN(value) || value == "") {
										if (value == "") {
											fieldListAsString += "null";
										} else {
											fieldListAsString += "'" + value.trim().split("'").join("''") + "'";
										}
									} else {
										if (value == "true" || value == "false" || value == true || value == false) {
											//-- this is a boolean field.  Set it as 1 for true or 0 for false.
											fieldListAsString += value == "true" || value == true ? "1" : "0";
										} else {
											if (value == 0 || value == "0") {
												fieldListAsString += "null";
											} else {
												if (question.controlType == "date") {
													fieldListAsString += +value;
												} else {
													fieldListAsString += value;
												}
											}
										}
									}
								}
							}
						}
						if (countOfFields < keys.length) {
							fieldListAsString += ",";
						}
						countOfFields++;
					}
				});

				// var value = submittedValuesObject[key];
				// console.log("key: " + key + ", value: " + value);
				// fieldListAsString += "@" + key + "=";
				// if (isNaN(submittedValuesObject[key]) || key == "zipcode") {
				//  fieldListAsString += value ? "'" + value + "'" : "null";
				// } else {
				//  fieldListAsString += value != "" ? value : "null";
				// }
				// if (countOfFields < keys.length) {
				//  fieldListAsString += ", ";
				// }
				// countOfFields++;
			});
			var lastCharacter = fieldListAsString.charAt(fieldListAsString.length - 1);
			if (lastCharacter == ",") {
				fieldListAsString = fieldListAsString.slice(0, -1);
			}
			Global.User.DebugMode && console.log("fieldListAsString = " + fieldListAsString);
			var sql = "API.AddOrUpdateDashboardRecord @Id=" + this.dashboardId + ", @CreatorUserId = " + Global.User.currentUser.Id + ", " + fieldListAsString;
			this.dataService.SQLActionAsPromise(sql).then((data: any) => {
				console.log("dashboards after update = %O", data);
				if (Global.isMobile) {
					data = data.filter((item) => {
						return item.Type === "Mobile";
					});
				}

				this.dataService.buildDashboardAndDashboardObjectAfterEdit(data);
				this.dataService.setUserDashboardList(data);
				this.isLoading = false;
				this.closeWindow(data);
			});
	}
}
