import { fromAsync } from "../../async/static/fromAsync";
/**
 * Produces the set difference of two sequences by using the comparer provided to compare values.
 * @param first An IEnumerable<T> whose elements that are not also in second will be returned.
 * @param second An IEnumerable<T> whose elements that also occur in the first sequence
 * will cause those elements to be removed from the returned sequence.
 * @param comparer An IAsyncEqualityComparer<T> to compare values.
 * @returns A sequence that contains the set difference of the elements of two sequences.
 */
export const exceptAsync = (first, second, comparer) => {
  async function* iterator() {
    const secondArray = [...second];
    for (const firstItem of first) {
      let exists = false;
      for (let j = 0; j < secondArray.length; j++) {
        const secondItem = secondArray[j];
        if ((await comparer(firstItem, secondItem)) === true) {
          exists = true;
          break;
        }
      }
      if (exists === false) {
        yield firstItem;
      }
    }
  }
  return fromAsync(iterator);
};