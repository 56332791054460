import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
export const selectAsync = (source, selector) => {
  if (typeof selector === "string") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return selectAsync2(source, selector);
  } else {
    return selectAsync1(source, selector);
  }
};
const selectAsync1 = (source, selector) => {
  async function* iterator() {
    for await (const value of source) {
      yield selector(value);
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const selectAsync2 = (source, key) => {
  async function* iterator() {
    for await (const value of source) {
      yield value[key];
    }
  }
  return new BasicAsyncEnumerable(iterator);
};