import { toArray } from "./toArray";
/**
 * Returns first element in sequence that satisfies predicate otherwise
 * returns the first element in the sequence. Returns null if no value found.
 * @param source An IParallelEnumerable<T> to return an element from.
 * @param predicate A function to test each element for a condition. Optional.
 * @returns The first element in the sequence
 * or the first element that passes the test in the specified predicate function.
 * Returns null if no value found.
 */
export const firstOrDefault = (source, predicate) => {
  if (predicate) {
    return firstOrDefault2(source, predicate);
  } else {
    return firstOrDefault1(source);
  }
};
const firstOrDefault1 = async source => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const values = await dataFunc.generator();
        if (values.length === 0) {
          return null;
        } else {
          return values[0];
        }
      }
    case 1 /* ArrayOfPromises */:
      {
        const promises = dataFunc.generator();
        if (promises.length === 0) {
          return null;
        } else {
          return await promises[0];
        }
      }
    case 2 /* PromiseOfPromises */:
      {
        const promises = await dataFunc.generator();
        if (promises.length === 0) {
          return null;
        } else {
          return await promises[0];
        }
      }
  }
};
const firstOrDefault2 = async (source, predicate) => {
  const data = await toArray(source);
  for (const value of data) {
    if (predicate(value) === true) {
      return value;
    }
  }
  return null;
};