import { ErrorString, InvalidOperationException } from "../../shared";
import { toArray } from "./toArray";
/**
 * Returns the first element of a sequence.
 * If predicate is specified, returns the first element in a sequence that satisfies a specified condition.
 * @param source The IParallelEnumerable<T> to return the first element of.
 * @param predicate A function to test each element for a condition. Optional.
 * @throws {InvalidOperationException} The source sequence is empty.
 * @returns The first element in the specified sequence.
 * If predicate is specified,
 * the first element in the sequence that passes the test in the specified predicate function.
 */
export const first = (source, predicate) => {
  if (predicate) {
    return first2(source, predicate);
  } else {
    return first1(source);
  }
};
const first1 = async source => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const values = await dataFunc.generator();
        if (values.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        } else {
          return values[0];
        }
      }
    case 1 /* ArrayOfPromises */:
      {
        const promises = dataFunc.generator();
        if (promises.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        } else {
          return await promises[0];
        }
      }
    case 2 /* PromiseOfPromises */:
      {
        const promises = await dataFunc.generator();
        if (promises.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        } else {
          return await promises[0];
        }
      }
  }
};
const first2 = async (source, predicate) => {
  const data = await toArray(source);
  for (const value of data) {
    if (predicate(value) === true) {
      return value;
    }
  }
  throw new InvalidOperationException(ErrorString.NoMatch);
};