import { ErrorString, InvalidOperationException } from "../../shared";
import { toArray } from "./toArray";
/**
 * Returns the only element of a sequence that satisfies a specified condition (if specified),
 * and throws an exception if more than one such element exists.
 * @param source An IParallelEnumerable<T> to return a single element from.
 * @param predicate A function to test an element for a condition. (Optional)
 * @throws {InvalidOperationException} No element satisfies the condition in predicate. OR
 * More than one element satisfies the condition in predicate. OR
 * The source sequence is empty.
 * @returns The single element of the input sequence that satisfies a condition.
 */
export const single = (source, predicate) => {
  if (predicate) {
    return single2(source, predicate);
  } else {
    return single1(source);
  }
};
const single1 = async source => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const results = await dataFunc.generator();
        if (results.length > 1) {
          throw new InvalidOperationException(ErrorString.MoreThanOneElement);
        } else if (results.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        }
        return results[0];
      }
    case 1 /* ArrayOfPromises */:
      {
        const results = dataFunc.generator();
        if (results.length > 1) {
          throw new InvalidOperationException(ErrorString.MoreThanOneElement);
        } else if (results.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        }
        return results[0];
      }
    case 2 /* PromiseOfPromises */:
      {
        const results = await dataFunc.generator();
        if (results.length > 1) {
          throw new InvalidOperationException(ErrorString.MoreThanOneElement);
        } else if (results.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        }
        return await results[0];
      }
  }
};
const single2 = async (source, predicate) => {
  const results = await toArray(source);
  let hasValue = false;
  let singleValue = null;
  for (const value of results) {
    if (predicate(value)) {
      if (hasValue === true) {
        throw new InvalidOperationException(ErrorString.MoreThanOneMatchingElement);
      } else {
        hasValue = true;
        singleValue = value;
      }
    }
  }
  if (hasValue === false) {
    throw new InvalidOperationException(ErrorString.NoMatch);
  }
  return singleValue;
};