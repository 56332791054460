import { BasicEnumerable } from "../BasicEnumerable";
/**
 * Projects each element of a sequence into a new form.
 * @param source A sequence of values to invoke a transform function on.
 * @param selector A key of TSource.
 * @returns
 * An IEnumerable<T> whose elements are the result of getting the value from the key on each element of source.
 */
export const select = (source, selector) => {
  if (typeof selector === "function") {
    const {
      length
    } = selector;
    if (length === 1) {
      return select1(source, selector);
    } else {
      return select2(source, selector);
    }
  } else {
    return select3(source, selector);
  }
};
const select1 = (source, selector) => {
  function* iterator() {
    for (const value of source) {
      yield selector(value);
    }
  }
  return new BasicEnumerable(iterator);
};
const select2 = (source, selector) => {
  function* iterator() {
    let index = 0;
    for (const value of source) {
      yield selector(value, index);
      index++;
    }
  }
  return new BasicEnumerable(iterator);
};
const select3 = (source, key) => {
  function* iterator() {
    for (const value of source) {
      yield value[key];
    }
  }
  return new BasicEnumerable(iterator);
};