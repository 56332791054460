/* eslint-disable  */
export const nextIterationWithIndexAsync = (source, onfulfilled) => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const generator = async () => {
          const results = await dataFunc.generator();
          const newPromises = new Array(results.length);
          for (let i = 0; i < results.length; i++) {
            newPromises[i] = onfulfilled(results[i], i);
          }
          return newPromises;
        };
        return {
          generator,
          type: 2 /* PromiseOfPromises */
        };
      }
    case 1 /* ArrayOfPromises */:
      {
        const generator = () => dataFunc.generator().map((promise, index) => promise.then(x => onfulfilled(x, index)));
        return {
          generator,
          type: 1 /* ArrayOfPromises */
        };
      }
    case 2 /* PromiseOfPromises */:
      {
        const generator = async () => {
          const promises = await dataFunc.generator();
          return promises.map((promise, index) => promise.then(x => onfulfilled(x, index)));
        };
        return {
          generator,
          type: 2 /* PromiseOfPromises */
        };
      }
  }
};