import { ErrorString, InvalidOperationException } from "../../shared";
export const min = (source, selector) => {
  if (selector) {
    return min2(source, selector);
  } else {
    return min1(source);
  }
};
const min1 = source => {
  let minItem = null;
  for (const item of source) {
    minItem = Math.min(minItem || Number.POSITIVE_INFINITY, item);
  }
  if (minItem === null) {
    throw new InvalidOperationException(ErrorString.NoElements);
  } else {
    return minItem;
  }
};
const min2 = (source, selector) => {
  let minItem = null;
  for (const item of source) {
    minItem = Math.min(minItem || Number.POSITIVE_INFINITY, selector(item));
  }
  if (minItem === null) {
    throw new InvalidOperationException(ErrorString.NoElements);
  } else {
    return minItem;
  }
};