import { fromAsync } from "../../async/static/fromAsync";
/**
 * Bypasses elements in a sequence as long as a specified condition is true and then returns the remaining elements.
 * The element's index is used in the logic of the predicate function.
 * @param source An Iterable<T> to return elements from.
 * @param predicate A function to test each source element for a condition;
 * the second parameter of the function represents the index of the source element.
 * @returns An IAsyncEnumerable<T> that contains the elements from the input sequence starting
 * at the first element in the linear series that does not pass the test specified by predicate.
 */
export const skipWhileAsync = (source, predicate) => {
  if (predicate.length === 1) {
    return skipWhileAsync1(source, predicate);
  } else {
    return skipWhileAsync2(source, predicate);
  }
};
const skipWhileAsync1 = (source, predicate) => {
  async function* iterator() {
    let skip = true;
    for (const item of source) {
      if (skip === false) {
        yield item;
      } else if ((await predicate(item)) === false) {
        skip = false;
        yield item;
      }
    }
  }
  return fromAsync(iterator);
};
const skipWhileAsync2 = (source, predicate) => {
  async function* iterator() {
    let index = 0;
    let skip = true;
    for (const item of source) {
      if (skip === false) {
        yield item;
      } else if ((await predicate(item, index)) === false) {
        skip = false;
        yield item;
      }
      index++;
    }
  }
  return fromAsync(iterator);
};