import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
/**
 * Concatenates two sequences.
 * @param first The first sequence to concatenate.
 * @param second The sequence to concatenate to the first sequence.
 * @returns An IAsyncEnumerable<T> that contains the concatenated elements of the two input sequences.
 */
export const concatenate = (first, second) => {
  async function* iterator() {
    yield* first;
    yield* second;
  }
  return new BasicAsyncEnumerable(iterator);
};