import { BasicEnumerable } from "../BasicEnumerable";
import { Grouping } from "../Grouping";
/* eslint-disable jsdoc/require-returns */
/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
/**
 * Group and Iterable Based on a Generic Key and an equality comparer
 * @param source Iteration
 * @param keySelector Key Selector
 * @param comparer Key Comparer
 * @private
 */
export const groupBy_0 = (source, keySelector, comparer) => {
  return function* generate() {
    const keyMap = new Array();
    for (const value of source) {
      const key = keySelector(value);
      let found = false;
      for (let i = 0; i < keyMap.length; i++) {
        const group = keyMap[i];
        if (comparer(group.key, key)) {
          group.push(value);
          found = true;
          break;
        }
      }
      if (found === false) {
        keyMap.push(new Grouping(key, value));
      }
    }
    for (const keyValue of keyMap) {
      yield keyValue;
    }
  };
};
/**
 * @private
 */
export const groupBy_0_Simple = (source, keySelector) => {
  return function* iterator() {
    const keyMap = {};
    for (const value of source) {
      const key = keySelector(value);
      const grouping = keyMap[key];
      if (grouping) {
        grouping.push(value);
      } else {
        keyMap[key] = new Grouping(key, value);
      }
    }
    // eslint-disable-next-line guard-for-in
    for (const value in keyMap) {
      yield keyMap[value];
    }
  };
};
/**
 * @private
 */
export const groupBy_1_Simple = (source, keySelector, elementSelector) => {
  function* generate() {
    const keyMap = {};
    for (const value of source) {
      const key = keySelector(value);
      const grouping = keyMap[key];
      const element = elementSelector(value);
      if (grouping) {
        grouping.push(element);
      } else {
        keyMap[key] = new Grouping(key, element);
      }
    }
    /* eslint-disable guard-for-in */
    for (const value in keyMap) {
      yield keyMap[value];
    }
    /* eslint-enable guard-for-in */
  }
  return new BasicEnumerable(generate);
};
/**
 * @private
 */
export const groupBy_1 = (source, keySelector, elementSelector, comparer) => {
  function* generate() {
    const keyMap = new Array();
    for (const value of source) {
      const key = keySelector(value);
      let found = false;
      for (let i = 0; i < keyMap.length; i++) {
        const group = keyMap[i];
        if (comparer(group.key, key)) {
          group.push(elementSelector(value));
          found = true;
          break;
        }
      }
      if (found === false) {
        const element = elementSelector(value);
        keyMap.push(new Grouping(key, element));
      }
    }
    for (const keyValue of keyMap) {
      yield keyValue;
    }
  }
  return new BasicEnumerable(generate);
};