import { Grouping } from "../../sync/Grouping";
import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
export const groupByWithSel = (source, keySelector, elementSelector, comparer) => {
  if (comparer) {
    return groupBy1(source, keySelector, elementSelector, comparer);
  } else {
    return groupBy1Simple(source, keySelector, elementSelector);
  }
};
const groupBy1 = (source, keySelector, elementSelector, comparer) => {
  const generator = async () => {
    const keyMap = new Array();
    for await (const value of source) {
      const key = keySelector(value);
      let found = false;
      for (let i = 0; i < keyMap.length; i++) {
        const group = keyMap[i];
        if (comparer(group.key, key)) {
          group.push(elementSelector(value));
          found = true;
          break;
        }
      }
      if (found === false) {
        const element = elementSelector(value);
        keyMap.push(new Grouping(key, element));
      }
    }
    const results = new Array();
    for (const value of keyMap) {
      results.push(value);
    }
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};
const groupBy1Simple = (source, keySelector, elementSelector) => {
  // generate(): AsyncIterableIterator<IGrouping<string | number, TElement>>
  const generator = async () => {
    const keyMap = {};
    for (const value of await source.toArray()) {
      const key = keySelector(value);
      const grouping = keyMap[key];
      const element = elementSelector(value);
      if (grouping) {
        grouping.push(element);
      } else {
        keyMap[key] = new Grouping(key, element);
      }
    }
    /* eslint-disable guard-for-in */
    const results = new Array();
    for (const value in keyMap) {
      results.push(keyMap[value]);
    }
    /* eslint-enable guard-for-in */
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};