import { ArgumentOutOfRangeException, ErrorString, InvalidOperationException } from "../shared";
import { BasicEnumerable } from '../sync/BasicEnumerable';
/**
 * Adds LINQ methods to String prototype
 */
export const bindString = () => {
  var _a;
  const prototype = String.prototype;
  const propertyNames = Object.getOwnPropertyNames(BasicEnumerable.prototype);
  for (const prop of propertyNames) {
    prototype[prop] = (_a = prototype[prop]) !== null && _a !== void 0 ? _a : BasicEnumerable.prototype[prop];
  }
  prototype.first = function (predicate) {
    if (predicate) {
      for (let i = 0; i < this.length; i++) {
        const value = this[i];
        if (predicate(value) === true) {
          return value;
        }
      }
      throw new InvalidOperationException(ErrorString.NoMatch);
    }
    if (this.length === 0) {
      throw new InvalidOperationException(ErrorString.NoElements);
    }
    return this[0];
  };
  prototype.firstOrDefault = function (predicate) {
    if (predicate) {
      for (let i = 0; i < this.length; i++) {
        const value = this[i];
        if (predicate(value) === true) {
          return value;
        }
      }
      return null;
    }
    return this.length === 0 ? null : this[0];
  };
  prototype.count = function (predicate) {
    if (predicate) {
      // eslint-disable-next-line no-shadow
      let count = 0;
      for (let i = 0; i < this.length; i++) {
        if (predicate(this[i]) === true) {
          count++;
        }
      }
      return count;
    } else {
      return this.length;
    }
  };
  prototype.elementAt = function (index) {
    if (index < 0 || index >= this.length) {
      throw new ArgumentOutOfRangeException("index");
    }
    return this[index];
  };
  prototype.elementAtOrDefault = function (index) {
    return this[index] || null;
  };
  prototype.last = function (predicate) {
    if (predicate) {
      for (let i = this.length - 1; i >= 0; i--) {
        const value = this[i];
        if (predicate(value) === true) {
          return value;
        }
      }
      throw new InvalidOperationException(ErrorString.NoMatch);
    } else {
      if (this.length === 0) {
        throw new InvalidOperationException(ErrorString.NoElements);
      }
      return this[this.length - 1];
    }
  };
  prototype.lastOrDefault = function (predicate) {
    if (predicate) {
      for (let i = this.length - 1; i >= 0; i--) {
        const value = this[i];
        if (predicate(value) === true) {
          return value;
        }
      }
      return null;
    } else {
      return this.length === 0 ? null : this[this.length - 1];
    }
  };
  prototype.reverse = function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const outer = this;
    function* generator() {
      for (let i = outer.length - 1; i >= 0; i--) {
        yield outer[i];
      }
    }
    return new BasicEnumerable(generator);
  };
};