import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
import { nextIteration } from "./_nextIteration";
import { nextIterationWithIndex } from "./_nextIterationWithIndex";
export const selectMany = (source, selector) => {
  const generator = async () => {
    let values;
    if (typeof selector === "function") {
      if (selector.length === 1) {
        values = nextIteration(source, selector);
      } else {
        values = nextIterationWithIndex(source, selector);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
      values = nextIteration(source, x => x[selector]);
    }
    const valuesArray = [];
    switch (values.type) {
      case 0 /* PromiseToArray */:
        {
          for (const outer of await values.generator()) {
            for (const y of outer) {
              valuesArray.push(y);
            }
          }
          break;
        }
      case 1 /* ArrayOfPromises */:
        {
          for (const outer of values.generator()) {
            for (const y of await outer) {
              valuesArray.push(y);
            }
          }
          break;
        }
      case 2 /* PromiseOfPromises */:
        {
          for (const outer of await values.generator()) {
            for (const y of await outer) {
              valuesArray.push(y);
            }
          }
          break;
        }
    }
    return valuesArray;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};