import { Grouping } from "../../sync/Grouping";
import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
export const groupBy = (source, keySelector, comparer) => {
  if (comparer) {
    return groupBy_0(source, keySelector, comparer);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return groupBy_0_Simple(source, keySelector);
  }
};
const groupBy_0 = (source, keySelector, comparer) => {
  async function* generate() {
    const keyMap = new Array();
    for await (const value of source) {
      const key = keySelector(value);
      let found = false;
      for (let i = 0; i < keyMap.length; i++) {
        const group = keyMap[i];
        if (comparer(group.key, key)) {
          group.push(value);
          found = true;
          break;
        }
      }
      if (found === false) {
        keyMap.push(new Grouping(key, value)); // TODO
      }
    }
    for (const g of keyMap) {
      yield g;
    }
  }
  return new BasicAsyncEnumerable(generate);
};
const groupBy_0_Simple = (source, keySelector) => {
  async function* iterator() {
    const keyMap = {};
    for await (const value of source) {
      const key = keySelector(value);
      const grouping = keyMap[key];
      if (grouping) {
        grouping.push(value);
      } else {
        keyMap[key] = new Grouping(key, value);
      }
    }
    // eslint-disable-next-line guard-for-in
    for (const value in keyMap) {
      yield keyMap[value];
    }
  }
  return new BasicAsyncEnumerable(iterator);
};