/* eslint-disable @typescript-eslint/no-empty-interface */
/**
 * Basic Enumerable. Usually returned from the Enumerable class.
 * @private
 */
export class BasicEnumerable {
  constructor(iterator) {
    this.iterator = iterator;
    //
  }
  [Symbol.iterator]() {
    return this.iterator();
  }
}