import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
/**
 * Bypasses elements in a sequence as long as a specified condition is true and then returns the remaining elements.
 * The element's index is used in the logic of the predicate function.
 * @param source An IAsyncParallel<T> to return elements from.
 * @param predicate A function to test each source element for a condition;
 * the second parameter of the function represents the index of the source element.
 * @returns An IParallelEnumerable<T> that contains the elements from the input sequence starting
 * at the first element in the linear series that does not pass the test specified by predicate.
 */
export const skipWhileAsync = (source, predicate) => {
  const generator = async () => {
    const values = await source.toArray();
    let i = 0;
    for (; i < values.length; i++) {
      const value = values[i];
      if ((await predicate(value, i)) === false) {
        break;
      }
    }
    const returnedValues = [];
    for (; i < values.length; i++) {
      returnedValues.push(values[i]);
    }
    return returnedValues;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};