import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
/**
 * Applies a type filter to a source iteration
 * @param source Async Iteration to Filtery by Type
 * @param type Either value for typeof or a consturctor function
 * @returns Values that match the type string or are instance of type
 */
export const ofType = (source, type) => {
  const typeCheck = typeof type === "string" ? x => typeof x === type : x => x instanceof type;
  async function* iterator() {
    for await (const item of source) {
      if (typeCheck(item)) {
        yield item;
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};