import { nextIteration } from "./_nextIteration";
/**
 * Determines whether all elements of a sequence satisfy a condition.
 * @param source An IParallelEnumerable<T> that contains the elements to apply the predicate to.
 * @param predicate A function to test each element for a condition.
 * @returns ``true`` if every element of the source sequence passes the test in the specified predicate,
 * or if the sequence is empty; otherwise, ``false``.
 */
export const all = (source, predicate) => {
  const nextIter = nextIteration(source, x => {
    if (!predicate(x)) {
      throw new Error(String(false));
    }
    return true;
  });
  switch (nextIter.type) {
    case 0 /* PromiseToArray */:
      return nextIter.generator().then(() => true, () => false);
    case 1 /* ArrayOfPromises */:
      return Promise.all(nextIter.generator()).then(() => true, () => false);
    case 2 /* PromiseOfPromises */:
      return nextIter.generator().then(Promise.all.bind(Promise)).then(() => true, () => false);
  }
};