import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';
import { UtilityService } from '../services/utility.service';

@Injectable({
	providedIn: 'root',
})
export class CustomerIssueGuard  {
	constructor(
		public dataService: DataService,
		public utilityService: UtilityService
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		let selectedCustomerIssueId = route.params.id;
		console.log(selectedCustomerIssueId);
		console.log(this.dataService.cache.userIssues);
		let foundCustomerIssue = this.dataService.cache.userIssues.find(
			(ui) => ui.Id == selectedCustomerIssueId
		);
		if (
			foundCustomerIssue != undefined ||
			selectedCustomerIssueId == 'newIssue'
		) {
			this.dataService.currentSelectedIssueId = selectedCustomerIssueId;
			return true;
		} else {
			this.utilityService.showToastMessageShared({
				type: 'error',
				message: 'You are not allowed to view this issue.',
			});
			return false;
		}
	}
}
