<!-- standard -->
<div style="height: 100%"
	*ngIf="this.currentDashboard !== undefined && this.currentDashboard.DashboardTypeId !== undefined">
	<div class="content" style="padding-right: 0px !important; padding-bottom: 0px" *ngIf="!global.isMobile">
		<div class="row mx-auto">
			<div class="col-12" style="padding-left: 0px">
				<button class="jbt-btn mr-1"
					matTooltip="Click to select from a menu of available widgets to add to this dashboard."
					matTooltipPosition="below" (click)="openAddWidgetToDashboardModal()">Add Widget</button>
				<div style="display: inline; margin-left: 15px; margin-right: 15px">
					<mat-slide-toggle (change)="changeDashboardLockedToggle($event)" [(ngModel)]="dashboardLocked">
						<p [style.color]="global.Theme === 'light' ? '#212427' : '#ffffff'">Dashboard Locked</p>
					</mat-slide-toggle>
				</div>
				<button *ngIf="dataService.tempTagsToChart.length > 0" class="jbt-btn mr-1"
					(click)="AddGraphWidgetToDashboard()">Add Chart</button>
				<button *ngIf="dataService.tempTagsToChart.length > 0" class="jbt-btn mr-1"
					(click)="ClearChartSelections()">Clear Chart Selections</button>
				<div class="pull-right" style="display: inline">
					<mat-form-field appearance="fill" class="pull-right">
						<mat-label>Select a Dashboard Timezone</mat-label>

						<mat-select [(value)]="currentTimeZoneId" (selectionChange)="changeDashboardTimeZone()">
							<mat-option *ngFor="let timeZone of DashboardTimeZones" [value]="timeZone.Id">
								{{ timeZone.Description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="pull-right" style="display: inline; padding-right: 10px" *ngIf="this.currentDashboard.CustomStartDate == null && this.currentDashboard.CustomEndDate == null ">
					<mat-form-field appearance="fill" class="pull-right">
						<mat-label>Select a Dashboard Timescope</mat-label>
						<mat-select [(value)]="currentTimeScopeId" (selectionChange)="changeDashboardTimeScope()">
							<mat-option *ngFor="let timeWindow of DashboardTimeScopes" [value]="timeWindow.Id">
								{{ timeWindow.Description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="pull-right" style="display: inline ; padding-right: 10px">
					<mat-form-field appearance="fill" class="pull-right">
						<mat-label>Select a Dashboard Update Interval</mat-label>

						<mat-select [(value)]="updateIntervalId" (selectionChange)="changeDashboardUpdateInterval()">
							<mat-option
								*ngFor="let updateInterval of global.User.currentUser.TacticalDashboardUpdateIntervals"
								[value]="updateInterval.Id">
								{{ updateInterval.Description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>




				<div class="pull-right" style="display: inline; padding-right: 10px" *ngIf="
				this.currentDashboard.GridsterTypeId != null
			">
					<mat-form-field appearance="fill" class="pull-right">
						<mat-label>Select a Dashboard Grid Type</mat-label>
						<mat-select [(value)]="currentGridsterTypeId" (selectionChange)="changeDashboardGridsterType()">
							<mat-option *ngFor="let gridsterType of DashboardGridsterTypes" [value]="gridsterType.Id">
								{{ gridsterType.DisplayName }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="pull-right" style="display: inline; padding-right: 10px" *ngIf="
						this.currentDashboard.CustomStartDate != null &&
						this.currentDashboard.CustomEndDate != null
					">
					<form [formGroup]="form">
						<kendo-datetimepicker (valueChange)="
								onDateSelectionChange($event, 'start')
							" [max]="maxDate" formControlName="startDateTime"></kendo-datetimepicker>
						<kendo-datetimepicker (valueChange)="onDateSelectionChange($event, 'end')" [max]="maxDate"
							formControlName="endDateTime"></kendo-datetimepicker>
					</form>
				</div>
			</div>
		</div>

		<div class="gridster-container">
			<gridster [options]="options">
				<gridster-item [item]="widget" *ngFor="let widget of dashboardWidgets">
					<div class="card card-tasks widget-card" id="widget-{{ widget.WidgetId }}"
						(click)="logWidget(widget)">


						<div style="display: flex; flex-direction: row"
							class="card-header widget-card-header drag-handler" id="widget-header-{{ widget.WidgetId }}"
							style="padding-top: 0px" *ngIf="widget.DashboardTypeId != 2">
							<h6 class="title" style="padding-top: 15px; display: inline-block; font-size: 14px;">
								{{ widget.WidgetName }}
								<span
									*ngIf="widget.WidgetTypeName != 'Camera View' && widget.WidgetTypeName != 'Sabel Data' && widget.WidgetTypeName != 'GSE Data' && widget.WidgetTypeName != 'Fleet Data' && widget.SiteList !== null && widget.SiteList !== undefined">:&nbsp;&nbsp;<span
										style="color: lightseagreen" #tooltip="matTooltip"
										matTooltip="{{ dataService.returnSiteListAsArrayOfNames(widget.SiteList) }}"
										[matTooltipPosition]="'below'" matTooltipHideDelay="10000">
										<strong>{{ dataService.returnSiteListAsArrayOfNames(widget.SiteList).length > 6
											? (dataService.returnSiteListAsArrayOfNames(widget.SiteList) | slice : 0 :
											6) + "..." : dataService.returnSiteListAsArrayOfNames(widget.SiteList) }}
										</strong>
									</span>
								</span>
								<span *ngIf="widget.VocationalSettingsJSON != null">:&nbsp;&nbsp;<span
										style="color: lightseagreen"><strong>{{widget.FleetName}}</strong></span></span>
								<span
									*ngIf="widget.WidgetSiteName != null && widget.SiteList === null && widget.VocationalSettingsJSON === null">:&nbsp;&nbsp;<span
										style="color: lightseagreen"><strong>{{ widget.WidgetSiteName
											}}</strong></span></span>
								<span
									*ngIf="widget.WidgetTypeName != 'Central Units Summary' && widget.WidgetTypeName?.substring(0,5) != 'Chart' && widget.WidgetTypeName != 'GSE Data' && widget.WidgetTypeName != 'Fleet Data' && widget.WidgetGateSystemName != null && widget.WidgetGateSystemName != 'C4C1'">&nbsp;<strong>Gate&nbsp;<span
											style="color: lightseagreen">{{ widget.WidgetGateSystemName
											}}</span></strong></span>
								<span
									*ngIf="widget.WidgetGateSystemName == null && widget.WidgetTerminalName != null">&nbsp;<strong>Terminal&nbsp;&nbsp;&nbsp;<span
											style="color: lightseagreen">{{ widget.WidgetTerminalName
											}}</span></strong></span>
								<span
									*ngIf="(widget.WidgetTypeName == 'Central Units Summary' || widget.WidgetGateSystemName == 'C4C1') && widget.WidgetTypeName != 'GSE Data' && widget.WidgetTypeName != 'Fleet Data' && widget.WidgetGateSystemName != null && widget.WidgetAssetName != null">
									&nbsp;<strong>{{ widget.WidgetGateSystemName }}&nbsp;&nbsp;<span
											style="color: lightseagreen">{{ widget.WidgetAssetName }}</span></strong>
								</span>
								<span
									*ngIf="widget.WidgetTypeName != 'Central Units Summary' && widget.WidgetGateSystemName != 'C4C1' && widget.WidgetAssetName != null">&nbsp;<strong>{{
										widget.WidgetAssetName }}</strong></span>
								<span *ngIf="findHiddenColumnCount(widget.WidgetId)?.hiddenColumnArray.length > 0"
									style="margin-left: 20px"><strong
										tooltip="{{ returnHiddenColumnsAsString(widget.WidgetId) }}"
										placement="bottom">{{
										findHiddenColumnCount(widget.WidgetId)?.hiddenColumnArray.length }} column{{
										findHiddenColumnCount(widget.WidgetId)?.hiddenColumnArray.length > 1 ? "s" : ""
										}} hidden</strong></span>
								<span
									*ngIf="widget.AngularDirectiveName === 'gsFlightSchedule' && findFlightDataTimer(widget.WidgetId)?.timer"
									style="margin-left: 10px; text-transform: none">Flight Data Updated: <i>{{
										findFlightDataTimer(widget.WidgetId)?.timer }}</i></span>
							</h6>

							<span
								*ngIf="widget.WidgetTypeName != 'Camera View' && widget.WidgetTypeName != 'Central Units Overview' && widget.WidgetTypeName != 'Sabel Data' && widget.WidgetTypeName != 'GSE Data' && widget.WidgetTypeName != 'Fleet Data'">
								<lib-widget-settings (widgetResizedEnabledChanged)="widgetResizeEnabledChanged(widget)"
									(widgetDragChanged)="widgetDragEnabledChanged(widget)"
									(widgetDeleted)="dashboardWidgetToDelete(widget)" [widgetObject]="widget"
									(widgetRememberSelectedTabIndexChanged)="widgetRememberSelectedTabIndexChanged(widget)"
									[currentDashboard]="dashboardService.currentDashboard"></lib-widget-settings>
							</span>

							<div *ngIf="widget.resizeEnabled == false"
								style="float: right; padding-left: 10px; padding-top: 10px">
								<i class="tim-icons icon-lock-circle" style="color: red" title="Resizing Disabled"></i>
							</div>
							<div *ngIf="widget.dragEnabled == false"
								style="float: right; padding-left: 10px; padding-top: 10px">
								<i class="tim-icons icon-pin" style="color: red" title="Dragging Disabled"></i>
							</div>
							<button mat-button
								*ngIf="widget.AngularDirectiveName == 'pbbSummary' || widget.AngularDirectiveName == 'pcaSummary' || widget.AngularDirectiveName == 'gpuSummary' || widget.AngularDirectiveName == 'ahuSummary' || widget.AngularDirectiveName == 'gseSummary' || widget.AngularDirectiveName == 'intelligentChargingSystemSummary' || widget.AngularDirectiveName == 'gseOverview' || ((widget.SiteList || widget.WidgetSiteId) && widget.AngularDirectiveName == 'siteGateSummary') || widget.AngularDirectiveName == 'deicerOverview' || widget.AngularDirectiveName == 'fuelLevel' ||  widget.AngularDirectiveName == 'locateAllGSE' "
								[disabled]="widget.alertNotificationDisabled" style="float: right;  padding-top: 5px"
								(click)="openAlertsPopup(widget)">
								<i class="tim-icons icon-bell-55 clipboard-larger"
									[ngStyle]="widget.alertNotificationColor" style="cursor: pointer"
									[matTooltip]="widget.alertNotificationTitle"></i>
							</button>
							<button mat-button
								*ngIf="widget.AngularDirectiveName == 'pbbSummary' || widget.AngularDirectiveName == 'pcaSummary' || widget.AngularDirectiveName == 'gpuSummary' || widget.AngularDirectiveName == 'ahuSummary' || widget.AngularDirectiveName == 'gseSummary' || widget.AngularDirectiveName == 'intelligentChargingSystemSummary' || widget.AngularDirectiveName == 'gseOverview' || ((widget.SiteList || widget.WidgetSiteId) && widget.AngularDirectiveName == 'siteGateSummary') || widget.AngularDirectiveName == 'deicerOverview' || widget.AngularDirectiveName == 'fuelLevel' ||  widget.AngularDirectiveName == 'locateAllGSE' "
								style="float: right;  padding-top: 5px" (click)="openNotesPopup(widget)">
								<mat-icon [matBadge]="this.dataService.evaluateNotesForWidget(widget)"
									[matBadgeHidden]="this.dataService.evaluateNotesForWidget(widget) == 0">notes</mat-icon>
							</button>
							<button mat-button
								*ngIf="widget.FileUploadImageEnabled && canUserSeeWidget(widget) && widget.WidgetTypeName != 'Camera View' && widget.WidgetTypeName != 'Central Units Overview' && widget.WidgetTypeName != 'Sabel Data' && widget.WidgetTypeName != 'GSE Data' && widget.WidgetTypeName != 'Fleet Data'"
								style="float: right; padding-top: 5px">
								<i (click)="widget.openFileImageUploadWindow()" [ngClass]="{
										'object-contains-files': widget.CountOfFilesUploaded >= 1
									}" class="tim-icons icon-notes clipboard-larger" aria-hidden="true" style="cursor: pointer"
									title="View files attached to this Asset.  {{ widget.CountOfFilesUploaded > 0 ? widget.CountOfFilesUploaded + ' file' + (widget.CountOfFilesUploaded > 1 ? 's' : '') + ' uploaded currently.' : '' }}">
								</i>
							</button>

							<div *ngIf="widget.WidgetTypeName != 'Camera View'"
								style="display: inline; float: right; padding-right: 10px; padding-top: 5px">
								<lib-live-data
									[live]="widget.isDisplayDataLive === undefined ? false : widget.isDisplayDataLive"></lib-live-data>
							</div>

							<div *ngIf="dashboardService.evaluateAngularDirectiveNameForTimeZoneOption('widget', widget)"
								style="display: inline; float: right; padding-right: 0px">
								<!-- <h6  matTooltip="Information in this widget is formatted to be in the timezone of the site the tag belongs to." [matTooltipPosition]="'below'" class="title" style="padding-top: 15px; display: inline-block">Site Time</h6> -->
								<h6 [matTooltip]="dashboardService.determineTimeZoneType(widget) === 'User Time' ? 'Information in this widget is formatted to be in the timezone of your computer.' : dashboardService.determineTimeZoneType(widget) === 'Site Time' ? 'Information in this widget is formatted to be in the timezone of the site the tag belongs to.' : dashboardService.determineTimeZoneType(widget) === 'UTC Time' ? 'Information in this widget is formatted to UTC Time, with no offset for a timezone.' : ''"
									[matTooltipPosition]="'below'" class="title"
									style="padding-top: 15px; display: inline-block">{{
									dashboardService.determineTimeZoneType(widget) }}</h6>
							</div>

							<div *ngIf="dashboardService.evaluateAngularDirectiveNameForTimeScopeOption('widget', widget)"
								style="display: inline; float: right; padding-right: 0px">
								<h6 class="title" style="padding-top: 15px; display: inline-block">
									{{ dashboardService.determineWhatTimeScopeForWidget(widget) }}
								</h6>
							</div>
						</div>
						<div *appInView class="card-body widget-card-body" id="widget-body-{{ widget.Id }}"
							[style.height.px]="widgetHeight$ | async">
							<div style="background-color: grey; width: 100%; height: 100%; padding: 5px; display: flex; justify-content: center; align-items: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: no-drop"
								*ngIf="!isUserAuthorizedToSeeWidget(widget.WidgetTypeId)">
								<span class="item" style="color: white">You do not have access to this widget. </span>
								<button class="jbt-btn mr-1" tooltip="New Widget"
									(click)="learnMoreAboutDisabledWidget()">Learn More</button>
							</div>

							<div style="height: 100%; width: 100%"
								id="{{ widget.AngularDirectiveName }}{{ widget.WidgetId }}">
								<ng-template #widgetsLazyLoadingSelector></ng-template>
							</div>
						</div>
					</div>
				</gridster-item>
			</gridster>
		</div>
	</div>
	<div *ngIf="global.isMobile" style="height: 100%">
		<!-- <ion-spinner *ngIf="isDataLoading"></ion-spinner> -->
		<div *ngIf="!isDataLoading" style="height: 100%">
			<div *ngIf="dashboardWidgets" id="gridsterParent" [style.width.%]="100" [style.height.%]="100"
				[style.min-width.px]="viewportWidth" [style.min-height.px]="viewportHeight">
				<ion-content>
					<gridster [options]="options">
						<gridster-item [item]="widget" *ngFor="let widget of dashboardWidgets">
							<div class="card card-tasks widget-card" id="widget-{{ widget.WidgetId }}"
								(click)="logWidget(widget)">



								<div class="card-header widget-card-header drag-handler"
									id="widget-header-{{ widget.WidgetId }}" #widgetElements style="padding-top: 0px"
									*ngIf="widget.DashboardTypeId != 2">
									<h6 class="title" style="margin-top: 12px; display: inline-block">
										{{ widget.WidgetName }}
										<span
											*ngIf="widget.WidgetTypeName != 'Camera View' && widget.WidgetTypeName != 'Sabel Data' && widget.WidgetTypeName != 'GSE Data' && widget.WidgetTypeName != 'Fleet Data' && widget.SiteList !== null && widget.SiteList !== undefined">:&nbsp;&nbsp;<span
												style="color: lightseagreen"><strong>
													{{ dataService.returnSiteListAsArrayOfNames(widget.SiteList).length
													> 3 ? (dataService.returnSiteListAsArrayOfNames(widget.SiteList) |
													slice : 0 : 3) + "..." :
													dataService.returnSiteListAsArrayOfNames(widget.SiteList) }}
												</strong></span></span>
												<span *ngIf="widget.VocationalSettingsJSON != null">:&nbsp;&nbsp;<span
													style="color: lightseagreen"><strong>{{widget.FleetName}}</strong></span></span>
										<span
											*ngIf="widget.WidgetSiteName != null && widget.SiteList === null">:&nbsp;&nbsp;<span
												style="color: lightseagreen"><strong>{{ widget.WidgetSiteName }}
												</strong></span></span>
											<span
											*ngIf="widget.WidgetTypeName != 'Central Units Summary' && widget.WidgetTypeName?.substring(0,5) != 'Chart' && widget.WidgetTypeName != 'GSE Data' && widget.WidgetTypeName != 'Fleet Data' && widget.WidgetGateSystemName != null && widget.WidgetGateSystemName != 'C4C1'">&nbsp;<strong>Gate&nbsp;<span
													style="color: lightseagreen">{{ widget.WidgetGateSystemName
													}}</span></strong></span>
										<span
											*ngIf="widget.WidgetGateSystemName == null && widget.WidgetTerminalName != null">&nbsp;<strong>Terminal&nbsp;&nbsp;&nbsp;<span
													style="color: lightseagreen">{{ widget.WidgetTerminalName
													}}</span></strong></span>
										<span
											*ngIf="(widget.WidgetTypeName == 'Central Units Summary' || widget.WidgetGateSystemName == 'C4C1') && widget.WidgetTypeName != 'GSE Data' && widget.WidgetTypeName != 'Fleet Data' && widget.WidgetGateSystemName != null && widget.WidgetAssetName != null">
											&nbsp;<strong>{{ widget.WidgetGateSystemName }}&nbsp;&nbsp;<span
													style="color: lightseagreen">{{ widget.WidgetAssetName
													}}</span></strong>
										</span>
										<span
											*ngIf="widget.WidgetTypeName != 'Central Units Summary' && widget.WidgetGateSystemName != 'C4C1' && widget.WidgetAssetName != null">&nbsp;<strong>{{
												widget.WidgetAssetName }}</strong></span>

												<span *ngIf="findHiddenColumnCount(widget.WidgetId)?.hiddenColumnArray.length > 0"
													style="margin-left: 20px"><strong
														tooltip="{{ returnHiddenColumnsAsString(widget.WidgetId) }}"
														placement="bottom">{{
														findHiddenColumnCount(widget.WidgetId)?.hiddenColumnArray.length }} column{{
														findHiddenColumnCount(widget.WidgetId)?.hiddenColumnArray.length > 1 ? "s" : ""
														}} hidden</strong></span>
												<span
													*ngIf="widget.AngularDirectiveName === 'gsFlightSchedule' && findFlightDataTimer(widget.WidgetId)?.timer"
													style="margin-left: 10px; text-transform: none">Flight Data Updated: <i>{{
														findFlightDataTimer(widget.WidgetId)?.timer }}</i></span>
									</h6>
									<lib-widget-settings
										(widgetResizedEnabledChanged)="widgetResizeEnabledChanged(widget)"
										(widgetRememberSelectedTabIndexChanged)="widgetRememberSelectedTabIndexChanged(widget)"
										(widgetDragChanged)="widgetDragEnabledChanged(widget)"
										(widgetDeleted)="dashboardWidgetToDelete(widget)"
										(widgetHeightChanged)="widgetHeightChanged(widget)" [widgetObject]="widget"
										[currentDashboard]="dashboardService.currentDashboard"></lib-widget-settings>
									<div *ngIf="widget.AngularDirectiveName == 'pbbSummary' || widget.AngularDirectiveName == 'pcaSummary' || widget.AngularDirectiveName == 'gpuSummary' || widget.AngularDirectiveName == 'ahuSummary' || widget.AngularDirectiveName == 'gseSummary' || widget.AngularDirectiveName == 'gseOverview' || widget.AngularDirectiveName == 'intelligentChargingSystemSummary'  || widget.AngularDirectiveName == 'siteGateSummary'"
										style="float: right; padding: 0px 5px; margin-top: 7px;">
										<i class="tim-icons icon-bell-55 clipboard-larger"
											[ngStyle]="widget.alertNotificationColor" style="cursor: pointer"
											matTooltip="Click to set alert management settings"
											(click)="openAlertsPopup(widget)"></i>
									</div>
									<div *ngIf="widget.FileUploadImageEnabled"
										style="float: right !important; padding-right: 5px !important; margin-top: 7px">
										&nbsp;&nbsp;&nbsp;<i (click)="widget.openFileImageUploadWindow()" [ngClass]="{
												'object-contains-files': widget.CountOfFilesUploaded >= 1
											}" class="tim-icons icon-notes clipboard-larger" aria-hidden="true" style="cursor: pointer"
											title="Attach files to this Asset.  {{ widget.CountOfFilesUploaded > 0 ? widget.CountOfFilesUploaded + ' file' + (widget.CountOfFilesUploaded > 1 ? 's' : '') + ' uploaded currently.' : '' }}">
										</i>
									</div>
									<div style="display: inline; float: right; padding-right: 10px; padding-top: 5px">
										<lib-live-data
											[live]="widget.isDisplayDataLive === undefined ? false : widget.isDisplayDataLive"></lib-live-data>
									</div>
								</div>

								<div *appInView class="card-body widget-card-body" id="widget-body-{{ widget.Id }}"
									[style.width.%]="100" [style.min-width.px]="viewportWidth"
									(mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()">
									<div class="no-access-to-widget"
										*ngIf="!isUserAuthorizedToSeeWidget(widget.WidgetTypeId)">
										<span class="item" style="color: white">You do not have access to this widget.
										</span>
										<button class="jbt-btn mr-1" tooltip="New Widget"
											(click)="learnMoreAboutDisabledWidget()">Learn More</button>
									</div>

									<div style="height: 100%; width: 100%"
										id="{{ widget.AngularDirectiveName }}{{ widget.WidgetId }}">
										<ng-template #widgetsLazyLoadingSelector></ng-template>
									</div>
								</div>
							</div>
						</gridster-item>
					</gridster>
				</ion-content>
			</div>
		</div>
	</div>
</div>
