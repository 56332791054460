import { BasicEnumerable } from "../BasicEnumerable";
export const zip = (source, second, resultSelector) => {
  if (resultSelector) {
    return zip2(source, second, resultSelector);
  } else {
    return zip1(source, second);
  }
};
const zip1 = (source, second) => {
  function* iterator() {
    const firstIterator = source[Symbol.iterator]();
    const secondIterator = second[Symbol.iterator]();
    while (true) {
      const a = firstIterator.next();
      const b = secondIterator.next();
      if (a.done && b.done) {
        break;
      } else {
        yield [a.value, b.value];
      }
    }
  }
  return new BasicEnumerable(iterator);
};
const zip2 = (source, second, resultSelector) => {
  function* iterator() {
    const firstIterator = source[Symbol.iterator]();
    const secondIterator = second[Symbol.iterator]();
    while (true) {
      const a = firstIterator.next();
      const b = secondIterator.next();
      if (a.done && b.done) {
        break;
      } else {
        yield resultSelector(a.value, b.value);
      }
    }
  }
  return new BasicEnumerable(iterator);
};