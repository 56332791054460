/**
 * Converts the Async Itertion to a Set
 * @param source Iteration
 * @returns Set containing the iteration values
 */
export const toSet = async source => {
  const set = new Set();
  for await (const item of source) {
    set.add(item);
  }
  return set;
};