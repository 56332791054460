import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
import { nextIteration } from "./_nextIteration";
import { nextIterationWithIndex } from "./_nextIterationWithIndex";
export const select = (source, key) => {
  if (typeof key === "function") {
    if (key.length === 1) {
      return new BasicParallelEnumerable(nextIteration(source, key));
    } else {
      return new BasicParallelEnumerable(nextIterationWithIndex(source, key));
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return new BasicParallelEnumerable(nextIteration(source, x => x[key]));
  }
};