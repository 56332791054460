import {
	Component,
	OnInit,
	ElementRef,
	EventEmitter,
	ViewChildren,
	ViewContainerRef,
	QueryList,
	OnDestroy,
	Input,
} from "@angular/core";

import {
	CompactType,
	DisplayGrid,
	GridsterItem,
	GridsterComponentInterface,
	GridsterConfig,
	GridsterItemComponentInterface,
	GridType,
} from "angular-gridster2";
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { v4 as uuidv4 } from 'uuid';

import _ from "lodash";

import { ActivatedRoute } from "@angular/router";

import { Observable, Subscription, Subject, fromEvent } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { AddWidgetToDashboardComponent } from "projects/shared-lib/src/lib/components/add-widget-to-dashboard/add-widget-to-dashboard.component";
import { ToastrService } from "ngx-toastr";
import { startWith, map } from "rxjs/operators";
import Swal from "sweetalert2";
import { FormControl, FormGroup } from "@angular/forms";
import { DashboardTimezonePopupConfirmationSettingsComponent } from "projects/shared-lib/src/lib/components/dashboard-timezone-popup-confirmation-settings/dashboard-timezone-popup-confirmation-settings.component";
import { DashboardTimescopePopupConfirmationSettingsComponent } from "projects/shared-lib/src/lib/components/dashboard-timescope-popup-confirmation-settings/dashboard-timescope-popup-confirmation-settings.component";
import { DataService } from "projects/shared-lib/src/lib/services/data.service";
import { DashboardService } from "projects/shared-lib/src/lib/services/dashboard.service";
import { SignalRCoreService } from "projects/shared-lib/src/lib/services/signalr-core.service";
import { Global } from "projects/shared-lib/src/lib/_constants/global.variables";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { IDashboard } from "projects/shared-lib/src/lib/_models/dashboard.model";
import { IWidget } from "projects/shared-lib/src/lib/_models/widget.model";
import { FileUploadListComponent } from "projects/shared-lib/src/lib/components/file-upload-list/file-upload-list.component";
import {
	EditWidgetComponent,
	WidgetSettingsComponent,
} from "projects/shared-lib/src/public-api";
import { WidgetIntervalSelectionPopupComponent } from "../../components/widget-interval-selection-popup/widget-interval-selection-popup.component";
import { WidgetTimeScopeSelectionPopupComponent } from "../../components/widget-time-scope-selection-popup/widget-time-scope-selection-popup.component";
import { WidgetSettingsPopupComponent } from "../widget-settings-popup/widget-settings-popup.component";

import { IGlobal } from "../../_models/global.model";
import $ from "jquery";
import { ScrollToWidgetComponent } from "projects/shared-lib/src/lib/components/scroll-to-widget/scroll-to-widget.component";
import { TacticalDashboardIntervalPopupConfirmationSettingsComponent } from "projects/shared-lib/src/lib/components/tactical-dashboard-interval-popup-confirmation-settings/tactical-dashboard-interval-popup-confirmation-settings.component";
import { DashboardCustomTimescopePopupConfirmationSettingsComponent } from "../dashboard-custom-timescope-popup-confirmation-settings/dashboard-custom-timescope-popup-confirmation-settings.component";
import {
	IStepOption,
	TourAnchorMatMenuDirective,
	TourMatMenuModule,
	TourService,
} from "ngx-ui-tour-md-menu";
import { AlertManagementPopupComponent } from '../alert-management-popup/alert-management-popup.component';

@Component({
	selector: "lib-shared-dashboard",
	templateUrl: "./shared-dashboard.component.html",
	styleUrls: ["./shared-dashboard.component.scss"]
})
export class SharedDashboardComponent implements OnInit {
	@ViewChildren("widgetElements") widgetElements: QueryList<ElementRef>;

	@ViewChildren("widgetsLazyLoadingSelector", { read: ViewContainerRef })
	widgetsLazyLoadingSelector!: QueryList<ViewContainerRef>;

	@Input() widgetHeightChangedEvent: EventEmitter<any>;
	widgetHeightChangedSubscription: Subscription;

	options: GridsterConfig;
	dashboard: IDashboard;
	gridsterDashboard: Array<GridsterItem>;
	dashboardId: number;
	dashboardWidgets: any;
	widgets: IWidget[];
	private sub: any;
	expandedDashboardsSub: Subscription;
	createdWidgetSub: Subscription;
	asyncDashboards$: Observable<any>;
	dashboardTitle: string;
	DialogRef: any;
	private static changed: Subject<any> = new Subject<any>();
	dashboardIndexInGlobalDashboardsArray: number;
	public widgetHeight$: Observable<number>;
	public widget$: Observable<any>;
	public height: number = 2.5;
	tagsToGraph: Array<any>;
	DashboardTimeScopes: Array<any>;
	dashboardObject: any;
	currentTimeScopeId: any;
	public widgetResizedEvent: EventEmitter<any> = new EventEmitter();
	public tagGraphCreatedEvent: EventEmitter<any> = new EventEmitter();
	public gseSummaryCreatedEvent: EventEmitter<any> = new EventEmitter();
	public pcaSummaryCreatedEvent: EventEmitter<any> = new EventEmitter();
	public pbbSummaryCreatedEvent: EventEmitter<any> = new EventEmitter();
	public gpuSummaryCreatedEvent: EventEmitter<any> = new EventEmitter();
	public ahuSummaryCreatedEvent: EventEmitter<any> = new EventEmitter();
	public icsSummaryCreatedEvent: EventEmitter<any> = new EventEmitter();
	public locateAllGSECreatedEvent: EventEmitter<any> = new EventEmitter();
	public dashboardTimeScopeChanged: EventEmitter<any> = new EventEmitter();
	public dashboardTimeZoneChanged: EventEmitter<any> = new EventEmitter();
	public dashboardUpdateIntervalChanged: EventEmitter<any> = new EventEmitter();
	private debugMode: boolean = false;
	createNewDashboardWidgetSubscriber: Subscription;
	widgetIsInViewSubscription: Subscription;
	searchString: string;
	searchObject: any;
	public fileImageData: any;
	public FileImageLibraryControlObject: any;
	hiddenColumnCountSubscription: any;
	hiddenColumnCountWidgetArray: any[];
	flightDataTimerSubscription: any;
	flightDataTimerWidgetArray: any[] = [];
	globalObject: any;
	private swalWithBootstrapButtons: any;
	editedWidgetSub: any;
	widgetTimeChangedSub: any;
	dashboardLocked: boolean;
	widgetMovementRecentlyChanged: boolean = false;
	private componentName: string = "dashboard";
	DashboardTimeZones: any;
	currentTimeZoneId: any;
	currentDashboardTimeZoneName: String = "";
	editUpdateIntervalModal: any;
	editDashboardTimeZoneModal: any;
	editDashboardTimeScopeModal: any;
	genericTacticalDashboardWidgetTypes: any[];
	currentUpdateIntervalName: any;
	reloadedWidgetSub: Subscription;
	fileUploadListComponentAfterClosedSubscription: Subscription;
	dashboardWidgetClickedSubscription: any;
	addWidgetToDashboardAfterClosedSubscription: any;
	editDashboadTimeScopeModalAfterClosedSubscription: any;
	editDashboardTimeZoneModalAfterClosedSubscription: any;
	editDashboardUpdateIntervalModalSubscription: any;
	public global: IGlobal = Global;
	public viewportHeight: number;
	public viewportWidth: number;
	isDataLoading: boolean = true;
	dashboardOptionExecutedSubscription: Subscription;
	scrollToWidgetAfterClosedSubscription: any;
	currentDashboard: any;
	public updateIntervalId: any;
	startDateTime: any;
	endDateTime: any;
	isTimeSelectionValid: boolean = true;
	maxDate: Date;
	form: FormGroup;
	editDashboardCustomTimeScopeModal: any;
	gridsterType: GridType = GridType.Fixed;
	currentGridsterTypeId: any = 2;
	DashboardGridsterTypes: any = [];

	constructor(public dataService: DataService, private route: ActivatedRoute, public dashboardService: DashboardService, public dialog: MatDialog, public toastr: ToastrService, public elementRef: ElementRef, private signalRCore: SignalRCoreService, private utilityService: UtilityService, public tourService: TourService) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-success",
				cancelButton: "btn btn-danger"
			},
			buttonsStyling: false
		});
	}

	findHiddenColumnCount(widgetId) {
		let columnCountObject = this.hiddenColumnCountWidgetArray.find((widgetObject) => {
			return widgetObject.widgetId === widgetId;
		});

		return columnCountObject;
	}

	returnHiddenColumnsAsString(widgetId) {
		let object = this.findHiddenColumnCount(widgetId);
		var hiddenColumnsAsString = "";
		if (object !== undefined) {
			object.hiddenColumnArray.forEach((hiddenColumn, index) => {
				if (index !== 0) {
					hiddenColumnsAsString = hiddenColumnsAsString + ", ";
				}
				hiddenColumnsAsString = hiddenColumnsAsString + hiddenColumn.title;
			});
			return hiddenColumnsAsString;
		}
	}

	findFlightDataTimer(widgetId) {
		let flightDataTimerObject = this.flightDataTimerWidgetArray.find((widgetObject) => {
			return widgetObject.widgetId === widgetId;
		});

		return flightDataTimerObject;
	}




	getCountOfFilesUploadedForEntity(entity: any, entityId: number, entityType: string) {
		var sqlStatement = "API.FileImageManagement 'Count', '" + entityType + "', " + entityId + ", @UserId = " + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			var listOfFiles = data;
			entity.CountOfFilesUploaded = listOfFiles.length > 0
					? listOfFiles.first().CountOfFilesUploaded
					: 0;
		});
	}

	openAlertsPopup(widget) {
		let alertLevel = "asset";

		let assetObj;
		let assetName;
		switch (widget.AngularDirectiveName) {
			case "siteGateSummary":
			case "gseOverview":
			case "deicerOverview":
			case "fuelLevel":
			case "locateAllGSE":
			case "fireTruckOverview":
				if ( widget.WidgetSiteId == null && widget.SiteList == null && widget.VocationalSettingsJSON == null ) {
					return;
				}
				if(widget.VocationalSettingsJSON == null) {
					alertLevel = "site";
				} else {
					alertLevel = "fleet";
				}
				break;
			case "fireTruckOverviewAerial":
				if ( widget.WidgetSiteId == null && widget.SiteList == null && widget.VocationalSettingsJSON == null ) {
					return;
				}
				if(widget.VocationalSettingsJSON == null) {
					alertLevel = "site";
				} else {
					alertLevel = "fleet";
				}
				break;
			case "fireTruckOverviewDriveline":
				if ( widget.WidgetSiteId == null && widget.SiteList == null && widget.VocationalSettingsJSON == null ) {
					return;
				}
				if(widget.VocationalSettingsJSON == null) {
					alertLevel = "site";
				} else {
					alertLevel = "fleet";
				}
				break;
			case "pbbSummary":
				assetName = "PBB";
				assetObj = this.findAssetByName(widget, assetName);
				break;
			case "pcaSummary":
				assetName = "PCA";
				assetObj = this.findAssetByName(widget, assetName);
				break;
			case "ahuSummary":
				assetName = "AHU";
				assetObj = this.findAssetByName(widget, assetName);
				break;
			case "gpuSummary":
				assetName = "GPU";
				assetObj = this.findAssetByName(widget, assetName);
				break;
			case "gseSummary":
				assetName = widget.WidgetAssetName;
				assetObj = this.findAssetById(widget, widget.WidgetAssetId);
				break;
			default:
				assetName = widget.WidgetAssetName;
				assetObj = widget.WidgetAssetId ? this.findAssetById(widget, widget.WidgetAssetId) : undefined;
				break;
		}

		const dlg = this.dialog.open(AlertManagementPopupComponent, {
			width: Global.isMobile ? "90%" : "40%",
			height: Global.isMobile ? "80%" : "70%",
			data: {
				alertLevel: alertLevel,
				widget: widget,
				item: assetObj
			},
			maxWidth: "100vw",
			maxHeight: "100vh"
		});
		let d = dlg.afterClosed().subscribe((result) => {});
	}

	canUserSeeWidget(widget) {
		if (this.isUserAuthorizedToSeeWidget(widget.WidgetTypeId) === true && (widget.refreshComponent === undefined || widget.refreshComponent === false)) {
			return true;
		} else {
			return false;
		}
	}

	isUserAuthorizedToSeeWidget(widgetTypeId) {
		if (Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.currentUser.Security.Aggregate.Collections.WidgetTypeIds.includes(parseInt(widgetTypeId))) {
			return true;
		} else {
			return false;
		}

		// return false;
	}

	learnMoreAboutDisabledWidget() {
		this.swalWithBootstrapButtons
			.fire({
				title: "<div><p style='color: rgb(84, 84, 84); font-size: 23px; font-weight: 400; text-align: left; margin-bottom: 10px;'>Widget Disabled</p></div>",
				html: "<p style='color: rgb(84, 84, 84); text-align: left;'>Why am I seeing this?</p><br><p style='text-align: left; color: rgb(84, 84, 84);'>Your organization no longer has access to this suite, which contains this widget type. Contact your support team to learn more.</p>",
				// icon: "warning", showCancelButton: true, confirmButtonText: "Yes, delete it!", cancelButtonText: "No, cancel!",
				reverseButtons: true
			})
			.then(() => {
				// if (result.value) { // logic for deleting dashboard goes here. this.widgetDeleted.emit(true); // then alerting the user the dashboard has been deleted. this.swalWithBootstrapButtons.fire("Deleted!", "Your widget has been deleted.", "success"); // _.remove(this.dashboards, function(n) { //   return n.Id === dashboard.Id;
				//  // });
				// } else if ( /* Read more about handling dismissals below */ result.dismiss === Swal.DismissReason.cancel
				// ) {
				//  this.swalWithBootstrapButtons.fire("Cancelled", "Your widget has not been deleted.", "error");
				// }
			});
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log("ngOnDestroy invoked...");

		if (this.sub) {
			this.sub.unsubscribe();
		}
		if (this.expandedDashboardsSub) {
			this.expandedDashboardsSub.unsubscribe();
		}
		if (this.createdWidgetSub) {
			this.createdWidgetSub.unsubscribe();
		}
		if (this.createNewDashboardWidgetSubscriber) {
			this.createNewDashboardWidgetSubscriber.unsubscribe();
		}
		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}
		if (this.editedWidgetSub) {
			this.editedWidgetSub.unsubscribe();
		}
		if (this.widgetTimeChangedSub) {
			this.widgetTimeChangedSub.unsubscribe();
		}
		if (this.hiddenColumnCountSubscription) {
			this.hiddenColumnCountSubscription.unsubscribe();
		}

		if (this.flightDataTimerSubscription) {
			this.flightDataTimerSubscription.unsubscribe();
		}
		if (this.reloadedWidgetSub) {
			this.reloadedWidgetSub.unsubscribe();
		}
		if (this.fileUploadListComponentAfterClosedSubscription) {
			this.fileUploadListComponentAfterClosedSubscription.unsubscribe();
		}
		if (this.dashboardWidgetClickedSubscription) {
			this.dashboardWidgetClickedSubscription.unsubscribe();
		}
		if (this.addWidgetToDashboardAfterClosedSubscription) {
			this.addWidgetToDashboardAfterClosedSubscription.unsubscribe();
		}
		if (this.editDashboadTimeScopeModalAfterClosedSubscription) {
			this.editDashboadTimeScopeModalAfterClosedSubscription.unsubscribe();
		}
		if (this.editDashboardTimeZoneModalAfterClosedSubscription) {
			this.editDashboardTimeZoneModalAfterClosedSubscription.unsubscribe();
		}
		if (this.editDashboardUpdateIntervalModalSubscription) {
			this.editDashboardUpdateIntervalModalSubscription.unsubscribe();
		}
		if (this.dashboardOptionExecutedSubscription) {
			this.dashboardOptionExecutedSubscription.unsubscribe();
		}
		if (this.scrollToWidgetAfterClosedSubscription) {
			this.scrollToWidgetAfterClosedSubscription.unsubscribe();
		}
		this.dashboardWidgets = undefined;

		this.dashboardService.closeAllOpenPopups();
	}

	scrollToWidget(widget) {
		//406292
		let tempwidgetid = 406295;

		Global.User.DebugMode && console.log(this.componentName + "widgetElements = %O", this.widgetElements);
		const elementToScrollInto = this.widgetElements.find((el) => {
			Global.User.DebugMode && console.log(this.componentName + "el = %O", el);
			return el.nativeElement.id === "widget-header-" + widget.value;
		});
		Global.User.DebugMode && console.log(this.componentName + "elementToScrollInto = %O", elementToScrollInto);

		if (elementToScrollInto !== undefined) {
			elementToScrollInto.nativeElement.scrollIntoView();
			this.utilityService.showToastMessageShared({
				type: "success",
				message: "Scrolled to widget."
			});
		}
	}

	ngOnInit() {
		if (Global.User.currentUser.Username == "dylan") {
			this.debugMode = true;
		}
		this.genericTacticalDashboardWidgetTypes = Global.User.currentUser.GenericTacticalDashboardWidgets;
		this.globalObject = Global;
		this.hiddenColumnCountWidgetArray = [];
		this.hiddenColumnCountSubscription = this.dashboardService.hiddenColumnCountChanged$.subscribe((data) => {
			Global.User.DebugMode && console.log(this.componentName + ": hiddenColumnCountChanged = %O", data);
			let objectIndex = this.hiddenColumnCountWidgetArray.findIndex((widgetObject) => {
				return widgetObject.widgetId === data.widgetId;
			});
			if (objectIndex !== -1) {
				this.hiddenColumnCountWidgetArray.splice(objectIndex, 1, data);
			} else {
				this.hiddenColumnCountWidgetArray.push(data);
			}
		});
		this.dashboardOptionExecutedSubscription = this.dashboardService.dashboardOptionExecuted$.subscribe((data) => {
			Global.User.DebugMode && console.log(this.componentName + "dashboardOptionExecutedSubscription = %O", data);
			if (data.title === "Add Widget to Dashboard") {
				let dashboard = Global.User.currentUser.Dashboards.find((obj) => obj.Id == this.dashboardId);

				this.DialogRef = this.dialog.open(AddWidgetToDashboardComponent, {
					width: "95%",
					height: "95%"
				});
				this.dashboardWidgetClickedSubscription = this.DialogRef.componentInstance.widgetClicked.subscribe((result) => {
					Global.User.DebugMode && console.log(this.componentName + ": widgetClicked = %O", result);
					let widget = _.clone(result);

					///Here we will add the ability to push the new widget into the grid. we will need to write functionality to update the db on the events that detect grid changes so that the new widget is added to db when those events fire
					this.dashboardWidgets.push(
						_.assign(widget, {
							cols: widget.InitialWidth,
							rows: widget.InitialHeight
						})
					);
					this.signalRCore.LogActivity("Added " + widget.WidgetTypeName + " widget to dashboard.");
					// this.toastr.success('Widget Successfully Added!');
					this.utilityService.showToastMessageShared({
						type: "success",
						message: widget.WidgetTypeName + " successfully added."
					});
				});

				this.addWidgetToDashboardAfterClosedSubscription = this.DialogRef.afterClosed().subscribe(() => {
					// Global.User.DebugMode && console.log(this.componentName + ": The modal was closed");
					this.dashboardWidgetClickedSubscription && this.dashboardWidgetClickedSubscription.unsubscribe();
					this.addWidgetToDashboardAfterClosedSubscription && this.addWidgetToDashboardAfterClosedSubscription.unsubscribe();
				});
			} else if (data.title === "Scroll to Widget") {
				// this.scrollToWidget(true);
				this.DialogRef = this.dialog.open(ScrollToWidgetComponent, {
					width: "95%",
					height: "95%",
					data: {
						widgets: this.dashboardWidgets
					}
				});
				this.scrollToWidgetAfterClosedSubscription = this.DialogRef.afterClosed().subscribe((data) => {
					if (data !== undefined) {
						this.scrollToWidget(data);
					}
					this.scrollToWidgetAfterClosedSubscription && this.scrollToWidgetAfterClosedSubscription.unsubscribe();
				});
			} else if (data.title === "Change Time Scope") {
				// this.scrollToWidget(true);
				this.currentTimeScopeId = data.id;
				this.dashboardService.currentTimeScopeId = data.id;
				this.changeDashboardTimeScope();
			} else if (data.title === "Change Time Zone") {
				// this.scrollToWidget(true);
				this.currentTimeZoneId = data.id;
				this.dashboardService.currentTimeZoneId = data.id;
				this.changeDashboardTimeZone();
			} else if (data.title === "Change Update Interval") {
				// this.scrollToWidget(true);
				this.updateIntervalId = data.id;
				this.dashboardService.currentUpdateIntervalId = data.id;
				this.changeDashboardUpdateInterval();
			} else if (data.title === "Add Tags") {
				this.AddGraphWidgetToDashboard();
			} else if (data.title === "Clear Tags") {
				this.ClearChartSelections();
			} else if (data.title === "Custom TimeScope") {
				this.changeDashboardCustomTimeScope();
			}
		});
		this.flightDataTimerSubscription = this.dashboardService.flightDataTimerTimerUpdated$.subscribe((data) => {
			let objectIndex = this.flightDataTimerWidgetArray.findIndex((widgetObject) => {
				return widgetObject.widgetId === data.widgetId;
			});
			if (objectIndex !== -1) {
				this.flightDataTimerWidgetArray.splice(objectIndex, 1, data);
			} else {
				this.flightDataTimerWidgetArray.push(data);
			}
		});

		this.searchObject = {};

		this.dashboardService.dashboardTimeScopes = Global.User.currentUser.DashboardTimeScopes;
		this.DashboardTimeScopes = this.dashboardService.dashboardTimeScopes;
		this.DashboardGridsterTypes = this.dataService.cache.gridsterTypes.filter((gridsterType) => {
			return gridsterType.Id == 2 || gridsterType.Id == 4;
		})
		Global.User.DebugMode && console.log("dashboard: Global.User.currentUser.DashboardTimeScopes data = %O", this.DashboardTimeScopes);

		this.DashboardTimeZones = Global.User.currentUser.DashboardTimeZones;
		Global.User.DebugMode && console.log("dashboard: this.dashboardObject = %O", this.dashboardObject);

		this.widgetHeight$ = fromEvent(this.elementRef.nativeElement, "resize").pipe(startWith(null), map(this.getHeight.bind(this)));
		this.widgetIsInViewSubscription = this.dashboardService.widgetInViewChanged$.subscribe((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + ": this.dashboardService.widgetInViewChanged$ data = %O", data);
			//Global.User.DebugMode && console.log(this.componentName + ": this.dashboardWidgets = %O", this.dashboardWidgets);
			if (this.dashboardWidgets != undefined) {
				//-- Stephen was receiving an error on this next line since it expected dashboardWidgets to be defined and they weren't yet. --Kirk T. Sherer, November 3, 2021.
				let index = this.dashboardWidgets.findIndex((widget: any) => {
					return data.widgetId === widget.WidgetId;
				});
				if (index !== -1) {
					this.dashboardWidgets[index].isWidgetInView = data.isInView;
					//now map widget and import

					if ((this.dashboardWidgets[index].widgetHasBeenLazyLoaded === false || _.isNil(this.dashboardWidgets[index].widgetHasBeenLazyLoaded)) && this.dashboardWidgets[index].isWidgetInView === true) {
						if (this.canUserSeeWidget(this.dashboardWidgets[index])) {
							this.loadEditedOrCreatedWidget(index);
						}
					}
					if (data.isInView === true) {
						this.dashboardWidgets[index].widgetHasBeenLazyLoaded = true;
					}
				}
			}
		});
		this.createNewDashboardWidgetSubscriber = this.dataService.createNewDashboardWidget$.subscribe((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + ": createNewDashboardWidget = %O", data);
			this.dashboardWidgets.push(data);
		});

		//subscribing to the route changes to get the dashboard id so we can go look up the contents of the dashboard.
		this.expandedDashboardsSub = this.dashboardService._expandedDashboards.subscribe((returnedWidgets) => {
			var newCollectionFinal = [];
			let dashboardLocked = true;
			if (returnedWidgets.length === 0) {
				dashboardLocked = false;
			}
			_.forEach(returnedWidgets, (widget: any) => {
				if (widget.dragEnabled === true || widget.resizeEnabled === true) {
					dashboardLocked = false;
				}
				if (widget.WidgetTypeId !== null) {
					widget.cols = widget.Width;
					widget.rows = widget.Height;
					widget.x = widget.Col;
					widget.y = widget.Row;
					if (widget.FileUploadImageEnabled) {
						this.setFileImageUploadSettings(widget);
					}
					Global.User.DebugMode && console.log(this.componentName + ": widget = %O", widget);

					// Force Site Time when logged in as another user
					if (Global.User.isLoggedInAsDifferentUser == true) {
						widget.TimeZoneId = 2;
					}

					newCollectionFinal.push(widget);
				}
			});
			Global.User.DebugMode && console.log(this.componentName + ": this.dashboardLocked = %O", this.dashboardLocked);
			if (this.dashboardLocked === undefined) {
				this.dashboardLocked = false;
			}
			this.dashboardWidgets = newCollectionFinal;
			this.dashboardLocked = dashboardLocked;

			Global.User.DebugMode && console.log(this.componentName + ": Global.User.currentUser.Dashboards = %O", Global.User.currentUser.Dashboards);

			if (this.dashboardIndexInGlobalDashboardsArray !== undefined && Global.User.currentUser.Dashboards[this.dashboardIndexInGlobalDashboardsArray].widgets === undefined) {
				Global.User.currentUser.Dashboards[this.dashboardIndexInGlobalDashboardsArray].widgets = this.dashboardWidgets;
			}

			if (Global.User.currentUser.DashboardsObject[this.dashboardId].widgets === undefined) {
				Global.User.currentUser.DashboardsObject[this.dashboardId].widgets = this.dashboardWidgets;
			}

			Global.User.DebugMode && console.log(this.componentName + ": this.dashboardWidgets = %O", this.dashboardWidgets);

			this.dashboardTitle = Global.CurrentMenuItem;
			// Global.User.DebugMode && console.log(this.componentName + ": this.dashboardTitle = " + this.dashboardTitle);
		});

		this.editedWidgetSub = this.dashboardService._editedWidget.subscribe((editedWidget: any) => {
			if (!_.isEmpty(editedWidget)) {
				let index = _.findIndex(this.dashboardWidgets, {
					WidgetId: editedWidget[0].WidgetId
				});
				if (index !== -1 && this.dashboardWidgets[index] && this.dashboardWidgets[index].FileUploadImageEnabled) {
					this.setFileImageUploadSettings(this.dashboardWidgets[index]);
				}

				// do this for GS Top 5 Alarms widget
				if (editedWidget[0].AngularDirectiveName == "tGSTopFive") {
					let assetForDisplay = this.dataService.cache.assets.find((a) => {
						return parseInt(a.Id) == parseInt(editedWidget[0].WidgetAssetId);
					});
					if (assetForDisplay != undefined) {
						Global.User.DebugMode && console.log(this.componentName + "assetForDisplay: %O", assetForDisplay);
						editedWidget[0].WidgetAssetName = assetForDisplay.Site?.Name + " " + assetForDisplay.ParentSystem?.Name + " " + assetForDisplay.Name;
						Global.User.DebugMode && console.log(this.componentName + "assetNameForDisplay: " + editedWidget[0].WidgetAssetName);
					}
				}
			}
		});

		this.reloadedWidgetSub = this.dashboardService._reloadedWidget.subscribe((reloadedWidget: any) => {
			if (!_.isEmpty(reloadedWidget)) {
				let index = _.findIndex(this.dashboardWidgets, {
					WidgetId: reloadedWidget.WidgetId
				});
				if (index !== -1 && this.dashboardWidgets[index] && this.dashboardWidgets[index].FileUploadImageEnabled) {
					this.setFileImageUploadSettings(this.dashboardWidgets[index]);
				}
				setTimeout(() => {
					Global.User.DebugMode && console.log(this.componentName + "this.widgetsLazyLoadingSelector = %O", this.widgetsLazyLoadingSelector);
					if (index !== -1) {
						this.loadEditedOrCreatedWidget(index);
					}
				}, 100);
			}
		});

		this.widgetTimeChangedSub = this.dashboardService._widgetTimeChanged.subscribe((emitObject: any) => {
			if (!_.isEmpty(emitObject)) {
				if (emitObject.TimeChangeType === "time zone") {
					this.dashboardTimeZoneChanged.emit(emitObject.data);
				} else if (emitObject.TimeChangeType === "time scope") {
					this.dashboardTimeScopeChanged.emit(emitObject.data);
				} else if (emitObject.TimeChangeType === "update interval") {
					this.dashboardUpdateIntervalChanged.emit(emitObject.data);
				}
				//determine if this was a time scope change or a time zone change, then emit
			}
		});

		this.createdWidgetSub = this.dashboardService._createdWidget.subscribe((returnedWidget: any) => {
			Global.User.DebugMode && console.log(this.componentName + ": returnedWidget = %O", returnedWidget);

			if (!_.isEmpty(returnedWidget)) {
				let index = this.dashboardWidgets.findIndex((widget: any) => {
					return widget.WidgetId === null;
				});
				if (index !== -1) {
					Object.assign(this.dashboardWidgets[index], returnedWidget[0]);

					if (this.dashboardWidgets[index].FileUploadImageEnabled) {
						this.setFileImageUploadSettings(this.dashboardWidgets[index]);
					}

					let newestAddedWidget = this.dashboardWidgets[index];
					if (newestAddedWidget.AngularDirectiveName === "gseSummary") {
						this.gseSummaryCreatedEvent.emit({
							WidgetId: newestAddedWidget.WidgetId
						});
					}

					if (newestAddedWidget.AngularDirectiveName === "pcaSummary") {
						this.pcaSummaryCreatedEvent.emit({
							WidgetId: newestAddedWidget.WidgetId
						});
					}

					if (newestAddedWidget.AngularDirectiveName === "pbbSummary") {
						this.pbbSummaryCreatedEvent.emit({
							WidgetId: newestAddedWidget.WidgetId
						});
					}

					if (newestAddedWidget.AngularDirectiveName === "gpuSummary") {
						this.gpuSummaryCreatedEvent.emit({
							WidgetId: newestAddedWidget.WidgetId
						});
					}
					if (newestAddedWidget.AngularDirectiveName === "ahuSummary") {
						this.ahuSummaryCreatedEvent.emit({
							WidgetId: newestAddedWidget.WidgetId
						});
					}

					if (newestAddedWidget.AngularDirectiveName === "locateAllGSE") {
						this.locateAllGSECreatedEvent.emit({
							WidgetId: newestAddedWidget.WidgetId
						});
					}

					if (newestAddedWidget.AngularDirectiveName === "tagGraph") {
						Global.User.DebugMode && console.log(this.componentName + ": this.dataService.tempTagsToChart = %O", this.dataService.tempTagsToChart);

						let tagIdsToAdd = [];
						_.forEach(this.dataService.tempTagsToChart, (TagId) => {
							tagIdsToAdd.push(TagId);
						});

						let tagIdsToAddString = tagIdsToAdd.join(",");

						let statement = "API.AddTagsToTagGraphByWidgetId " + newestAddedWidget.WidgetId + ",'" + tagIdsToAddString + "'";

						this.dataService.SQLActionAsPromise(statement).then(() => {
							this.dataService.tempTagsToChart.length = 0;
							//fire event emitter which means the tags are affiliated with the widget, so now we can initialize the tagGraph. Pass the widget id so that we can evaluate if this widget id is the right one since it will be broadcasted to all instances of tagGrahph components on the screen.
							this.tagGraphCreatedEvent.emit({
								WidgetId: newestAddedWidget.WidgetId,
								DashboardTimeScopes: this.DashboardTimeScopes
							});
						});

						//need to also get dashboard timescope if there is one, or set one by clicking buttons example of one. appears to be objects. vm.dashboard.DashboardTimeScope = { Id: 6 Description: "24 Hours  " Days: 1
						// }
					}
					if (newestAddedWidget.isWidgetInView) {
						this.loadEditedOrCreatedWidget(newestAddedWidget);
					}

					if (this.dashboardLocked === true) {
						newestAddedWidget.dragEnabled = !this.dashboardLocked;
						newestAddedWidget.resizeEnabled = !this.dashboardLocked;
						this.options.api.optionsChanged();

						this.itemChange(newestAddedWidget);
					}
				}
			}
		});

		this.sub = this.route.params.subscribe((params) => {
			this.dashboardId = +params["id"];


			this.dashboardService.closeAllOpenPopups();

			Global.User.DebugMode && console.log(this.componentName + ": this.dashboardId = " + this.dashboardId);

			if (this.dashboardId !== undefined) {
				this.currentDashboard = Global.User.currentUser.Dashboards.find((obj) => obj.Id == this.dashboardId);
				if (this.currentDashboard.CustomStartDate != null && this.currentDashboard.CustomEndDate != null) {
					this.maxDate = new Date();
					let startDate = new Date(this.currentDashboard.CustomStartDateMS);
					let endDate = new Date(this.currentDashboard.CustomEndDateMS);
					this.form = new FormGroup({
						startDateTime: new FormControl(startDate),
						endDateTime: new FormControl(endDate)
					});
				}

				if (Global.User.currentUser.Username == "dylan") {
					console.log(this.currentDashboard);
				}
				Global.User.DebugMode && console.log(this.componentName + ": Global.User.currentUser.Dashboards = %O", Global.User.currentUser.Dashboards);
				this.dashboardIndexInGlobalDashboardsArray = Global.User.currentUser.Dashboards.findIndex((obj) => obj.Id == this.dashboardId);
				if (Global.User.currentUser.Dashboards[this.dashboardIndexInGlobalDashboardsArray].widgets === undefined) {
					this.dashboardService.loadAllExpandedDashboards(this.dashboardId);
				} else {
					Global.User.currentUser.Dashboards[this.dashboardIndexInGlobalDashboardsArray].widgets.forEach((widget) => {
						widget.widgetHasBeenLazyLoaded = false;
						widget.isWidgetInView = false;
					});
					this.dashboardWidgets = Global.User.currentUser.Dashboards[this.dashboardIndexInGlobalDashboardsArray].widgets;
				}
				this.dashboardObject = Global.User.currentUser.Dashboards[this.dashboardIndexInGlobalDashboardsArray];
				console.log("shared-dashboard: dashboardObject = %O", this.dashboardObject);

				this.currentTimeZoneId = this.dashboardObject.TimeZoneId;
				this.utilityService.updateCurrentMenuItem(this.dashboardObject.Name);
				this.updateIntervalId = this.dashboardObject.UpdateIntervalId && 2; //-- defaulting this to Id number 2 for every 15 minutes since some dashboards don't have this set when they should. --Kirk T. Sherer, February 22, 2024.
				console.log("shared-dashboard: Global.User.currentUser.TacticalDashboardUpdateIntervals = %O", Global.User.currentUser.TacticalDashboardUpdateIntervals);
				if (!_.isNil(this.updateIntervalId) && this.updateIntervalId !== 0) {
					this.currentUpdateIntervalName = Global.User.currentUser.TacticalDashboardUpdateIntervals.find((tz) => tz.Id == this.updateIntervalId).Description;
				}

				if (!_.isNil(this.currentTimeZoneId) && this.currentTimeZoneId !== 0) {
					this.currentDashboardTimeZoneName = this.DashboardTimeZones.find((tz) => tz.Id == this.currentTimeZoneId).Description;
				}
				if(_.isNil(this.dashboardObject.GridsterTypeId)) {
					this.dashboardObject.GridsterTypeId = 2;
				}
				this.currentGridsterTypeId = this.dashboardObject.GridsterTypeId ;
				this.currentTimeScopeId = this.dashboardObject.TimeScopeId;
				this.evaluateGridsterType();

				this.dashboardService.currentDashboard = Global.User.currentUser.Dashboards[this.dashboardIndexInGlobalDashboardsArray];
				this.dashboardService.currentDashboardId = this.dashboardId;

				Global.User.DebugMode && console.log(this.componentName + ": dashboardObject = %O", this.dashboardObject);

				let dashboardLocked = true;
				if (this.dashboardWidgets !== undefined) {
					if (this.dashboardWidgets.length === 0) {
						dashboardLocked = false;
					} else {
						this.dashboardWidgets.forEach((widget: any) => {
							if (widget.dragEnabled === true || widget.resizeEnabled === true) {
								dashboardLocked = false;
							}
						});
					}

					this.dashboardLocked = dashboardLocked;
				}
			}

			this.dashboardTitle = Global.CurrentMenuItem;
			// Global.User.DebugMode && console.log(this.componentName + ": current dashboard in this route: " + this.dashboardId);
		});

		this.viewportWidth = this.dashboardService.screenWidth;
		this.viewportHeight = this.dashboardService.screenHeight - 50; //-- the minus 50 is so that we don't lose the bottom of the widgets. --Kirk T. Sherer, August 2, 2021.

		this.options = {
			initCallback: SharedDashboardComponent.gridInit, //callback to call after grid has initialized
			destroyCallback: SharedDashboardComponent.gridDestroy, //callback to call after grid has destroyed
			gridSizeChangedCallback: this.gridSizeChanged.bind(this), //callback to call after grid has changed size cols/rows
			itemChangeCallback: this.itemChange.bind(this), //callback to call for each item when is changes x, y, rows, cols
			itemResizeCallback: this.itemResize.bind(this), //callback to call for each item when width/height changes
			itemInitCallback: this.itemInit.bind(this), //callback to call for each item when is initialized
			itemRemovedCallback: this.itemRemoved.bind(this), //callback to call for each item when is removed
			itemValidateCallback: SharedDashboardComponent.itemValidate, //callback to call to validate item position/size. Return true if valid.
			compactType: CompactType.CompactUp,
			margin: 5,
			outerMargin: false,
			outerMarginTop: null,
			outerMarginRight: null,
			outerMarginBottom: null,
			outerMarginLeft: null,
			useTransformPositioning: false,
			mobileBreakpoint: Global.isMobile ? 5000 : 640,
			minCols: 1,
			maxCols: 60,
			height: Global.isMobile || this.currentGridsterTypeId === 4 ? this.viewportHeight + "px" : "2000px",
			width: Global.isMobile || this.currentGridsterTypeId === 4 ? this.viewportWidth + "px" : "2000px",
			minRows: 1,
			maxRows: 100,
			maxItemCols: 60,
			minItemCols: 1,
			maxItemRows: 100,
			minItemRows: 1,
			maxItemArea: 5000,
			minItemArea: 1,
			defaultItemCols: 1,
			defaultItemRows: 1,
			fixedColWidth: 60,
			fixedRowHeight:  60,
			setGridSize: false, //leave as true so that this displays properly on mac. Sets the grid height to the size of its child components.
			keepFixedHeightInMobile: false,
			keepFixedWidthInMobile: false,
			scrollSensitivity: 10,
			scrollSpeed: 20,
			enableEmptyCellClick: false,
			enableEmptyCellContextMenu: false,
			enableEmptyCellDrop: false,
			enableEmptyCellDrag: false,
			enableOccupiedCellDrop: false,
			emptyCellDragMaxCols: 50,
			emptyCellDragMaxRows: 50,
			ignoreMarginInRow: false,
			draggable: {
				enabled: true,
				ignoreContent: true,
				dragHandleClass: "drag-handler"
			},
			resizable: {
				enabled: true
			},
			swap: false,
			pushItems: true,
			disablePushOnDrag: false,
			disablePushOnResize: false,
			pushDirections: {
				north: true,
				east: false,
				south: true,
				west: false
			},
			pushResizeItems: false,
			displayGrid: DisplayGrid.Always,
			disableWindowResize: false,
			disableWarnings: false,
			scrollToNewItems: false
		};

		this.evaluateGridsterType();


		if (this.widgetHeightChangedEvent) {
			this.widgetHeightChangedSubscription = this.widgetHeightChangedEvent.subscribe((data) => {
				this.widgetHeightChanged(data);
			});
		}

		let interval = setInterval(() => {
			if (this.dashboardWidgets != null) {
				this.dashboardWidgets.forEach((widget) => {
					this.widgetAlertNotificationColor(widget);
				});
			}
		}, 10000);

		// old documentation

		// html String|HTMLElement The string of HTMLElement that represents the widget is going to be added. size_x Number The number of rows that the widget occupies. Defaults to 1. size_y Number The number of columns that the widget occupies. Defaults to 1. col Number The column the widget should start in. row Number The row the widget should start in.
		if (this.dashboardLocked === undefined) {
			this.dashboardLocked = false;
		}
		this.isDataLoading = false;
	}

	findAssetByName(widget, assetName): any {
		return this.dataService.cache.assets.find((a) => a.SiteId == widget.WidgetSiteId && (widget.WidgetGateSystemId == undefined || a.ParentSystemId == widget.WidgetGateSystemId) && a.Name == assetName);
	}

	findAssetById(widget, assetId): any {
		return this.dataService.cache.assets.find((a) => a.Id == assetId);
	}

	widgetAlertNotificationColor(widget): any {
		let alertLevel = "asset";
		widget.alertNotificationTitle = "Click to set alert management settings.";

		let assetObj;
		let assetName;
		switch (widget.AngularDirectiveName) {
			case "siteGateSummary":
			case "gseOverview":
			case "deicerOverview":
			case "fuelLevel":
			case "locateAllGSE":
			case "fireTruckOverview":
				alertLevel = "site";
				break;
				case "fireTruckOverviewAerial":
					alertLevel = "site";
					break;
					case "fireTruckOverviewDriveline":
						alertLevel = "site";
						break;
			case "pbbSummary":
				assetName = "PBB";
				assetObj = this.findAssetByName(widget, assetName);
				break;
			case "pcaSummary":
				assetName = "PCA";
				assetObj = this.findAssetByName(widget, assetName);
				break;
			case "ahuSummary":
				assetName = "AHU";
				assetObj = this.findAssetByName(widget, assetName);
				break;
			case "gpuSummary":
				assetName = "GPU";
				assetObj = this.findAssetByName(widget, assetName);
				break;
			case "gseSummary":
				assetName = widget.WidgetAssetName;
				assetObj = this.findAssetById(widget, widget.WidgetAssetId);
				break;
			default:
				return;

				break;
		}

		let userAlert = [];

		if (alertLevel == "asset") {
			// disable the bell if no asset is selected
			if (assetObj == null) {
				widget.alertNotificationDisabled = true;
				return;
			}
			else {
				widget.alertNotificationDisabled = false;
			}

			userAlert = this.dataService.cache.emailTagUsers.filter((a) => a.UserId == Global.User.currentUser.Id && (a.CriticalAssetId == assetObj.Id || a.AssetId == assetObj.Id || a.AlertAssetId == assetObj.Id));

			// set the title with alert info
			if (userAlert?.length > 0) {
				let title = userAlert[0].CriticalAssetId == assetObj.Id ? "Critical " : "";
				title += "Alert notifications set for " + assetName;
				let alertObject = this.GetAlertNotificationDetails(userAlert[0]);

				if (alertObject.Email == true && alertObject.TextMessage == true) {
					title += " email and text delivery";
				} else {
					title += alertObject.Email == true ? " email delivery" : "";
					title += alertObject.TextMessage == true ? " text delivery" : "";
				}

				title += " on " + alertObject.DaysSelected;
				title += " during " + alertObject.HoursSelected;

				widget.alertNotificationTitle = title;
			}
		} else if (alertLevel == "site") {
			if (widget.WidgetSiteId != null) {
				let siteId = widget.WidgetSiteId;
				userAlert = this.dataService.cache.emailTagUsers.filter((a) => a.UserId == Global.User.currentUser.Id && (a.CriticalSiteId == siteId || a.SiteId == siteId));
				let title = this.GetAlertSummaryForSite(siteId, userAlert);
				widget.alertNotificationTitle = title != "" ? title : "Click to set alert management settings.";
			} else if (!Array.isArray(widget.SiteList) && widget.SiteList != null) {
				let siteId = widget.SiteList;
				userAlert = this.dataService.cache.emailTagUsers.filter((a) => a.UserId == Global.User.currentUser.Id && (a.CriticalSiteId == siteId || a.SiteId == siteId));
				let title = this.GetAlertSummaryForSite(siteId, userAlert);
				widget.alertNotificationTitle = title != "" ? title : "Click to set alert management settings.";
			} else if (widget.SiteList?.length > 0) {
				let alertNotificationTitle = [];

				widget.SiteList.forEach((siteId) => {
					let userAlertForSite = this.dataService.cache.emailTagUsers.filter((a) => a.UserId == Global.User.currentUser.Id && (a.CriticalSiteId == siteId || a.SiteId == siteId));
					if (userAlertForSite && userAlertForSite.length > 0) {
						userAlert.push(userAlertForSite[0]);

						let title = this.GetAlertSummaryForSite(siteId, userAlertForSite);
						if (title != "") {
							alertNotificationTitle.push(title);
						}
					}
				});

				if (alertNotificationTitle.length > 0) {
					widget.alertNotificationTitle = alertNotificationTitle.join(",  ");
				}
			} else if (widget.VocationalSettingsJSON != null) {
				var VocationalSettings = JSON.parse(widget.VocationalSettingsJSON);
				//Global.User.DebugMode && console.log("VocationalSettings ", VocationalSettings);
				let fleetId = VocationalSettings.id;

				userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
									a.UserId == Global.User.currentUser.Id && (a.CriticalThingId == fleetId || a.ThingId == fleetId || a.AlertThingId == fleetId));
				let title = this.GetAlertSummaryForFleet(fleetId, userAlert);
				widget.alertNotificationTitle = title != "" ? title : "Click to set alert management settings.";

			} else {
				// disable the bell if no site is selected
				widget.alertNotificationDisabled = true;
				return;
			}
		}

		// set the color for the bell
		if (userAlert?.length > 0) {
			widget.alertNotificationColor = { color: "green" };
		} else {
			widget.alertNotificationColor = { color: "white" };
		}
	}

	GetAlertSummaryForSite(siteId, userAlert): any {
		let title = "";

		// set the title with alert info
		if (userAlert?.length > 0) {
			let siteName = this.dataService.cache.sitesObject[siteId].Name;

			title = userAlert[0].CriticalSiteId == siteId ? "Critical " : "";
			title += "Alert notifications set for " + siteName;

			let alertObject = this.GetAlertNotificationDetails(userAlert[0]);

			if (alertObject.Email == true && alertObject.TextMessage == true) {
				title += " email and text delivery";
			} else {
				title += alertObject.Email == true ? " email delivery" : "";
				title += alertObject.TextMessage == true ? " text delivery" : "";
			}

			title += " on " + alertObject.DaysSelected;
			title += " during " + alertObject.HoursSelected;
		}

		return title;
	}

	GetAlertSummaryForFleet(fleetId, userAlert): any {
		let title = "";

		let fleetName = "";

		// set the title with alert info
		if (userAlert?.length > 0) {
			this.dataService.GetFleets().subscribe((data: any) => {
				if(data.length > 0) {
					let myFleet = data.find((fleet) => {
						return ( fleet.FleetId === fleetId );
					});

					if(myFleet) {

						fleetName = myFleet.FleetName;

						title = userAlert[0].CriticalSiteId == fleetId ? "Critical " : "";
						title += "Alert notifications set for " + fleetName;

						let alertObject = this.GetAlertNotificationDetails(userAlert[0]);

						if (alertObject.Email == true && alertObject.TextMessage == true) {
							title += " email and text delivery";
						} else {
							title += alertObject.Email == true ? " email delivery" : "";
							title += alertObject.TextMessage == true ? " text delivery" : "";
						}

						title += " on " + alertObject.DaysSelected;
						title += " during " + alertObject.HoursSelected;
					}

				}
			});


		}

		return title;
	}

	GetAlertNotificationDetails(userAlert): any {
		let alertObject: any = {
			siteId: 0,
			siteName: "",
			gateSystemId: 0,
			gateSystemName: "",
			category: "",
			Email: 0
		};

		// delivery
		alertObject.Email = userAlert.Email == 1 ? true : false;
		alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;

		// set days
		if (userAlert.SendAllDays == 1) {
			alertObject.DaysSelected = "all days";
		} else {
			alertObject.DaysSelected = "select days";
		}

		// calc work hours
		let hourToStart = userAlert.UserTimeZoneOffset + 8;

		// set hours
		if (userAlert.SendAllHours == 1) {
			alertObject.HoursSelected = "all Hours";
		} else {
			alertObject.HoursSelected = "select Hours";
		}

		return alertObject;
	}

	changeDashboardLockedToggle(event) {
		Global.User.DebugMode && console.log(this.componentName + "event = %O", event);
		Global.User.DebugMode && console.log(this.componentName + "this.dashboardLocked = " + this.dashboardLocked);
		if (this.widgetMovementRecentlyChanged === false) {
			if (this.dashboardWidgets !== undefined && this.dashboardWidgets.length > 0) {
				this.dashboardWidgets.forEach((widget) => {
					widget.dragEnabled = !this.dashboardLocked;
					widget.resizeEnabled = !this.dashboardLocked;
					this.itemChange(widget);
				});
				this.options.api.optionsChanged();
			}
		} else if (this.widgetMovementRecentlyChanged === true) {
			this.widgetMovementRecentlyChanged = false;
		}
	}

	private async loadEditedOrCreatedWidget(index) {
		await this.mapWidget(this.dashboardWidgets[index]);
		setTimeout(() => {
			this.widgetsLazyLoadingSelector.map((vcr: any, i: number) => {
				let dw = this.dashboardWidgets[index];

				if (vcr.element.nativeElement.parentElement.id !== null) {
					let parentElementName = vcr.element.nativeElement.parentElement.id;

					if (parentElementName.includes(dw.AngularDirectiveName + dw.WidgetId)) {
						vcr.clear();
						const { instance }: any = vcr.createComponent(dw.component[dw.ProperComponentName]);
						Global.User.DebugMode && console.log(this.componentName + "DashboardTypeId = " + dw.DashboardTypeId);
						switch (dw.AngularDirectiveName) {
							
							case "mobileConditionedOverview":
							case "fuelLevel":
							case "deicerOverview":
								instance.widgetObject = dw;
								break;
								
							case "gseOverview":
							case "locateAllGSE":
							case "fireTruckOverview":
							case "fireTruckOverviewAerial":
							case "fireTruckOverviewDriveline":
								instance.widgetObject = dw;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								break;

							case "siteGateSummary":
								instance.widgetObject = dw;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								break;
							case "siteCameraView":
							case "cameraView":
							case "sabelData":
							case "gseData":
								instance.widgetObject = dw;
								// TODO instance.dashoardWidgetDeleted = dashboardWidgetToDelete()

								break;
							case "weather":
								instance.widgetObject = dw;

								break;
							case "pbbSummary":
								instance.widgetObject = dw;
								instance.pbbSummaryCreatedEvent = this.pbbSummaryCreatedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "pcaSummary":
								instance.widgetObject = dw;
								instance.pcaSummaryCreatedEvent = this.pcaSummaryCreatedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "gsEquipmentHoursOfUsage":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;

								break;
							case "gsEquipmentUsage":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;

								break;
							case "gpuSummary":
								instance.widgetObject = dw;
								instance.gpuSummaryCreatedEvent = this.gpuSummaryCreatedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "siteActiveAlarms":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "siteActiveCommsLoss":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;

								break;
							case "siteActiveWarnings":
								instance.widgetObject = dw;
								instance.searchValue = this.searchObject;

								break;
							case "gseActiveAlarms":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "gseUserDefinedActiveAlarms":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "gsTopFiveAlarmTypesByEquipment":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;

								break;
							case "assetTracking":
								instance.widgetObject = dw;

								break;
							case "gseTopFiveAlarmTypes":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;

								break;
							case "gseTopFiveEquipmentAlarmTypes":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;

								break;
							case "gseSummary":
								instance.widgetObject = dw;
								instance.gseSummaryCreatedEvent = this.gseSummaryCreatedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;
								break;
							case "pcaDischargePerformance":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;

								break;
							case "tagGraph":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;
								instance.dashboard = this.dashboardObject;
								instance.tagGraphCreatedEvent = this.tagGraphCreatedEvent;

								break;
							case "terminalOverview":
								instance.widgetObject = dw;

								break;
							case "canvas":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;

								break;
							case "rawTagDataForAsset":
								instance.widgetObject = dw;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "assetGeofencing":
								instance.widgetObject = dw;

								break;
							case "wheelChairSummary":
								instance.widgetObject = dw;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "wheelChairOverview":
								instance.widgetObject = dw;

								break;
							case "centralUnitsSummary":
								instance.widgetObject = dw;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "centralUnitsOverview":
								instance.widgetObject = dw;

								break;
							case "gsTopFiveAlarmTypes":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;

								break;
							case "gsPerfectHookup":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;

								break;
							case "gsPerfectHookupOpportunity":
								instance.widgetObject = dw;
								instance.widgetResizedEvent = this.widgetResizedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;

								break;
							case "gsFlightSchedule":
								instance.widgetObject = dw;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "gsePeakReportData":
								instance.widgetObject = dw;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "alarmEmailSettingsForAsset":
								instance.widgetObject = dw;
								break;
							case "ahuSummary":
								instance.widgetObject = dw;
								instance.ahuSummaryCreatedEvent = this.ahuSummaryCreatedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "intelligentChargingSystemSummary":
								instance.widgetObject = dw;
								instance.icsSummaryCreatedEvent = this.icsSummaryCreatedEvent;
								instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;

								break;
							case "testGrid":
								instance.widgetObject = dw;
								break
							default:
								if (dw.DashboardTypeId === 2) {
									instance.widgetObject = dw;
									instance.dashboardUpdateIntervalChanged = this.dashboardUpdateIntervalChanged;
									instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;
									instance.widgetResizedEvent = this.widgetResizedEvent;
									break
								} else {

									instance.widgetObject = dw;
									instance.dashboardTimeZoneChanged = this.dashboardTimeZoneChanged;
									instance.dashboardTimeScopeChanged = this.dashboardTimeScopeChanged;
									instance.dashboardUpdateIntervalChanged = this.dashboardUpdateIntervalChanged;
									break
								}
						}
					}
				}
			});
		}, 1000);
	}

	async mapWidgets() {
		for (const widget of this.dashboardWidgets) {
			await this.mapWidget(widget);
			Global.User.DebugMode && console.log(this.componentName + "widget = %O", widget);
		}

		// for  (const widget of this.widgets.map()) this.widgets.forEach((widget: any) => { widget.component = import('../widgets/gse-overview/gse-overview.component');

		// })
	}

	async mapWidget(widget: any) {
		switch (widget.AngularDirectiveName) {
			case "gseOverview":
			case "mobileConditionedOverview":
			case "fuelLevel":
			case "deicerOverview":
			case "fireTruckOverview":
				case "fireTruckOverviewAerial":
					case "fireTruckOverviewDriveline":
				widget.ProperComponentName = "AssetOverviewParentComponent";
				await import("projects/shared-lib/src/lib/components/asset-overview-parent/asset-overview-parent.module");
				widget.component = await import("projects/shared-lib/src/lib/components/asset-overview-parent/asset-overview-parent.component");

				break;
			case "locateAllGSE":
				widget.ProperComponentName = "LocateAllGseWidgetComponent";
				await import("projects/shared-lib/src/lib/components/locate-all-gse-widget/locate-all-gse-widget.module");
				widget.component = await import("projects/shared-lib/src/lib/components/locate-all-gse-widget/locate-all-gse-widget.component");

				break;

			case "siteGateSummary":
				widget.ProperComponentName = "SiteAllGateSummaryComponent";
				await import("projects/shared-lib/src/lib/components/site-all-gate-summary/site-all-gate-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/site-all-gate-summary/site-all-gate-summary.component");

				break;
			case "siteCameraView":
				widget.ProperComponentName = "SiteCameraViewComponent";
				await import("projects/shared-lib/src/lib/components/site-camera-view/site-camera-view.module");
				widget.component = await import("projects/shared-lib/src/lib/components/site-camera-view/site-camera-view.component");

				break;
			case "weather":
				widget.ProperComponentName = "WeatherWidgetComponent";
				await import("projects/shared-lib/src/lib/components/weather-widget/weather-widget.module");
				widget.component = await import("projects/shared-lib/src/lib/components/weather-widget/weather-widget.component");

				break;
			case "pbbSummary":
				widget.ProperComponentName = "PbbSummaryComponent";
				await import("projects/shared-lib/src/lib/components/pbb-summary/pbb-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/pbb-summary/pbb-summary.component");

				break;
			case "pcaSummary":
				widget.ProperComponentName = "PcaSummaryComponent";
				await import("projects/shared-lib/src/lib/components/pca-summary/pca-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/pca-summary/pca-summary.component");

				break;
			case "gsEquipmentHoursOfUsage":
				widget.ProperComponentName = "GsEquipmentHoursOfOperationComponent";
				await import("projects/shared-lib/src/lib/components/gs-equipment-hours-of-operation/gs-equipment-hours-of-operation.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gs-equipment-hours-of-operation/gs-equipment-hours-of-operation.component");

				break;
			case "gsEquipmentUsage":
				widget.ProperComponentName = "GsEquipmentCycleCountSummaryComponent";
				await import("projects/shared-lib/src/lib/components/gs-equipment-cycle-count-summary/gs-equipment-cycle-count-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gs-equipment-cycle-count-summary/gs-equipment-cycle-count-summary.component");
				this.settingsOnWidget(widget);
				break;
			case "gpuSummary":
				widget.ProperComponentName = "GpuSummaryComponent";
				await import("projects/shared-lib/src/lib/components/gpu-summary/gpu-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gpu-summary/gpu-summary.component");

				break;
			case "siteActiveAlarms":
				widget.ProperComponentName = "SiteActiveAlarmsComponent";
				await import("projects/shared-lib/src/lib/components/site-active-alarms/site-active-alarms.module");
				widget.component = await import("projects/shared-lib/src/lib/components/site-active-alarms/site-active-alarms.component");

				break;
			case "siteActiveCommsLoss":
				widget.ProperComponentName = "SiteActiveCommsLossComponent";
				await import("projects/shared-lib/src/lib/components/site-active-comms-loss/site-active-comms-loss.module");
				widget.component = await import("projects/shared-lib/src/lib/components/site-active-comms-loss/site-active-comms-loss.component");

				break;
			case "gseActiveAlarms":
				widget.ProperComponentName = "SiteActiveAlarmsComponent";
				await import("projects/shared-lib/src/lib/components/site-active-alarms/site-active-alarms.module");
				widget.component = await import("projects/shared-lib/src/lib/components/site-active-alarms/site-active-alarms.component");

				break;
			case "gseUserDefinedActiveAlarms":
				widget.ProperComponentName = "GseOperationalAlarmsComponent";
				await import("projects/shared-lib/src/lib/components/gse-operational-alarms/gse-operational-alarms.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-operational-alarms/gse-operational-alarms.component");

				break;

			case "assetTracking":
				widget.ProperComponentName = "AdveezSiteComponent";
				await import("projects/shared-lib/src/lib/components/adveez-site/adveez-site.module");
				widget.component = await import("projects/shared-lib/src/lib/components/adveez-site/adveez-site.component");

				break;

			case "gseSummary":
				widget.ProperComponentName = "AssetSummaryParentComponent";
				await import("projects/shared-lib/src/lib/components/asset-summary-parent/asset-summary-parent.module");
				widget.component = await import("projects/shared-lib/src/lib/components/asset-summary-parent/asset-summary-parent.component");

				break;
			case "pcaDischargePerformance":
				widget.ProperComponentName = "PcaDischargePerformanceComponent";
				await import("projects/shared-lib/src/lib/components/pca-discharge-performance/pca-discharge-performance.module");
				widget.component = await import("projects/shared-lib/src/lib/components/pca-discharge-performance/pca-discharge-performance.component");

				break;
			case "tagGraph":
				widget.ProperComponentName = "TagGraphComponent";
				await import("projects/shared-lib/src/lib/components/tag-graph/tag-graph.module");
				widget.component = await import("projects/shared-lib/src/lib/components/tag-graph/tag-graph.component");

				break;
			case "terminalOverview":
				widget.ProperComponentName = "TerminalOverviewComponent";
				await import("projects/shared-lib/src/lib/components/terminal-overview/terminal-overview.module");
				widget.component = await import("projects/shared-lib/src/lib/components/terminal-overview/terminal-overview.component");

				break;
			case "canvas":
				widget.ProperComponentName = "CanvasComponent";
				await import("projects/shared-lib/src/lib/components/canvas/canvas.module");
				widget.component = await import("projects/shared-lib/src/lib/components/canvas/canvas.component");

				break;
			case "rawTagDataForAsset":
				widget.ProperComponentName = "RawTagDataForAssetComponent";
				await import("projects/shared-lib/src/lib/components/raw-tag-data-for-asset/raw-tag-data-for-asset.module");
				widget.component = await import("projects/shared-lib/src/lib/components/raw-tag-data-for-asset/raw-tag-data-for-asset.component");

				break;
			case "assetGeofencing":
				widget.ProperComponentName = "AssetGeofencingComponent";
				await import("projects/shared-lib/src/lib/components/asset-geofencing/asset-geofencing.module");
				widget.component = await import("projects/shared-lib/src/lib/components/asset-geofencing/asset-geofencing.component");

				break;
			case "wheelChairSummary":
				widget.ProperComponentName = "WheelchairSummaryComponent";
				await import("projects/shared-lib/src/lib/components/wheelchair-summary/wheelchair-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/wheelchair-summary/wheelchair-summary.component");

				break;
			case "wheelChairOverview":
				widget.ProperComponentName = "WheelchairOverviewComponent";
				await import("projects/shared-lib/src/lib/components/wheelchair-overview/wheelchair-overview.module");
				widget.component = await import("projects/shared-lib/src/lib/components/wheelchair-overview/wheelchair-overview.component");

				break;
			case "centralUnitsSummary":
				widget.ProperComponentName = "CentralunitsSummaryComponent";
				await import("projects/shared-lib/src/lib/components/centralunits-summary/centralunits-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/centralunits-summary/centralunits-summary.component");

				break;
			case "centralUnitsOverview":
				widget.ProperComponentName = "CentralunitsOverviewComponent";
				await import("projects/shared-lib/src/lib/components/centralunits-overview/centralunits-overview.module");
				widget.component = await import("projects/shared-lib/src/lib/components/centralunits-overview/centralunits-overview.component");
				(widget.deleteFunction = () => this.onDelete(widget)), (widget.editWidgetName = () => this.editWidgetName(widget));

				break;

			case "gsPerfectHookup":
				widget.ProperComponentName = "PerfectTurnComponent";
				await import("projects/shared-lib/src/lib/components/perfect-turn/perfect-turn.module");
				widget.component = await import("projects/shared-lib/src/lib/components/perfect-turn/perfect-turn.component");

				break;
			case "gsPerfectHookupOpportunity":
				widget.ProperComponentName = "PerfectTurnOpportunityComponent";
				await import("projects/shared-lib/src/lib/components/perfect-turn-opportunity/perfect-turn-opportunity.module");
				widget.component = await import("projects/shared-lib/src/lib/components/perfect-turn-opportunity/perfect-turn-opportunity.component");

				break;
			case "gsFlightSchedule":
				widget.ProperComponentName = "FlightScheduleComponent";
				await import("projects/shared-lib/src/lib/components/flight-schedule/flight-schedule.module");
				widget.component = await import("projects/shared-lib/src/lib/components/flight-schedule/flight-schedule.component");

				break;
			case "gsePeakReportData":
				widget.ProperComponentName = "GsePeakReportsDataComponent";
				await import("projects/shared-lib/src/lib/components/gse-peak-reports-data/gse-peak-reports-data.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-peak-reports-data/gse-peak-reports-data.component");

				break;

			case "alarmEmailSettingsForAsset":
				widget.ProperComponentName = "AlertConfigurationComponent";
				await import("projects/shared-lib/src/lib/components/alert-configuration/alert-configuration.module");
				widget.component = await import("projects/shared-lib/src/lib/components/alert-configuration/alert-configuration.component");

				break;
			case "ahuSummary":
				widget.ProperComponentName = "AhuSummaryComponent";
				await import("projects/shared-lib/src/lib/components/ahu-summary/ahu-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/ahu-summary/ahu-summary.component");

				break;
			case "intelligentChargingSystemSummary":
				widget.ProperComponentName = "IntelligentChargingSystemSummaryComponent";
				await import("projects/shared-lib/src/lib/components/intelligent-charging-system-summary/intelligent-charging-system-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/intelligent-charging-system-summary/intelligent-charging-system-summary.component");

				break;
			case "testGrid":
				widget.ProperComponentName = "TestGridComponent";
				await import("projects/shared-lib/src/lib/components/test-grid/test-grid.module");
				widget.component = await import("projects/shared-lib/src/lib/components/test-grid/test-grid.component");

				break;
			case "cameraView":
				widget.ProperComponentName = "CameraViewComponent";
				await import("projects/shared-lib/src/lib/components/camera-view/camera-view.module");
				widget.component = await import("projects/shared-lib/src/lib/components/camera-view/camera-view.component");
				(widget.deleteFunction = () => this.onDelete(widget)), (widget.editWidgetName = () => this.editWidgetName(widget)), (widget.siteChange = () => this.siteChange(widget)), (widget.systemChange = () => this.systemChange(widget)), (widget.assetChange = () => this.assetChange(widget));
				break;
			case "sabelData":
				widget.ProperComponentName = "SabelDataComponent";
				await import("projects/shared-lib/src/lib/components/sabel-data/sabel-data.module");
				widget.component = await import("projects/shared-lib/src/lib/components/sabel-data/sabel-data.component");
				(widget.deleteFunction = () => this.onDelete(widget)), (widget.editWidgetName = () => this.editWidgetName(widget)), (widget.siteChange = () => this.siteChange(widget)), (widget.systemChange = () => this.systemChange(widget)), (widget.assetChange = () => this.assetChange(widget));
				break;
			case "gseData":
				widget.ProperComponentName = "GSEDataComponent";
				await import("projects/shared-lib/src/lib/components/gse-data/gse-data.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-data/gse-data.component");
				(widget.deleteFunction = () => this.onDelete(widget)), (widget.editWidgetName = () => this.editWidgetName(widget)), (widget.siteChange = () => this.siteChange(widget)), (widget.assetChange = () => this.assetChange(widget));

				break;
			case "outOfServiceAssets":
				widget.ProperComponentName = "GseOutOfServiceOrMaintenanceModeComponent";
				await import("projects/shared-lib/src/lib/components/gse-out-of-service-or-maintenance-mode/gse-out-of-service-or-maintenance-mode.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-out-of-service-or-maintenance-mode/gse-out-of-service-or-maintenance-mode.component");
				this.settingsOnWidget(widget);
				break;
			case "availableAssets":
				widget.ProperComponentName = "GseAvailableAssetsComponent";
				await import("projects/shared-lib/src/lib/components/gse-available-assets/gse-available-assets.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-available-assets/gse-available-assets.component");
				this.settingsOnWidget(widget);
				break;
			case "assetsInUse":
				widget.ProperComponentName = "GseAssetsInUseComponent";
				await import("projects/shared-lib/src/lib/components/gse-assets-in-use/gse-assets-in-use.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-assets-in-use/gse-assets-in-use.component");
				this.settingsOnWidget(widget);
				break;
			case "tacticalEquipmentActiveAlarms":
				widget.ProperComponentName = "GseEquipmentActiveAlarmsComponent";
				await import("projects/shared-lib/src/lib/components/gse-equipment-active-alarms/gse-equipment-active-alarms.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-equipment-active-alarms/gse-equipment-active-alarms.component");
				this.settingsOnWidget(widget);
				break;
			case "tacticalUserActiveAlarms":
				widget.ProperComponentName = "GseUserActiveAlarmsComponent";
				await import("projects/shared-lib/src/lib/components/gse-user-active-alarms/gse-user-active-alarms.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-user-active-alarms/gse-user-active-alarms.component");
				this.settingsOnWidget(widget);
				break;
			case "gsAssetsInUse":
				widget.ProperComponentName = "GsAssetsInUseComponent";
				await import("projects/shared-lib/src/lib/components/gs-assets-in-use/gs-assets-in-use.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gs-assets-in-use/gs-assets-in-use.component");
				this.settingsOnWidget(widget);
				break;
			case "gsAvailableAssets":
				widget.ProperComponentName = "GsAvailableAssetsComponent";
				await import("projects/shared-lib/src/lib/components/gs-available-assets/gs-available-assets.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gs-available-assets/gs-available-assets.component");
				this.settingsOnWidget(widget);

				break;
			case "gsOutOfService":
				widget.ProperComponentName = "GsOutOfServiceOrMaintenanceModeComponent";
				await import("projects/shared-lib/src/lib/components/gs-out-of-service-or-maintenance-mode/gs-out-of-service-or-maintenance-mode.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gs-out-of-service-or-maintenance-mode/gs-out-of-service-or-maintenance-mode.component");
				this.settingsOnWidget(widget);

				break;
			case "pbbDocking":
				widget.ProperComponentName = "PbbDockingComponent";
				await import("projects/shared-lib/src/lib/components/pbb-docking/pbb-docking.module");
				widget.component = await import("projects/shared-lib/src/lib/components/pbb-docking/pbb-docking.component");
				this.settingsOnWidget(widget);
				break;
			case "gsAlarmStatus":
				widget.ProperComponentName = "GsAlarmStatusComponent";
				await import("projects/shared-lib/src/lib/components/gs-alarm-status/gs-alarm-status.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gs-alarm-status/gs-alarm-status.component");
				this.settingsOnWidget(widget);
				break;
			case "gsCommsLoss":
				widget.ProperComponentName = "GsCommsLossComponent";
				await import("projects/shared-lib/src/lib/components/gs-comms-loss/gs-comms-loss.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gs-comms-loss/gs-comms-loss.component");
				this.settingsOnWidget(widget);
				break;
			case "tGSTopFive":
				widget.ProperComponentName = "TacticalGstopFiveAlarmsComponent";
				await import("projects/shared-lib/src/lib/components/tactical-gstop-five-alarms/tactical-gstop-five-alarms.module");
				widget.component = await import("projects/shared-lib/src/lib/components/tactical-gstop-five-alarms/tactical-gstop-five-alarms.component");
				this.settingsOnWidget(widget);

				break;
			case "tGSETopFive":
				widget.ProperComponentName = "TacticalGsetopFiveAlarmsComponent";
				await import("projects/shared-lib/src/lib/components/tactical-gsetop-five-alarms/tactical-gsetop-five-alarms.module");
				widget.component = await import("projects/shared-lib/src/lib/components/tactical-gsetop-five-alarms/tactical-gsetop-five-alarms.component");
				this.settingsOnWidget(widget);

				break;
			case "tacticalFireEngineOverview":
				widget.ProperComponentName = "TacticalFireEngineOverviewComponent";
				await import("projects/shared-lib/src/lib/components/tactical-fire-engine-overview/tactical-fire-engine-overview.module");
				widget.component = await import("projects/shared-lib/src/lib/components/tactical-fire-engine-overview/tactical-fire-engine-overview.component");
				this.settingsOnWidget(widget);

				break;
			case "equipmentCycleCountSummary":
				widget.ProperComponentName = "EquipmentCycleCountSummaryComponent";
				await import("projects/shared-lib/src/lib/components/equipment-cycle-count-summary/equipment-cycle-count-summary.module");
				widget.component = await import("projects/shared-lib/src/lib/components/equipment-cycle-count-summary/equipment-cycle-count-summary.component");
				this.settingsOnWidget(widget);

				break;
			case "gsEquipmentNotUsedLast":
				widget.ProperComponentName = "GsEquipmentNotUsedLastComponent";
				await import("projects/shared-lib/src/lib/components/gs-equipment-not-used-last/gs-equipment-not-used-last.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gs-equipment-not-used-last/gs-equipment-not-used-last.component");
				this.settingsOnWidget(widget);
				break;
			case "gseEquipmentNotUsedLast":
				widget.ProperComponentName = "GseEquipmentNotUsedLastComponent";
				await import("projects/shared-lib/src/lib/components/gse-equipment-not-used-last/gse-equipment-not-used-last.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-equipment-not-used-last/gse-equipment-not-used-last.component");
				this.settingsOnWidget(widget);
				break;
			case "tacticalGSELevels":
				widget.ProperComponentName = "TacticalGseLevelsComponent";
				await import("projects/shared-lib/src/lib/components/tactical-gse-levels/tactical-gse-levels.module");
				widget.component = await import("projects/shared-lib/src/lib/components/tactical-gse-levels/tactical-gse-levels.component");
				this.settingsOnWidget(widget);
				break;
			case "tacticalFireTruckLevels":
				widget.ProperComponentName = "TacticalFireTruckLevelsComponent";
				await import("projects/shared-lib/src/lib/components/tactical-fire-truck-levels/tactical-fire-truck-levels.module");
				widget.component = await import("projects/shared-lib/src/lib/components/tactical-fire-truck-levels/tactical-fire-truck-levels.component");
				this.settingsOnWidget(widget);
				break;
			case "pcaNotMeetingPerformance":
				widget.ProperComponentName = "PcaNotMeetingPerformanceComponent";
				await import("projects/shared-lib/src/lib/components/pca-not-meeting-performance/pca-not-meeting-performance.module");
				widget.component = await import("projects/shared-lib/src/lib/components/pca-not-meeting-performance/pca-not-meeting-performance.component");
				this.settingsOnWidget(widget);
				break;
			case "tacticalOperationalAlerts":
				widget.ProperComponentName = "GseOperationalAlertsComponent";
				await import("projects/shared-lib/src/lib/components/gse-operational-alerts/gse-operational-alerts.module");
				widget.component = await import("projects/shared-lib/src/lib/components/gse-operational-alerts/gse-operational-alerts.component");
				this.settingsOnWidget(widget);
				break;
			case "tIntelligentChargingSystem":
				widget.ProperComponentName = "TacticalIntelligentChargingSystemComponent";
				await import("projects/shared-lib/src/lib/components/tactical-intelligent-charging-system/tactical-intelligent-charging-system.module");
				widget.component = await import("projects/shared-lib/src/lib/components/tactical-intelligent-charging-system/tactical-intelligent-charging-system.component");
				this.settingsOnWidget(widget);
				break;
		}

		// widget.component = await import(`../widgets/${widget.name}/${widget.name}.component`);
	}

	settingsOnWidget(widget: any) {
		(widget.deleteFunction = () => this.onDelete(widget)), 
		(widget.editWidgetName = () => this.editWidgetName(widget)), 
		(widget.editTimeScopeSettings = () => this.editTimeScopeSettings(widget)), 
		(widget.editIntervalSettings = () => this.editIntervalSettings(widget)), 
		(widget.editWidgetSettings = () => this.editWidgetSettings(widget)), 
		(widget.invertResizeSetting = () => this.invertResizeSetting(widget)), 
		(widget.invertDraggableSetting = () => this.invertDraggableSetting(widget)),
		(widget.invertRememberingOfSelectedTabIndex = () => this.widgetRememberSelectedTabIndexChanged(widget)),
		(widget.makeWidgetTaller = () => this.changeWidgetHeight("taller", widget)), 
		(widget.makeWidgetShorter = () => this.changeWidgetHeight("shorter", widget));
	}
	findWidgetTimeZoneName(TimeZoneId) {
		let timeZoneName = this.DashboardTimeZones.find((tz) => TimeZoneId == tz.Id).Description;
		return timeZoneName;
	}

	setFileImageUploadSettings(widget: any) {
		var service = this;
		var entityId = widget.WidgetTypeName == "Fleet Overview" ? null : widget.WidgetAssetId == null ? (widget.WidgetTerminalSystemId == null ? widget.WidgetSiteId : widget.WidgetTerminalSystemId) : widget.WidgetAssetId;
		var entityType = widget.WidgetTypeName == "Fleet Overview" ? "AdveezAsset" : widget.WidgetAssetId == null ? (widget.WidgetTerminalSystemId == null ? "Site" : "System") : "Asset";
		var title: string = widget.WidgetTypeName == "Fleet Overview" ? "Fleet Documentation" : (widget.WidgetTypeName.indexOf("Fleet") != -1 ? "" : widget.WidgetSiteName) + (widget.WidgetGateSystemName != null ? " Gate " + widget.WidgetGateSystemName : widget.WidgetAssetName == null ? (widget.WidgetTerminalName == null ? " Site" : " Terminal " + widget.WidgetTerminalName) : widget.WidgetAssetName) + " Documentation";

		widget.openFileImageUploadWindow = function () {
			var sqlStatement = "API.FileImageManagement @Type='List', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
			service.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				Global.User.DebugMode && console.log(this.componentName + ": " + sqlStatement + " = %O", data);
				service.fileImageData = data;
				service.FileImageLibraryControlObject = {
					imageKeys: service.fileImageData.filter((i: any) => {
						return i.ImageKey;
					}),
					//---B
					removeUploadFunction(deletedImageKey: string) {
						service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Delete', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + deletedImageKey + "', @UserId=" + Global.User.currentUser.Id).then((remainingFileImageKeyList: any) => {
							Global.User.DebugMode && console.log(service.componentName + ": remainingFileImageKeyList after deletion: %O", remainingFileImageKeyList);
							service.FileImageLibraryControlObject.imageKeys = remainingFileImageKeyList;
							Global.User.DebugMode && console.log(service.componentName + ": service.FileImageLibraryControlObject.imageKeys.length = " + service.FileImageLibraryControlObject.imageKeys.length);
							Global.User.DebugMode && console.log(service.componentName + ": service.FileImageLibraryControlObject = %O", service.FileImageLibraryControlObject);
							service.fileImageData = remainingFileImageKeyList;
						});
					},
					//---B
					addUploadFunction(newImageKey: string) {
						service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Add', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + newImageKey + "', @UserId=" + Global.User.currentUser.Id).then((updatedFileImageKeyList: any) => {
							Global.User.DebugMode && console.log(service.componentName + ": updatedFileImageKeyList after new file added: %O", updatedFileImageKeyList);
							service.FileImageLibraryControlObject.imageKeys = updatedFileImageKeyList;
							Global.User.DebugMode && console.log(service.componentName + ": service.FileImageLibraryControlObject.imageKeys.length = " + service.FileImageLibraryControlObject.imageKeys.length);
							Global.User.DebugMode && console.log(service.componentName + ": service.FileImageLibraryControlObject = %O", service.FileImageLibraryControlObject);
							service.fileImageData = updatedFileImageKeyList;
						});
					},
					mode: null,
					listTitle: title,
					entityId: entityId,
					entityType: entityType,
					closeUploadWindowFunction() {
						service.FileImageLibraryControlObject = null;
					}
				};

				service.openDialog("fileUpload", widget);
			});
		};
	}

	openDialog(type: string, widget: any): void {
		var innerWidth = window.innerWidth;
		if (type == "fileUpload") {
			const dialogRef = this.dialog.open(FileUploadListComponent, {
				width: "70%",
				height: "70%"
			});

			dialogRef.componentInstance.fileImageLibraryControlObject = this.FileImageLibraryControlObject;

			this.fileUploadListComponentAfterClosedSubscription = dialogRef.afterClosed().subscribe(() => {
				Global.User.DebugMode && console.log(this.componentName + ": The dialog was closed");
				Global.User.DebugMode && console.log(this.componentName + ": this.FileImageLibraryControlObject.imageKeys.length = " + this.FileImageLibraryControlObject.imageKeys.length);
				widget.CountOfFilesUploaded = this.FileImageLibraryControlObject.imageKeys.length;
				this.fileUploadListComponentAfterClosedSubscription.unsubscribe();
			});
		}
	}

	doKeywordFilter(searchValue: string, widgetId: any) {
		this.searchString = searchValue.trim().toLocaleLowerCase();
		this.searchObject = {
			searchString: searchValue.trim().toLocaleLowerCase(),
			widgetId: widgetId
		};
		// this.searchObject.searchString = ; this.searchObject.widgetId = widgetId;
	}

	logWidget(widget: any) {
		Global.User.DebugMode && console.log(this.componentName + ": Clicked Widget data = %O", widget);
		//this.widgetHeightChanged(widget);
		this.signalRCore.LogActivity("User clicked on " + widget.WidgetTypeName + "...");
	}

	initializeTour(widget: any): void {
		this.tourService.initialize([
			{
				anchorId: widget.WidgetId + "-1",
				content: "Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget",
				title: "Settings"
			},
			{
				anchorId: widget.WidgetId + "-2",
				content: "Navigate to different tabs & Widget Settings",
				title: "Navigation"
			},
			{
				anchorId: widget.WidgetId + "-3",
				content: "Click on various tiles for more information",
				title: "Summary Info"
			},
			{
				anchorId: widget.WidgetId + "-4",
				content: "Here you can click to change the height for this widget",
				title: "Change Height"
			}
		]);

		this.tourService.start();
	}

	getHeight() {
		Global.User.DebugMode && console.log(this.componentName + ": this.elementRef.nativeElement.innerHeight = " + this.elementRef.nativeElement.innerHeight + ", this.elementRef.nativeElement.innerWidth = " + this.elementRef.nativeElement.innerWidth);

		return this.elementRef.nativeElement.innerHeight / this.height;
	}

	itemChange(item: any) {
		Global.User.DebugMode && console.log(this.componentName + ": item changed = %O", item);
		let itemCopy = item;
		if (!itemCopy.Id) {
			//if itemId doesn't exist, should be new widget, so let itemInit handle it.
		} else {
			itemCopy.Width = itemCopy.cols;
			itemCopy.Height = itemCopy.rows;
			itemCopy.Col = itemCopy.x;
			itemCopy.Row = itemCopy.y;
			this.dashboardService.addOrUpdateDashboardWidgetItem(itemCopy);
		}
	}

	itemResize(item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
		Global.User.DebugMode && console.log(this.componentName + ": itemResized %O", item);
		Global.User.DebugMode && console.log(this.componentName + ": itemComponent = %O", itemComponent);

		this.widgetResizedEvent.emit(itemComponent);
	}

	itemInit(item: GridsterItem) {
		// console.info("itemInit", item, itemComponent);
		let itemCopy = item;
		Global.User.DebugMode && console.log(this.componentName + ": gridsterItem = %O", itemCopy);

		if (!itemCopy.Id) {
			//if itemId doesn't exist, should be new widget, so let itemInit handle it.
			itemCopy.Id = this.dashboardId;
			itemCopy.WidgetId = "";
			itemCopy.Width = itemCopy.cols;
			itemCopy.Height = itemCopy.rows;
			itemCopy.Col = itemCopy.x;
			itemCopy.Row = itemCopy.y;
			Global.User.DebugMode && console.log(this.componentName + ": itemCopy = %O", itemCopy);
			this.dashboardService.addOrUpdateDashboardWidgetItem(itemCopy);
			setTimeout(() => {
				if (this.canUserSeeWidget(this.dashboardWidgets[this.dashboardWidgets.length - 1])) this.loadEditedOrCreatedWidget(this.dashboardWidgets.length - 1);
			}, 100);
			Global.User.DebugMode && console.log(this.componentName + ": this.dashboardWidgets = %O", this.dashboardWidgets);
		}
	}

	itemRemoved(item: GridsterItem, itemComponent: GridsterItemComponentInterface) {
		console.info("itemRemoved", item, itemComponent);
		//logic for removing
	}

	static itemValidate(item: GridsterItem) {
		return item.cols > 0 && item.rows > 0;
	}

	dashboardWidgetToDelete(widget) {
		// Global.User.DebugMode && console.log(this.componentName + ": widget = %O", widget); Global.User.DebugMode && console.log(this.componentName + ": this.dashboardWidgets = %O", this.dashboardWidgets);
		_.remove(this.dashboardWidgets, function (n: any) {
			return n.WidgetId === widget.WidgetId && n.Id === widget.Id;
		});
		this.dashboardService.deleteDashboardWidgetItem(widget);
	}

	editWidgetName(widget) {
		var editWidgetComponentModal = this.dialog.open(EditWidgetComponent, {
			width: Global.isMobile ? "95%" : "70%",
			height: Global.isMobile ? "95%" : "30%",
			data: { title: "Re-Name Widget", data: widget },
			autoFocus: false
		});
		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(editWidgetComponentModal);

		editWidgetComponentModal.afterClosed().subscribe((result) => {
			if (this.debugMode) {
				console.log("The modal was closed");

				//if not submitted, then result is undefined
				console.log(result);
			}

			if (result !== undefined) {
				//make call to update widget name in db, then after that, assign the value to teh widget object
				if (result.data !== undefined) {
					widget.WidgetName = result.data.widgetName;
				} else {
					widget.WidgetName = result;
				}
				let statement = "API.Widget_UpdateRecordByIdAndFieldName " + widget.WidgetId + ", '" + "Name" + "', '''" + widget.WidgetName + "'''";
				if (this.debugMode) {
					console.log(statement);
				}
				this.dataService.SQLActionAsPromise(statement).then((data: any) => {
					this.utilityService.showToastMessageShared({
						type: "success",
						message: "Widget Name Changed!"
					});
				});
			}
		});
	}

	siteChange(widget: any) {
		var site = widget.WidgetSiteId ? this.dataService.cache.sitesObject[widget.WidgetSiteId] : null;
		widget.WidgetSiteName = site?.Name;
		var system = widget.WidgetGateSystemId ? this.dataService.cache.systemsObject[widget.WidgetGateSystemId] : widget.SelectedSystemId ? this.dataService.cache.systemsObject[widget.SelectedSystemId] : null;
		widget.WidgetGateSystemName = system?.Name;
		var asset = widget.WidgetAssetId ? this.dataService.cache.assetsObject[widget.WidgetAssetId] : null;
		widget.WidgetAssetName = asset?.Name;
	}

	systemChange(widget: any) {
		var system = widget.WidgetGateSystemId ? this.dataService.cache.systemsObject[widget.WidgetGateSystemId] : widget.SelectedSystemId ? this.dataService.cache.systemsObject[widget.SelectedSystemId] : null;
		widget.WidgetGateSystemName = system?.Name;
		var asset = widget.WidgetAssetId ? this.dataService.cache.assetsObject[widget.WidgetAssetId] : null;
		widget.WidgetAssetName = asset?.Name;
	}

	assetChange(widget: any) {
		var asset = widget.WidgetAssetId ? this.dataService.cache.assetsObject[widget.WidgetAssetId] : null;
		widget.WidgetAssetName = asset?.Name;
	}

	editTimeScopeSettings(widget) {
		var widgetSettingsScopeEditModal = this.dialog.open(WidgetTimeScopeSelectionPopupComponent, {
			width: Global.isMobile ? "95%" : "50%",
			height: Global.isMobile ? "95%" : "80%",
			maxHeight: "100%",
			maxWidth: "100%",
			data: widget,
			position: { right: "0" },
			hasBackdrop: false,
			disableClose: true
		});
		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(widgetSettingsScopeEditModal);

		widgetSettingsScopeEditModal.afterClosed().subscribe((result) => {});
	}

	editIntervalSettings(widget) {
		var widgetSettingsIntervalEditModal = this.dialog.open(WidgetIntervalSelectionPopupComponent, {
			width: Global.isMobile ? "95%" : "50%",
			height: Global.isMobile ? "95%" : "80%",
			maxHeight: "100%",
			maxWidth: "100%",
			data: widget,
			position: { right: "0" },
			hasBackdrop: false,
			disableClose: true
		});
		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(widgetSettingsIntervalEditModal);

		widgetSettingsIntervalEditModal.afterClosed().subscribe((result) => {});
	}

	editWidgetSettings(widget) {
		var widgetSettingsEditModal = this.dialog.open(WidgetSettingsPopupComponent, {
			width: Global.isMobile ? "95%" : "50%",
			height: Global.isMobile ? "95%" : "80%",
			maxHeight: "100%",
			maxWidth: "100%",
			data: {
				widget: widget,
				widgetType: widget.DashboardTypeId === 1 ? "Standard" : "Tactical"
			},
			position: { right: "0" },
			hasBackdrop: false,
			disableClose: true,
			autoFocus: false
		});
		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(widgetSettingsEditModal);

		widgetSettingsEditModal.afterClosed().subscribe((result) => {
			if (this.debugMode) {
				console.log("The modal was closed");
				//if not submitted, then result is undefined
				console.log(result);
			}
		});
	}

	onDelete(widget) {
		console.log("Deleting widget = %O", widget);

		this.swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html: "You want to delete the " + widget.WidgetName + " widget? This cannot be reversed once it's deleted.",
				showCancelButton: true,
				confirmButtonText: "Delete Widget",
				cancelButtonText: "Cancel",
				reverseButtons: false
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					this.dashboardWidgetToDelete(widget);
					this.utilityService.showToastMessageShared({
						type: "success",
						message: "The " + widget.WidgetName + " widget has been DELETED."
					});

					// _.remove(this.dashboards, function(n) {
					//   return n.Id === dashboard.Id;
					// });
				} else if (
					/* Read more about handling dismissals below */
					result.dismiss === Swal.DismissReason.cancel
				) {
					this.utilityService.showToastMessageShared({
						type: "info",
						message: "The " + widget.WidgetName + "  widget has NOT been deleted."
					});
				}
			});
	}

	static gridInit(grid: GridsterComponentInterface) {
		console.info("gridInit", grid);
	}

	static gridDestroy(grid: GridsterComponentInterface) {
		console.info("gridDestroy", grid);
	}

	gridSizeChanged(grid: GridsterComponentInterface) {
		// console.info('gridSizeChanged', grid);
	}

	toggleAllWidgetResizingEnabled() {}

	openAddWidgetToDashboardModal() {
		let dashboard = Global.User.currentUser.Dashboards.find((obj) => obj.Id == this.dashboardId);

		this.DialogRef = this.dialog.open(AddWidgetToDashboardComponent, {
			width: "95%",
			height: "80%"
		});
		this.dashboardWidgetClickedSubscription = this.DialogRef.componentInstance.widgetClicked.subscribe((result) => {
			Global.User.DebugMode && console.log(this.componentName + ": widgetClicked = %O", result);
			let widget = _.clone(result);

			///Here we will add the ability to push the new widget into the grid. we will need to write functionality to update the db on the events that detect grid changes so that the new widget is added to db when those events fire
			this.dashboardWidgets.push(
				_.assign(widget, {
					cols: widget.InitialWidth,
					rows: widget.InitialHeight
				})
			);
			this.utilityService.showToastMessageShared({
				type: "success",
				message: "Widget Successfully Added!",
				title: "Dashboards"
			});
			// this.toastr.success('Widget Successfully Added!');
		});

		this.addWidgetToDashboardAfterClosedSubscription = this.DialogRef.afterClosed().subscribe(() => {
			this.dashboardWidgetClickedSubscription && this.dashboardWidgetClickedSubscription.unsubscribe();
			this.addWidgetToDashboardAfterClosedSubscription && this.addWidgetToDashboardAfterClosedSubscription.unsubscribe();
			// Global.User.DebugMode && console.log(this.componentName + ": The modal was closed");
		});
	}

	onDateSelectionChange(event, picker) {
		console.log(event);
		console.log(picker);
		let startMS = picker === "start" ? event.getTime() : this.currentDashboard.CustomStartDateMS;
		let endMS = picker === "end" ? event.getTime() : this.currentDashboard.CustomEndDateMS;

		if (startMS < endMS) {
			this.isTimeSelectionValid = true;
			if (picker == "start") {
				this.currentDashboard.CustomStartDateMS = event.getTime();
			} else if (picker == "end") {
				this.currentDashboard.CustomEndDateMS = event.getTime();
			}
			var sqlStatement = this.dashboardService.addOrUpdateDashboardRecord(this.dashboardService.currentDashboard);
			this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				console.log("dashboard", data);
				let returnedDashboard = data.find((dashboardInstance) => {
					return dashboardInstance.Id === this.dashboardService.currentDashboard.Id;
				});
				if (picker == "start") {
					this.currentDashboard.CustomStartDateMS = returnedDashboard.CustomStartDateMS;
					this.currentDashboard.CustomStartDate = returnedDashboard.CustomStartDate;
					this.dashboardService.currentDashboard.CustomStartDateMS = returnedDashboard.CustomStartDateMS;
					this.dashboardService.currentDashboard.CustomStartDate = returnedDashboard.CustomStartDate;
				} else if (picker == "end") {
					this.currentDashboard.CustomEndDateMS = returnedDashboard.CustomEndDateMS;
					this.currentDashboard.CustomEndDate = returnedDashboard.CustomEndDate;
					this.dashboardService.currentDashboard.CustomEndDateMS = returnedDashboard.CustomEndDateMS;
					this.dashboardService.currentDashboard.CustomEndDate = returnedDashboard.CustomEndDate;
				}

				this.utilityService.showToastMessageShared({
					type: "success",
					message: "Dashboard time scope changed to custom",
					title: "Dashboards"
				});
				this.dashboardTimeScopeChanged.emit(this.currentDashboard.widgets);
			});
		} else {
			this.isTimeSelectionValid = false;
			this.utilityService.showToastMessageShared({
				type: "error",
				message: "Dashboard time scope not changed. Please enter a valid date range.",
				title: "Dashboards"
			});
		}
	}

	changeDashboardCustomTimeScope() {
		this.editDashboardCustomTimeScopeModal = this.dialog.open(DashboardCustomTimescopePopupConfirmationSettingsComponent, {
			width: "70%",
			height: "70%",
			data: {
				dashboardObject: this.dashboardService.currentDashboard
			}
		});

		this.editDashboadTimeScopeModalAfterClosedSubscription = this.editDashboardCustomTimeScopeModal.afterClosed().subscribe((result) => {
			if (result !== undefined) {
				// pick up rest of logic
				let currentDashboardObjectCopy = _.cloneDeep(this.dashboardService.currentDashboard);
				currentDashboardObjectCopy.CustomStartDateMS = result.startDateMS;
				currentDashboardObjectCopy.CustomEndDateMS = result.endDateMS;

				var sqlStatement = this.dashboardService.addOrUpdateDashboardRecord(currentDashboardObjectCopy);
				this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
					let returnedDashboard = data.find((dashboardInstance) => {
						return dashboardInstance.Id === this.dashboardService.currentDashboard.Id;
					});

					this.currentDashboard.CustomStartDateMS = returnedDashboard.CustomStartDateMS;
					this.currentDashboard.CustomStartDate = returnedDashboard.CustomStartDate;
					this.dashboardService.currentDashboard.CustomStartDateMS = returnedDashboard.CustomStartDateMS;
					this.dashboardService.currentDashboard.CustomStartDate = returnedDashboard.CustomStartDate;

					this.currentDashboard.CustomEndDateMS = returnedDashboard.CustomEndDateMS;
					this.currentDashboard.CustomEndDate = returnedDashboard.CustomEndDate;
					this.dashboardService.currentDashboard.CustomEndDateMS = returnedDashboard.CustomEndDateMS;
					this.dashboardService.currentDashboard.CustomEndDate = returnedDashboard.CustomEndDate;

					this.dashboardTimeScopeChanged.emit(this.currentDashboard.widgets);
				});
			} else {
				// this.currentTimeScopeId = oldTimeScope;
			}
			this.editDashboadTimeScopeModalAfterClosedSubscription && this.editDashboadTimeScopeModalAfterClosedSubscription.unsubscribe();
		});
	}

	changeDashboardGridsterType(){
		let oldGridsterType = this.dashboardObject.GridsterType;
		if(this.currentGridsterTypeId === this.dashboardObject.GridsterTypeId){
			return;
		}
		let currentDashboardObjectCopy = _.cloneDeep(this.dashboardService.currentDashboard);
		currentDashboardObjectCopy.GridsterTypeId = this.currentGridsterTypeId;
		var sqlStatement = this.dashboardService.addOrUpdateDashboardRecord(currentDashboardObjectCopy);
		this.dataService.SQLActionAsPromise(sqlStatement).then((data) => {
			console.log(data);
			this.dashboardObject.GridsterTypeId = this.currentGridsterTypeId;

			this.utilityService.showToastMessageShared({
				type: "success",
				message: "Dashboard grid type changed to " + this.DashboardGridsterTypes.find(gt => gt.Id === this.currentGridsterTypeId).Name,
				title: "Grid Type Changed"
			});
			this.evaluateGridsterType();

		});

	}

	evaluateGridsterType(){
		if(this.options){
			if(this.currentGridsterTypeId === 4){
				this.gridsterType = GridType.Fixed;
			} else if(this.currentGridsterTypeId === 2){
				this.gridsterType = GridType.ScrollVertical;
			}
			this.options.gridType = this.gridsterType;

			this.options.api?.optionsChanged();
		}

	}

	changeDashboardTimeScope() {
		let oldTimeScope = this.dashboardObject.TimeScopeId;
		let timeScopeObject;
		let dashboardWidgetsThatListenToTimescopeChanges;
		let timeScopes;
		if (this.currentTimeScopeId == this.dashboardObject.TimeScopeId) {
			return;
		}
		//Tactical Dashboard
		timeScopes = this.DashboardTimeScopes;
		timeScopeObject = this.DashboardTimeScopes.find((ts) => ts.Id === this.currentTimeScopeId);
		dashboardWidgetsThatListenToTimescopeChanges = this.dashboardService.evaluateAngularDirectiveNameForTimeScopeOption("list", this.dashboardWidgets);

		// let dashboardWidgetsThatListenToTimescopeChanges = this.dashboardWidgets.filter(dw => { return dw.AngularDirectiveName === 'tagGraph' || dw.AngularDirectiveName === 'gsEquipmentHoursOfUsage' || dw.AngularDirectiveName === 'gsEquipmentUsage' || dw.AngularDirectiveName === 'gsTopFiveAlarmTypesByEquipment' || dw.AngularDirectiveName === 'gseTopFiveAlarmTypes' || dw.AngularDirectiveName === 'gseTopFiveEquipmentAlarmTypes' || dw.AngularDirectiveName === 'gseTopFiveEquipmentAlarmTypes';
		// })
		if (dashboardWidgetsThatListenToTimescopeChanges.length === 0) {
			let currentDashboardObjectCopy = _.cloneDeep(this.dashboardService.currentDashboard);
			currentDashboardObjectCopy.TimeScopeId = timeScopeObject.Id;
			var sqlStatement = this.dashboardService.addOrUpdateDashboardRecord(currentDashboardObjectCopy);
			this.dataService.SQLActionAsPromise(sqlStatement).then(() => {
				this.dashboardObject.TimeScopeId = this.currentTimeScopeId;
				let timeScopeObject = timeScopes.find((ts) => ts.Id === this.currentTimeScopeId);

				this.utilityService.showToastMessageShared({
					type: "success",
					message: "Dashboard time scope changed to " + timeScopeObject.Description,
					title: "Dashboards"
				});
				// this.toastr.success(
				// 	'Dashboard time scope changed to ' +
				// 		timeScopeObject.Description
				// );
				return;
			});
		} else {
			this.editDashboardTimeScopeModal = this.dialog.open(DashboardTimescopePopupConfirmationSettingsComponent, {
				width: "70%",
				height: "30%",
				data: {
					dashboardWidgets: dashboardWidgetsThatListenToTimescopeChanges,
					selectedTimescopeObject: timeScopeObject,
					timeScopes: timeScopes
				}
			});

			this.editDashboadTimeScopeModalAfterClosedSubscription = this.editDashboardTimeScopeModal.afterClosed().subscribe((result) => {
				if (result !== undefined) {
					// pick up rest of logic

					let currentDashboardObjectCopy = _.cloneDeep(this.dashboardService.currentDashboard);
					currentDashboardObjectCopy.TimeScopeId = timeScopeObject.Id;
					var sqlStatement = this.dashboardService.addOrUpdateDashboardRecord(currentDashboardObjectCopy);
					this.dataService.SQLActionAsPromise(sqlStatement).then(() => {
						this.dashboardObject.TimeScopeId = this.currentTimeScopeId;
						let timeScopeObject = timeScopes.find((ts) => ts.Id === this.currentTimeScopeId);
						this.utilityService.showToastMessageShared({
							type: "success",
							message: "Dashboard time scope changed to " + timeScopeObject.Description,
							title: "Dashboards"
						});
						// this.toastr.success(
						// 	'Dashboard time scope changed to ' +
						// 		timeScopeObject.Description
						// );
						let changedWidgets = result.dashboardWidgets.filter((dw) => {
							console.log(dw);
							return dw.isTimeScopeSelected === true && dw.TimeScopeId !== timeScopeObject.Id;
						});
						if (changedWidgets.length > 0) {
							changedWidgets.forEach((cw, index) => {
								let statement = "API.DashboardUpdateWidgetRecordSettings " + "@ParentDashboardId = " + cw.Id + ", @Id = " + cw.WidgetId;
								if (_.isNil(cw.SiteList)) {
									statement = statement + ", @widgetSiteList = " + cw.SiteList;
								} else if (!_.isNil(cw.SiteList)) {
									if (typeof cw.SiteList === "string") {
										statement = statement + ", @widgetSiteList = '" + cw.SiteList + "'";
									} else {
										cw.SiteListAsString = cw.SiteList.join();
										statement = statement + ", @widgetSiteList = '" + cw.SiteListAsString + "'";
									}
								}
								if (!_.isNil(cw.WidgetSiteId)) {
									statement = statement + ", @widgetSiteId = " + cw.WidgetSiteId;
								}
								if (!_.isNil(cw.WidgetTerminalSystemId)) {
									statement = statement + ", @widgetTerminalSystemId = " + cw.WidgetTerminalSystemId;
								}

								if (!_.isNil(cw.WidgetGateSystemId)) {
									statement = statement + ", @widgetGateSystemId = " + cw.WidgetGateSystemId;
								}
								if (!_.isNil(cw.WidgetAssetId)) {
									statement = statement + ", @widgetAssetSystemId = " + cw.WidgetAssetId;
								}

								if (!_.isNil(cw.TimeZoneId) && Global.User.isLoggedInAsDifferentUser == false) {
									statement = statement + ", @widgetTimeZoneId = " + cw.TimeZoneId;
								}

								statement = statement + ", @widgetTimeScopeId = " + this.currentTimeScopeId + ", @selectedTabIndex = " + cw.SelectedTabIndex;

								this.dataService.SQLActionAsPromise(statement).then(() => {
									let timeScopeObjectChanged = timeScopes.find((ts) => ts.Id === this.currentTimeScopeId);

									cw.TimeScopeId = this.currentTimeScopeId;
									cw.DashboardTimeScopeDays = timeScopeObjectChanged.Days;
									cw.DashboardTimeScopeDescription = timeScopeObjectChanged.Description;
									cw.DashboardTimeScopeId = timeScopeObjectChanged.Id;
									// cw.DashboardTimeScopeDays =
									let widgetIndexChanged = this.dashboardWidgets.findIndex((widget) => {
										return widget.WidgetId === cw.WidgetId;
									});
									this.dashboardWidgets[widgetIndexChanged].TimeScopeId = this.currentTimeScopeId;
									this.dashboardWidgets[widgetIndexChanged].DashboardTimeScopeDays = timeScopeObjectChanged.Days;
									this.dashboardWidgets[widgetIndexChanged].DashboardTimeScopeDescription = timeScopeObjectChanged.Description;
									this.dashboardWidgets[widgetIndexChanged].DashboardTimeScopeId = timeScopeObjectChanged.Id;
									if (index === changedWidgets.length - 1) {
										this.dashboardWidgets.forEach((dashWid) => {
											if (_.isNil(dashWid.TimeScopeId)) {
												changedWidgets.push(dashWid);
											}
										});
										this.dashboardTimeScopeChanged.emit(changedWidgets);
									}
								});
							});
						}
					});
				} else {
					this.currentTimeScopeId = oldTimeScope;
				}
				this.editDashboadTimeScopeModalAfterClosedSubscription && this.editDashboadTimeScopeModalAfterClosedSubscription.unsubscribe();
			});
		}
	}

	changeDashboardTimeZone() {
		let oldTimeZoneId = this.dashboardObject.TimeZoneId;

		if (this.currentTimeZoneId == this.dashboardObject.TimeZoneId) {
			return;
		}
		let timeZoneObject = this.DashboardTimeZones.find((tz) => tz.Id === this.currentTimeZoneId);

		console.log(this.dashboardWidgets);
		let dashboardWidgetsThatListenToTimezoneChanges = this.dashboardService.evaluateAngularDirectiveNameForTimeZoneOption("list", this.dashboardWidgets);

		if (dashboardWidgetsThatListenToTimezoneChanges.length === 0) {
			let currentDashboardObjectCopy = _.cloneDeep(this.dashboardService.currentDashboard);
			currentDashboardObjectCopy.TimeZoneId = timeZoneObject.Id;
			var sqlStatement = this.dashboardService.addOrUpdateDashboardRecord(currentDashboardObjectCopy);
			console.log(sqlStatement);

			this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				console.log(data);
				this.dashboardObject.TimeZoneId = this.currentTimeZoneId;
				this.currentDashboardTimeZoneName = this.DashboardTimeZones.find((tz) => tz.Id == this.currentTimeZoneId).Description;
				this.utilityService.showToastMessageShared({
					type: "success",
					message: "Dashboard time zone changed to " + this.currentDashboardTimeZoneName,
					title: "Dashboards"
				});
				// this.toastr.success(
				// 	'Dashboard time zone changed to ' +
				// 		this.currentDashboardTimeZoneName
				// );
				return;
			});
		} else {
			this.editDashboardTimeZoneModal = this.dialog.open(DashboardTimezonePopupConfirmationSettingsComponent, {
				width: "70%",
				height: "30%",
				data: {
					dashboardWidgets: dashboardWidgetsThatListenToTimezoneChanges,
					selectedTimezoneObject: timeZoneObject,
					timeZones: this.DashboardTimeZones
				}
			});

			this.editDashboardTimeZoneModalAfterClosedSubscription = this.editDashboardTimeZoneModal.afterClosed().subscribe((result) => {
				console.log(result);
				console.log(this);
				if (result !== undefined) {
					let currentDashboardObjectCopy = _.cloneDeep(this.dashboardService.currentDashboard);
					currentDashboardObjectCopy.TimeZoneId = timeZoneObject.Id;
					var sqlStatement = this.dashboardService.addOrUpdateDashboardRecord(currentDashboardObjectCopy);
					console.log(sqlStatement);

					this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
						console.log(data);
						this.dashboardObject.TimeZoneId = this.currentTimeZoneId;
						this.currentDashboardTimeZoneName = this.DashboardTimeZones.find((tz) => tz.Id == this.currentTimeZoneId).Description;
						this.utilityService.showToastMessageShared({
							type: "success",
							message: "Dashboard time zone changed to " + this.currentDashboardTimeZoneName,
							title: "Dashboards"
						});
						// this.toastr.success(
						// 	'Dashboard time zone changed to ' +
						// 		this.currentDashboardTimeZoneName
						// );
						let changedWidgets = result.dashboardWidgets.filter((dw) => {
							return dw.isTimeZoneSelected === true && dw.TimeZoneId !== timeZoneObject.Id;
						});
						console.log(changedWidgets);
						if (changedWidgets.length > 0) {
							changedWidgets.forEach((cw, index) => {
								let statement = "API.DashboardUpdateWidgetRecordSettings " + "@ParentDashboardId = " + cw.Id + ", @Id = " + cw.WidgetId;
								if (_.isNil(cw.SiteList)) {
									statement = statement + ", @widgetSiteList = " + cw.SiteList;
								} else if (!_.isNil(cw.SiteList)) {
									cw.SiteListAsString = cw.SiteList.join();
									statement = statement + ", @widgetSiteList = '" + cw.SiteListAsString + "'";
								}
								if (!_.isNil(cw.WidgetSiteId)) {
									statement = statement + ", @widgetSiteId = " + cw.WidgetSiteId;
								}
								if (!_.isNil(cw.WidgetTerminalSystemId)) {
									statement = statement + ", @widgetTerminalSystemId = " + cw.WidgetTerminalSystemId;
								}

								if (!_.isNil(cw.WidgetGateSystemId)) {
									statement = statement + ", @widgetGateSystemId = " + cw.WidgetGateSystemId;
								}
								if (!_.isNil(cw.WidgetAssetId)) {
									statement = statement + ", @widgetAssetSystemId = " + cw.WidgetAssetId;
								}

								if (!_.isNil(cw.TimeScopeId)) {
									statement = statement + ", @widgetTimeScopeId = " + cw.TimeScopeId;
								}

								statement = statement + ", @widgetTimeZoneId = " + this.currentTimeZoneId + ", @selectedTabIndex = " + cw.SelectedTabIndex;

								this.dataService.SQLActionAsPromise(statement).then(() => {
									cw.TimeZoneId = this.currentTimeZoneId;
									let widgetIndexChanged = this.dashboardWidgets.findIndex((widget) => {
										return widget.WidgetId === cw.WidgetId;
									});
									this.dashboardWidgets[widgetIndexChanged].TimeZoneId = this.currentTimeZoneId;
									if (index === changedWidgets.length - 1) {
										this.dashboardWidgets.forEach((dashWid) => {
											if (_.isNil(dashWid.TimeZoneId)) {
												changedWidgets.push(dashWid);
											}
										});
										this.dashboardTimeZoneChanged.emit(changedWidgets);
									}
								});
							});
						}
					});
				} else {
					this.currentTimeZoneId = oldTimeZoneId;
				}
				this.editDashboardTimeZoneModalAfterClosedSubscription && this.editDashboardTimeZoneModalAfterClosedSubscription.unsubscribe();
			});
		}
	}

	changeDashboardUpdateInterval() {
		let oldTimeZoneId = this.dashboardObject.UpdateIntervalId;

		if (this.updateIntervalId == this.dashboardObject.UpdateIntervalId) {
			return;
		}
		let updateIntervalObject = Global.User.currentUser.TacticalDashboardUpdateIntervals.find((tz) => tz.Id === this.updateIntervalId);

		console.log(this.dashboardWidgets);
		let dashboardWidgetsThatListenToUpdateIntervalChanges = this.dashboardService.evaluateAngularDirectiveNameForUpdateIntervalOption("list", this.dashboardWidgets);

		if (dashboardWidgetsThatListenToUpdateIntervalChanges.length === 0) {
			let currentDashboardObjectCopy = _.cloneDeep(this.dashboardService.currentDashboard);
			currentDashboardObjectCopy.UpdateIntervalId = updateIntervalObject.Id;
			var sqlStatement = this.dataService.addOrUpdateDashboardRecord(currentDashboardObjectCopy);
			console.log(sqlStatement);

			this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
				console.log(data);
				this.dashboardObject.UpdateIntervalId = this.updateIntervalId;
				this.currentUpdateIntervalName = Global.User.currentUser.TacticalDashboardUpdateIntervals.find((tz) => tz.Id == this.updateIntervalId).Description;
				this.utilityService.showToastMessageShared({
					type: "success",
					message: "Update Interval Changed To " + this.currentUpdateIntervalName,
					title: "Dashboards"
				});
				// this.toastr.success(
				// 	'Update Interval Changed To ' +
				// 		this.currentUpdateIntervalName
				// );
				return;
			});
		} else {
			this.editUpdateIntervalModal = this.dialog.open(TacticalDashboardIntervalPopupConfirmationSettingsComponent, {
				width: "70%",
				height: "30%",
				data: {
					dashboardWidgets: dashboardWidgetsThatListenToUpdateIntervalChanges,
					selectedUpdateIntervalObject: updateIntervalObject,
					updateIntervals: Global.User.currentUser.TacticalDashboardUpdateIntervals
				}
			});

			this.editDashboardUpdateIntervalModalSubscription = this.editUpdateIntervalModal.afterClosed().subscribe((result) => {
				console.log(result);
				console.log(this);
				if (result !== undefined) {
					let currentDashboardObjectCopy = _.cloneDeep(this.dashboardService.currentDashboard);
					currentDashboardObjectCopy.UpdateIntervalId = updateIntervalObject.Id;
					var sqlStatement = this.dataService.addOrUpdateDashboardRecord(currentDashboardObjectCopy);
					console.log(sqlStatement);

					this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
						console.log(data);
						this.dashboardObject.UpdateIntervalId = this.updateIntervalId;
						this.currentUpdateIntervalName = Global.User.currentUser.TacticalDashboardUpdateIntervals.find((tz) => tz.Id == this.updateIntervalId).Description;
						this.utilityService.showToastMessageShared({
							type: "success",
							message: "Update Interval Changed To " + this.currentUpdateIntervalName,
							title: "Dashboards"
						});
						// this.toastr.success(
						// 	'Update Interval Changed To ' +
						// 		this.currentUpdateIntervalName
						// );
						let changedWidgets = result.dashboardWidgets.filter((dw) => {
							return dw.isUpdateIntervalSelected === true && dw.UpdateIntervalId !== updateIntervalObject.Id;
						});
						Global.User.DebugMode && console.log(this.componentName + "changedWidgets = %O", changedWidgets);
						if (changedWidgets.length > 0) {
							changedWidgets.forEach((cw, index) => {
								let statement = "API.DashboardUpdateWidgetRecordSettings " + "@ParentDashboardId = " + cw.Id + ", @Id = " + cw.WidgetId;
								if (_.isNil(cw.SiteList)) {
									statement = statement + ", @widgetSiteList = " + cw.SiteList;
								} else if (!_.isNil(cw.SiteList)) {
									if (typeof cw.SiteList !== "string") {
										cw.SiteListAsString = cw.SiteList.join();
										statement = statement + ", @widgetSiteList = '" + cw.SiteListAsString + "'";
									} else if (typeof cw.SiteList === "string") {
										statement = statement + ", @widgetSiteList = '" + cw.SiteList + "'";
									}
								}
								if (!_.isNil(cw.WidgetSiteId)) {
									statement = statement + ", @widgetSiteId = " + cw.WidgetSiteId;
								}
								if (!_.isNil(cw.WidgetAssetTypeId)) {
									statement + ", @widgetSiteId = " + cw.WidgetSiteId + ", @widgetAssetTypeId = " + cw.WidgetAssetTypeId;
								}
								if (!_.isNil(cw.WidgetTerminalSystemId)) {
									statement = statement + ", @widgetTerminalSystemId = " + cw.WidgetTerminalSystemId;
								}

								if (!_.isNil(cw.WidgetGateSystemId)) {
									statement = statement + ", @widgetGateSystemId = " + cw.WidgetGateSystemId;
								}
								if (!_.isNil(cw.WidgetAssetId)) {
									statement = statement + ", @widgetAssetSystemId = " + cw.WidgetAssetId;
								}

								if (!_.isNil(cw.TimeScopeId)) {
									statement = statement + ", @widgetTimeScopeId = " + cw.TimeScopeId;
								}

								if (!_.isNil(cw.TimeZoneId) && Global.User.isLoggedInAsDifferentUser == false) {
									statement = statement + ", @widgetTimeZoneId = " + cw.TimeZoneId;
								}

								statement = statement + ", @widgetUpdateIntervalId = " + this.updateIntervalId + ", @selectedTabIndex = " + cw.SelectedTabIndex;

								this.dataService.SQLActionAsPromise(statement).then(() => {
									cw.UpdateIntervalId = this.updateIntervalId;
									let widgetIndexChanged = this.dashboardWidgets.findIndex((widget) => {
										return widget.WidgetId === cw.WidgetId;
									});
									this.dashboardWidgets[widgetIndexChanged].UpdateIntervalId = this.updateIntervalId;
									if (index === changedWidgets.length - 1) {
										this.dashboardWidgets.forEach((dashWid) => {
											if (_.isNil(dashWid.UpdateIntervalId)) {
												changedWidgets.push(dashWid);
											}
										});
										// this.dashboardTimeZoneChanged.emit(changedWidgets);
										this.dashboardUpdateIntervalChanged.emit(changedWidgets);
									}
								});
							});
						}
					});
				} else {
					this.currentTimeZoneId = oldTimeZoneId;
				}
				this.editDashboardUpdateIntervalModalSubscription && this.editDashboardUpdateIntervalModalSubscription.unsubscribe();
			});
		}
	}

	widgetDragEnabledChanged(widget) {
		this.options.api.optionsChanged();
		this.itemChange(widget);
		if ((widget.dragEnabled === true || widget.resizeEnabled === true) && this.dashboardLocked === true) {
			this.widgetMovementRecentlyChanged = true;
			this.dashboardLocked = false;
		} else if (widget.dragEnabled === false && widget.resizeEnabled === false && this.dashboardLocked === false) {
			this.widgetMovementRecentlyChanged = true;
			let dashboardLocked = true;
			this.dashboardWidgets.forEach((widget) => {
				if (widget.dragEnabled === true || widget.resizeEnabled === true) {
					dashboardLocked = false;
				}
			});
			if (dashboardLocked === true) {
				this.dashboardLocked = true;
			}
		}
	}

	widgetRememberSelectedTabIndexChanged(widget) {
		this.itemChange(widget);
	}

	invertResizeSetting(widgetObject) {
		if (widgetObject.resizeEnabled === true || widgetObject.resizeEnabled === undefined || widgetObject.resizeEnabled === null) {
			widgetObject.resizeEnabled = false;
		} else if (widgetObject.resizeEnabled === false) {
			widgetObject.resizeEnabled = true;
		}
		this.widgetResizeEnabledChanged(widgetObject);
	}

	invertDraggableSetting(widgetObject) {
		if (widgetObject.dragEnabled === true || widgetObject.dragEnabled === undefined || widgetObject.dragEnabled === null) {
			widgetObject.dragEnabled = false;
		} else if (widgetObject.dragEnabled === false) {
			widgetObject.dragEnabled = true;
		}
		this.widgetDragEnabledChanged(widgetObject);
	}

	widgetResizeEnabledChanged(widget) {
		this.options.api.optionsChanged();
		this.itemChange(widget);
		if ((widget.dragEnabled === true || widget.resizeEnabled === true) && this.dashboardLocked === true) {
			this.widgetMovementRecentlyChanged = true;
			this.dashboardLocked = false;
		} else if (widget.dragEnabled === false && widget.resizeEnabled === false && this.dashboardLocked === false) {
			this.widgetMovementRecentlyChanged = true;
			let dashboardLocked = true;
			this.dashboardWidgets.forEach((widget) => {
				if (widget.dragEnabled === true || widget.resizeEnabled === true) {
					dashboardLocked = false;
				}
			});
			if (dashboardLocked === true) {
				this.dashboardLocked = true;
			}
		}
	}

	changeWidgetHeight(changeRequested: string, widget) {
		if (changeRequested === "taller") {
			widget.rows = widget.rows + 2;
			widget.Height = widget.Height + 2;
			// make widget taller
		} else if (changeRequested === "shorter") {
			// if(widget.rows < 8) {
			// 	Global.User.DebugMode &&
			// console.log(
			// 	this.componentName + "widget minimum height reached=", widget.rows);
			// 	return;
			// }
			// make widget smaller
			widget.rows = widget.rows - 2;
			widget.Height = widget.Height - 2;
		}

		Global.User.DebugMode && console.log(this.componentName + "widget height =", widget.rows);

		this.widgetHeightChanged(widget);
	}

	AddGraphWidgetToDashboard() {
		Global.User.DebugMode && console.log(this.componentName + ": Adding graph widget to dashboard");
		Global.User.DebugMode && console.log(this.componentName + ": this.dataService.cache.widgetTypes = %O", this.dataService.cache.widgetTypes);

		const tagGraphWidgetType = this.dataService.cache.widgetTypes.filter((n) => {
			return n.AngularDirectiveName == "tagGraph";
		});

		let tagGraphWidgetObject = _.cloneDeep(tagGraphWidgetType[0]);
		tagGraphWidgetObject.WidgetTypeId = tagGraphWidgetObject.Id;
		tagGraphWidgetObject.cols = tagGraphWidgetObject.InitialWidth;
		tagGraphWidgetObject.rows = tagGraphWidgetObject.InitialHeight;
		delete tagGraphWidgetObject.Id;
		tagGraphWidgetObject.WidgetId = "";
		tagGraphWidgetObject.WidgetName = tagGraphWidgetObject.Name;
		tagGraphWidgetObject.dragEnabled = true;
		tagGraphWidgetObject.resizeEnabled = true;
		tagGraphWidgetObject.graphId = uuidv4();
		this.dashboardWidgets.push(tagGraphWidgetObject);
		Global.User.DebugMode && console.log(this.componentName + ": tagGraphWidgetObject = %O", tagGraphWidgetObject);
		this.utilityService.showToastMessageShared({
			type: "success",
			message: "Charting Widget Added!",
			title: "Dashboards"
		});
		// this.toastr.success('Charting Widget Added!');
		this.options.api.optionsChanged();
		Global.User.DebugMode && console.log(this.componentName + ": tagGraphWidgetType = %O", tagGraphWidgetType);
	}

	ClearChartSelections() {
		this.dataService.tempTagsToChart = [];
	}

	changedOptions() {
		if (this.options.api && this.options.api.optionsChanged) {
			this.options.api.optionsChanged();
		}
	}

	removeItem($event, item) {
		$event.preventDefault();
		$event.stopPropagation();
		this.gridsterDashboard.splice(this.gridsterDashboard.indexOf(item), 1);
	}

	addItem() {
		// this.dashboardWidgets.push({ cols: 5, rows: 5 });
	}

	widgetHeightChanged(widget) {
		this.options.api.optionsChanged();
		this.itemChange(widget);
	}



	openNotesPopup(widget){

		let entityData = {
			EntityId: widget.WidgetAssetId,
			EntityName: widget.WidgetAssetName,
			EntityLogTypeId: 1, //Asset
		}

		let filteredLogs = 	this.dataService.filterNotesForWidget(widget);
		var logsArray = filteredLogs?.select((visiblelog: any) => {
				var EntityLogTypeName = this.dataService.cache.entityLogTypesObject[visiblelog.EntityLogTypeId]?.Name;

				var assetName;

				if(EntityLogTypeName == "Asset") {

					if(this.dataService.cache.assetsObject[visiblelog.EntityId].Name == "PBB" ||
						this.dataService.cache.assetsObject[visiblelog.EntityId].Name == "GPU" ||
						this.dataService.cache.assetsObject[visiblelog.EntityId].Name == "PCA" ||
						this.dataService.cache.assetsObject[visiblelog.EntityId].Name == "AHU"
					) {
						assetName = this.dataService.cache.assetsObject[visiblelog.EntityId].Site.Name + " " +
										this.dataService.cache.assetsObject[visiblelog.EntityId].ParentSystem.Name + " " +
										this.dataService.cache.assetsObject[visiblelog.EntityId].Name;
					}
					else {
						assetName = this.dataService.cache.assetsObject[visiblelog.EntityId].Name;
					}
				}

				var output = {
					Id: visiblelog.Id,
					Description: visiblelog.Description,
					CreationDate: visiblelog.CreationDate,
					CreatorUserId: visiblelog.CreatorUserId,
					CreatorName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.CreatorUserId })?.FullName,
					LastModifiedDate: visiblelog.LastModifiedDate,
					LastModifiedName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.LastModifiedUserId })?.FullName,
					EntityLogTypeName: EntityLogTypeName,
					AssetName: assetName,
					CountOfFilesUploaded: visiblelog.CountOfFilesUploaded,
					CountOfLogComments: visiblelog.CountOfLogComments,
					EntityId: visiblelog.EntityId,
					EntityLogTypeId: visiblelog.EntityLogTypeId,
					IsPrivate: visiblelog.IsPrivate,
					Keywords: visiblelog.Keywords,
				};
				return output;
			}).toArray();


		const dlg = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '80%',
			height: Global.isMobile ? '90%' : '90%',
			data: {
				graphId: uuidv4(),
				groupable: false,
				groupColumns: [],
				popupTitle: widget.WidgetAssetId ? 'Logbook for Asset: ' + widget.WidgetAssetName : 'Logbook',
				WidgetName: 'Kendo-Grid',
				description: 'Notes list',
				tableStructure:  [
					{
						field: "Id",
						title: "Id",
						_width: 50,
					},
					{
						field: "CreatorName",
						title: "Creator",
						_width: 75,
					},
					{
						field: "AssetName",
						title: "Asset",
						_width: 75,
					},
					{
						field: "Description",
						title: "Description",
						_width: 300,
					},
					{
						field: "LastModifiedDate",
						title: "Last Modified Date",
						_width: 75
					},
					{
						field: "EditNote",
						title: "Actions",
						_width: 100,
					},
				],
				tableData: logsArray,
				entityData: entityData,
				widget: widget,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}
}
