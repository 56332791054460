import { ErrorString, InvalidOperationException } from "../../shared";
/**
 * Returns the last element of a sequence.
 * If predicate is specified, the last element of a sequence that satisfies a specified condition.
 * @param source An IParallelEnumerable<T> to return the last element of.
 * @param predicate A function to test each element for a condition. Optional.
 * @throws {InvalidOperationException} The source sequence is empty.
 * @returns The value at the last position in the source sequence
 * or the last element in the sequence that passes the test in the specified predicate function.
 */
export const last = (source, predicate) => {
  if (predicate) {
    return last2(source, predicate);
  } else {
    return last1(source);
  }
};
const last1 = async source => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const values = await dataFunc.generator();
        if (values.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        } else {
          return values[values.length - 1];
        }
      }
    case 1 /* ArrayOfPromises */:
      {
        const promises = dataFunc.generator();
        if (promises.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        } else {
          return await promises[promises.length - 1];
        }
      }
    case 2 /* PromiseOfPromises */:
      {
        const promises = await dataFunc.generator();
        if (promises.length === 0) {
          throw new InvalidOperationException(ErrorString.NoElements);
        } else {
          return await promises[promises.length - 1];
        }
      }
  }
};
const last2 = async (source, predicate) => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const values = await dataFunc.generator();
        // Promise Array - Predicate
        for (let i = values.length - 1; i >= 0; i--) {
          const value = values[i];
          if (predicate(value)) {
            return value;
          }
        }
        break;
      }
    case 1 /* ArrayOfPromises */:
      {
        const promises = dataFunc.generator();
        // Promise Array - Predicate
        for (let i = promises.length - 1; i >= 0; i--) {
          const value = await promises[i];
          if (predicate(value)) {
            return value;
          }
        }
        break;
      }
    case 2 /* PromiseOfPromises */:
      {
        const promises = await dataFunc.generator();
        // Promise Array - Predicate
        for (let i = promises.length - 1; i >= 0; i--) {
          const value = await promises[i];
          if (predicate(value)) {
            return value;
          }
        }
        break;
      }
  }
  throw new InvalidOperationException(ErrorString.NoMatch);
};