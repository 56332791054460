import { BasicEnumerable } from "../BasicEnumerable";
import { groupBy_0, groupBy_0_Simple } from "./groupByShared";
export const groupBy = (source, keySelector, comparer) => {
  let iterable;
  if (comparer) {
    iterable = groupBy_0(source, keySelector, comparer);
  } else {
    iterable = groupBy_0_Simple(source, keySelector);
  }
  return new BasicEnumerable(iterable);
};