import { StrictEqualityComparer } from "../../shared";
import { nextIteration } from "./_nextIteration";
/**
 * Determines whether a sequence contains a specified element by using the specified or default IEqualityComparer<T>.
 * @param source A sequence in which to locate a value.
 * @param value The value to locate in the sequence.
 * @param comparer An equality comparer to compare values. Optional.
 * @returns Whether or not source contains the specified value
 */
export const contains = async (source, value, comparer = StrictEqualityComparer) => {
  let values;
  if (comparer) {
    values = nextIteration(source, x => comparer(value, x));
  } else {
    values = nextIteration(source, x => x === value);
  }
  switch (values.type) {
    case 0 /* PromiseToArray */:
      {
        const data = await values.generator();
        return data.some(x => x);
      }
    case 1 /* ArrayOfPromises */:
      {
        const data = await Promise.all(values.generator());
        return data.some(x => x);
      }
    case 2 /* PromiseOfPromises */:
      {
        const data = await Promise.all(await values.generator());
        return data.some(x => x);
      }
  }
};