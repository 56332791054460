import { ErrorString, InvalidOperationException } from "../../shared";
/**
 * Invokes a transform function on each element of a sequence and returns the minimum value.
 * @param source A sequence of values to determine the minimum value of.
 * @param selector A transform function to apply to each element.
 * @throws {InvalidOperationException} source contains no elements.
 * @returns The minimum value in the sequence.
 */
export const minAsync = async (source, selector) => {
  let min = null;
  for (const item of source) {
    min = Math.min(min || Number.POSITIVE_INFINITY, await selector(item));
  }
  if (min === null) {
    throw new InvalidOperationException(ErrorString.NoElements);
  } else {
    return min;
  }
};