import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
/**
 * Filters a sequence of values based on a predicate.
 * Each element's index is used in the logic of the predicate function.
 * @param source An AsyncIterable<T> to filter.
 * @param predicate A function to test each source element for a condition;
 * the second parameter of the function represents the index of the source element.
 * @returns An IAsyncEnumerable<T> that contains elements from the input sequence that satisfy the condition.
 */
export const where = (source, predicate) => {
  if (predicate.length === 1) {
    return where1(source, predicate);
  } else {
    return where2(source, predicate);
  }
};
const where1 = (source, predicate) => {
  async function* iterator() {
    for await (const item of source) {
      if (predicate(item) === true) {
        yield item;
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const where2 = (source, predicate) => {
  async function* iterator() {
    let i = 0;
    for await (const item of source) {
      if (predicate(item, i++) === true) {
        yield item;
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};