import { fromAsync } from "../../async/static/fromAsync";
/**
 * Returns distinct elements from a sequence by using the specified equality comparer to compare values.
 * @param source The sequence to remove duplicate elements from.
 * @param comparer An IAsyncEqualityComparer<T> to compare values.
 * @returns An IAsyncEnumerable<T> that contains distinct elements from the source sequence.
 */
export const distinctAsync = (source, comparer) => {
  async function* iterator() {
    const distinctElements = [];
    outerLoop: for (const item of source) {
      for (const distinctElement of distinctElements) {
        const found = await comparer(distinctElement, item);
        if (found) {
          continue outerLoop;
        }
      }
      distinctElements.push(item);
      yield item;
    }
  }
  return fromAsync(iterator);
};