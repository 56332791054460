import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
import { nextIteration } from "./_nextIteration";
import { typeDataToArray } from "./_typeDataToArray";
/**
 * Applies a type filter to a source iteration
 * @param source Async Iteration to Filtery by Type
 * @param type Either value for typeof or a consturctor function
 * @returns Values that match the type string or are instance of type
 */
export const ofType = (source, type) => {
  const typeCheck = typeof type === "string" ? x => [typeof x === type, x] : x => [x instanceof type, x];
  const generator = async () => {
    const dataFunc = nextIteration(source, typeCheck);
    const values = await typeDataToArray(dataFunc);
    const filteredValues = [];
    for (const [pass, value] of values) {
      if (pass) {
        filteredValues.push(value);
      }
    }
    return filteredValues;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};