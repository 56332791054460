import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
/**
 * Bypasses a specified number of elements in a sequence and then returns the remaining elements.
 * @param source An IParallelEnumerable<T> to return elements from.
 * @param count The number of elements to skip before returning the remaining elements.
 * @returns
 * An IParallelEnumerable<T> that contains the elements that occur after the specified index in the input sequence.
 */
export const skip = (source, count) => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const generator = async () => (await dataFunc.generator()).slice(count);
        return new BasicParallelEnumerable({
          generator,
          type: 0 /* PromiseToArray */
        });
      }
    case 1 /* ArrayOfPromises */:
      {
        const generator = () => dataFunc.generator().slice(count);
        return new BasicParallelEnumerable({
          generator,
          type: 1 /* ArrayOfPromises */
        });
      }
    case 2 /* PromiseOfPromises */:
      {
        const generator = async () => {
          const dataInner = await dataFunc.generator();
          return dataInner.slice(count);
        };
        const dataFuncNew = {
          generator,
          type: 2 /* PromiseOfPromises */
        };
        return new BasicParallelEnumerable(dataFuncNew);
      }
  }
};