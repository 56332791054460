import { Grouping } from "../../sync/Grouping";
import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
export const groupByAsync = (source, keySelector, comparer) => {
  if (comparer) {
    return groupByAsync_0(source, keySelector, comparer);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return groupByAsync_0_Simple(source, keySelector);
  }
};
const groupByAsync_0_Simple = (source, keySelector) => {
  async function* iterator() {
    const keyMap = {}; // TODO
    for await (const value of source) {
      const key = await keySelector(value);
      const grouping = keyMap[key];
      if (grouping) {
        grouping.push(value);
      } else {
        keyMap[key] = new Grouping(key, value);
      }
    }
    // eslint-disable-next-line guard-for-in
    for (const value in keyMap) {
      yield keyMap[value];
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const groupByAsync_0 = (source, keySelector, comparer) => {
  async function* generate() {
    const keyMap = new Array();
    for await (const value of source) {
      const key = await keySelector(value);
      let found = false;
      for (let i = 0; i < keyMap.length; i++) {
        const group = keyMap[i];
        if ((await comparer(group.key, key)) === true) {
          group.push(value);
          found = true;
          break;
        }
      }
      if (found === false) {
        keyMap.push(new Grouping(key, value));
      }
    }
    for (const keyValue of keyMap) {
      yield keyValue;
    }
  }
  return new BasicAsyncEnumerable(generate);
};