import { StrictEqualityComparer } from "../../shared";
/**
 * Compares two parallel sequences to see if they are equal using a comparer function.
 * @param first First Sequence
 * @param second Second Sequence
 * @param comparer Comparer
 * @returns Whether or not the two iterations are equal
 */
export const sequenceEquals = async (
// eslint-disable-next-line no-shadow
first, second, comparer = StrictEqualityComparer) => {
  const firstArray = await first.toArray();
  const secondArray = await second.toArray();
  if (firstArray.length !== secondArray.length) {
    return false;
  }
  for (let i = 0; i < firstArray.length; i++) {
    const firstResult = firstArray[i];
    const secondResult = secondArray[i];
    if (comparer(firstResult, secondResult) === false) {
      return false;
    }
  }
  return true;
};