/**
 * Returns first element in sequence that satisfies predicate otherwise
 * returns the first element in the sequence. Returns null if no value found.
 * @param source An AsyncIterable<T> to return an element from.
 * @param predicate A function to test each element for a condition. Optional.
 * @returns The first element in the sequence
 * or the first element that passes the test in the specified predicate function.
 * Returns null if no value found.
 */
export const firstOrDefault = (source, predicate) => {
  if (predicate) {
    return firstOrDefault2(source, predicate);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return firstOrDefault1(source);
  }
};
const firstOrDefault1 = async source => {
  const first = await source[Symbol.asyncIterator]().next();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return first.value || null;
};
const firstOrDefault2 = async (source, predicate) => {
  for await (const value of source) {
    if (predicate(value) === true) {
      return value;
    }
  }
  return null;
};