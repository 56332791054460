import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
export const zip = (first, second, resultSelector) => {
  if (resultSelector) {
    return zip2(first, second, resultSelector);
  } else {
    return zip1(first, second);
  }
};
const zip1 = (source, second) => {
  async function* iterator() {
    const firstIterator = source[Symbol.asyncIterator]();
    const secondIterator = second[Symbol.asyncIterator]();
    while (true) {
      const result = await Promise.all([firstIterator.next(), secondIterator.next()]);
      const a = result[0];
      const b = result[1];
      if (a.done && b.done) {
        break;
      } else {
        yield [a.value, b.value];
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const zip2 = (source, second, resultSelector) => {
  async function* iterator() {
    const firstIterator = source[Symbol.asyncIterator]();
    const secondIterator = second[Symbol.asyncIterator]();
    while (true) {
      const result = await Promise.all([firstIterator.next(), secondIterator.next()]);
      const a = result[0];
      const b = result[1];
      if (a.done && b.done) {
        break;
      } else {
        yield resultSelector(a.value, b.value);
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};