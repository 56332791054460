import { OrderedParallelEnumerable } from "../OrderedParallelEnumerable";
/**
 * Sorts the elements of a sequence in ascending order by using a specified comparer.
 * @param source A sequence of values to order.
 * @param keySelector An async function to extract a key from an element.
 * @param comparer An IComparer<T> to compare keys.
 * @returns An IOrderedParallelEnumerable<TElement> whose elements are sorted according to a key.
 */
export const orderByAsync = (source, keySelector, comparer) => {
  return OrderedParallelEnumerable.generateAsync(source, keySelector, true, comparer);
};