import { typeDataToArray } from "./_typeDataToArray";
/**
 * Converts an Async Iterable to a key value pair object
 * @param source IParallelEnumerable to Convert to an Object
 * @param selector Key Selector
 * @returns KVP Object
 */
export const toObject = async (source, selector) => {
  const dataFunc = source.dataFunc;
  const values = await typeDataToArray(dataFunc);
  const map = {};
  for (const value of values) {
    map[selector(value)] = value;
  }
  return map;
};