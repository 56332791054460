import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
/**
 * Returns elements from a sequence as long as a specified condition is true.
 * The element's index is used in the logic of the predicate function.
 * @param source The sequence to return elements from.
 * @param predicate A async function to test each source element for a condition;
 * the second parameter of the function represents the index of the source element.
 * @returns An IAsyncEnumerable<T> that contains elements from the input sequence
 * that occur before the element at which the test no longer passes.
 */
export const takeWhileAsync = (source, predicate) => {
  if (predicate.length === 1) {
    return takeWhileAsync1(source, predicate);
  } else {
    return takeWhileAsync2(source, predicate);
  }
};
const takeWhileAsync1 = (source, predicate) => {
  async function* iterator() {
    for await (const item of source) {
      if (await predicate(item)) {
        yield item;
      } else {
        break;
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const takeWhileAsync2 = (source, predicate) => {
  async function* iterator() {
    let index = 0;
    for await (const item of source) {
      if (await predicate(item, index++)) {
        yield item;
      } else {
        break;
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};