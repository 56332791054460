import { Grouping } from "../../sync/Grouping";
import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
export const groupBy = (source, keySelector, comparer) => {
  if (comparer) {
    return groupBy_0(source, keySelector, comparer);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return groupBy_0_Simple(source, keySelector);
  }
};
const groupBy_0_Simple = (source, keySelector) => {
  const generator = async () => {
    const keyMap = {};
    for (const value of await source.toArray()) {
      const key = keySelector(value);
      const grouping = keyMap[key]; // TODO
      if (grouping) {
        grouping.push(value);
      } else {
        keyMap[key] = new Grouping(key, value);
      }
    }
    const results = new Array();
    /* eslint-disable guard-for-in */
    for (const value in keyMap) {
      results.push(keyMap[value]);
    }
    /* eslint-enable guard-for-in */
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};
const groupBy_0 = (source, keySelector, comparer) => {
  const generator = async () => {
    const keyMap = new Array();
    for (const value of await source.toArray()) {
      const key = keySelector(value);
      let found = false;
      for (let i = 0; i < keyMap.length; i++) {
        const group = keyMap[i];
        if (comparer(group.key, key)) {
          group.push(value);
          found = true;
          break;
        }
      }
      if (found === false) {
        keyMap.push(new Grouping(key, value));
      }
    }
    const results = new Array();
    for (const g of keyMap) {
      results.push(g);
    }
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};