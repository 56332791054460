import { nextIterationAsync } from "./_nextIterationAsync";
import { typeDataToArray } from "./_typeDataToArray";
/**
 * Converts an Async Iterable to a key value pair object
 * @param source IParallelEnumerable to Convert to an Object
 * @param selector Async Key Selector
 * @returns KVP Object
 */
export const toObjectAsync = async (source, selector) => {
  const dataFunc = nextIterationAsync(source, async value => {
    const key = await selector(value);
    return [key, value];
  });
  const keyValues = await typeDataToArray(dataFunc);
  const map = {};
  for (const [key, value] of keyValues) {
    map[key] = value;
  }
  return map;
};