import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { Global } from "../_constants/global.variables";
import { UtilityService } from "../services/utility.service";

@Injectable({
	providedIn: "root",
})
export class DashboardAccessGuard  {
	constructor(
		private router: Router,
		private utilityService: UtilityService
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		let selectedDashboardId = route.params.id;
		let ifAllowed = false;
		let foundDashboard = Global.User.currentUser.Dashboards.find(
			(obj) => obj.Id == selectedDashboardId
		);

		if (foundDashboard != undefined) {
			ifAllowed = true;
		} else {
			this.utilityService.showToastMessageShared({
				type: "info",
				message: "Route does not exist. Redirecting to dashboard list.",
				title: "Dashboards",
			});
			this.router.navigate(["/layout/dashboard-list"]);
			ifAllowed = false;
		}

		return ifAllowed;
	}
}
