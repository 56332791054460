<div class="card card-tasks" id="card-whole">
	<div class="card-header">
		<h4 class="card-title d-inline" style="padding-left: 15px">
			BreadCrumbs Events Template
		</h4>
		<div
			style="
				padding: 0px;
				float: right;
				margin-right: 20px;
				margin-left: 20px;
			"
			tooltip="Close"
			placement="bottom"
			(click)="closeDialogWindow()"
		>
			<i class="tim-icons icon-simple-remove"></i>
		</div>
	</div>
	<div>
		<form *ngIf="retrieveTemplates === false" [formGroup]="breadcrumbsTemplateForm" (submit)="submitBreadcrumbsTemplateForm(breadcrumbsTemplateForm)">
			<div
				class="card card-tasks center"
				*ngIf="noTemplateName === true"
			>
				<div class="row col-sm-12 col-md-12">
					<div class="col-sm-4 col-md-4 col-form-label">
						<label for="subjectId"
							>Enter a name for the template*</label
						>
					</div>
					<div class="col-sm-8 col-md-8">
						<div class="form-group">
							<input
								class="form-control"
								type="text"
								id="templateName"
								formControlName="templateName"
								(change)="
								checkForTemplateName($event)
								"
							/>
						</div>
					</div>
				</div>
				<div
					class="card-footer text-center"
				>
					<button class="btn btn-danger" type="submit" id="Create" *ngIf="templateName?.length > 0">
						Create New Template
					</button>
				</div>
			</div>
		</form>
		<div class="card-body" *ngIf="noTemplateName === false">
			<h5 class="card-title d-inline" style="padding-left: 15px">
				Template: {{templateName}}
			</h5>
			<div class="table-container">
				<table *ngIf="idsArray != undefined" class="table">
					<thead class="text-primary">
						<tr>
							<th>JBT StandardObservation Id</th>
							<th>Name</th>
						</tr>
					</thead>
					<tbody class="example-list" >
						<tr class="example-box" *ngFor="let i of idsArray">
							<td class="thirdWidth">
								{{ i.JBTStandardObservationId }}
							</td>
							<td class="thirdWidth">
								{{i.Name}}
							</td>
							<td class="thirdWidth" class="text-middle" style="white-space: nowrap !important;">
								<button class="btn action-btn-link-icon" title="Delete this Tag Series" type="button" (click)="onDelete(i)">
									<i class="tim-icons danger-color icon-trash-simple"></i>
								</button>
							</td>
						</tr>
						<tr class="example-box" *ngIf="showAddNewRecord">
							<td>
								<form [formGroup]="widgetTemplateForm" (submit)="submitForm(widgetTemplateForm)">
									<div class="row col-sm-12 col-md-12">
										<div class="row col-sm-12 col-md-12">
											<div class="col-sm-4 col-md-4 col-form-label">
												<label for="subjectId"
													>JBT Standard Observation Id</label
												>
											</div>
											  <div class="col-sm-8 col-md-8">
												<div class="form-group">
													<input style="background-color: #4C74B2;color:white" id="name" type="text" aria-label="Number" matInput  [formControlName]="JBTStandardObservationId" [id]="JBTStandardObservationId" [matAutocomplete]="auto"/>
														<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (change)="saveSelectedValue($event)">
															<mat-option *ngFor="let obs of filteredList$ | async" [value]="obs">
																{{ obs.SelectorName }}
															</mat-option>
														</mat-autocomplete>
												</div>
											</div>
											<div>
												<button class="btn btn-danger" title="Add this JBTStandardObservation" type="submit" id="Create">
													<i class="tim-icons danger-color icon-simple-add"></i>
												</button>
											</div>
										</div>
									</div>

								</form>
							</td>

						</tr>
					</tbody>
				</table>
				<button *ngIf="!showAddNewRecord" class="btn btn-fill btn-primary" type="submit" (click)="addConditionRecord()">
					<i style="font-size: 15px">Add JBTStandardObservation</i>
				</button>
			</div>
		</div>
		<form *ngIf="retrieveTemplates === true" [formGroup]="existingTemplateForm" (submit)="submitExistingTemplateForm(existingTemplateForm)">
			<div class="col-sm-4 col-md-4 col-form-label">
				<label for="subjectId"
					>Please select from existing Template</label
				>
			</div>

			<div class="col-sm-8 col-md-8">
				<div class="form-group">
					<select
						required
						class="form-control"
						id="selectedTemplateId"
						formControlName="selectedTemplateId"
						(change)="
							checkForTemplateSelected($event)
						"
					>
						<option
							class="form-control"
							*ngFor="
								let model of existingTemplatesForUser
							"
							[value]="model.Id"
						>
							{{ model.Name }}
						</option>
					</select>
				</div>
				<table *ngIf="JBTStandardObservationIdAvailableForSelectedTemplate === true" class="table">
					<thead class="text-primary">
						<tr>
							<th>JBT StandardObservation Id</th>
							<th>Name</th>
						</tr>
					</thead>
					<tbody class="example-list" >
						<tr class="example-box" *ngFor="let i of JBTStandardIdsFromTemplate">
							<td class="thirdWidth">
								{{ i.JBTStandardObservationId }}
							</td>
							<td class="thirdWidth">
								{{i.Name}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div
					class="card-footer text-center"
				>
					<button class="btn btn-danger" type="submit" id="Create" *ngIf="templateSelected === true">
						Use this Template
					</button>
				</div>
		</form>>

</div>
