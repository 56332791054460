import { NgModule } from "@angular/core";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TagInputModule } from "ngx-chips";
import { layoutRoutes } from "./layout.routes";
import { RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { ColorPickerModule } from 'ngx-color-picker';

import { UserModule } from '../user/user.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { RecipesComponent } from './advanced/recipes/recipes.component';
import { SystemTablesComponent } from './advanced/system-tables/system-tables.component';
import { DashboardListComponent } from './dashboard/dashboard-list/dashboard-list.component';
import { DashboardDemoComponent } from './dashboard/dashboard-demo/dashboard-demo.component';
import { GridsterModule } from 'angular-gridster2';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { AppModule } from "../app.module";
import { MaterialModule } from '../../app/_other-modules/material.module';


import { AdminReportsComponent } from './advanced/admin-reports/admin-reports.component';
import { CustomerReportsComponent } from './advanced/customer-reports/customer-reports.component';

import { HighchartsChartModule } from 'highcharts-angular';

import { LocateAllGSEComponent } from './advanced/maps/locate-all-gse/locate-all-gse.component';
import { UserSettingsComponent } from './navbar/user-settings/user-settings.component';
import { LinearMeterComponent } from './shared/components/linear-meter/linear-meter.component';
import { IconsModule } from '@progress/kendo-angular-icons';

import { IndicatorsModule } from '@progress/kendo-angular-indicators';
// import { SassHelperComponent } from '../_helpers/sass-helper/sass-helper.component';

import {
	GridModule,
	ExcelModule,
	PDFModule,
} from '@progress/kendo-angular-grid';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { PopupModule } from '@progress/kendo-angular-popup';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';

import { IssuesComponent } from './navbar/issues/issues.component';

import { StoreModule } from '@ngrx/store';
import { locationReducer } from './shared/components/location-reducer/location-reducer.component';

import { ChartModule } from '@progress/kendo-angular-charts';
import { StockChartModule } from '@progress/kendo-angular-charts';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { SparklineModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { ResizableDirective } from '../_directives/resizable.directive';
import { CustomSpinnerDirective } from '../_directives/custom-spinner.directive';

import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';

import { CustomProgressbarDirective } from '../_directives/custom-progressbar.directive';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { RdnDestinationComponent } from './advanced/system-tables/rdn-destination/rdn-destination.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SliderModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NinjaStatisticsComponent } from './shared/widgets/ninja-statistics/ninja-statistics.component';
import { PotdModule } from 'projects/shared-lib/src/lib/_other-template-components/potd/potd.module';
import { SignalrCoreTestComponent } from './shared/widgets/signalr-core-test/signalr-core-test.component';

import { LiveDataModule } from 'projects/shared-lib/src/lib/components/live-data/live-data.module';
import { DialogModalParentComponentModule } from 'projects/shared-lib/src/lib/components/dialog-modal-parent/dialog-modal-parent.component.module';
import { CanvasPopupSettingsModule } from 'projects/shared-lib/src/lib/components/canvas-popup-settings/canvas-popup-settings.module';
import { CanvasGsePopupSettingsModule } from 'projects/shared-lib/src/lib/components/canvas-gse-popup-settings/canvas-gse-popup-settings.module';
import { JbtStandardObservationIdTemplateModule } from 'projects/shared-lib/src/lib/components/jbt-standard-observation-id-template/jbt-standard-observation-id-template.module';
import { JbtStandardObservationIdTemplateTagsModule } from 'projects/shared-lib/src/lib/components/jbt-standard-observation-id-template-tags/jbt-standard-observation-id-template-tags.module';
import { CanvasWidgetTemplatePopupSettingsModule } from 'projects/shared-lib/src/lib/components/canvas-widget-template-popup-settings/canvas-widget-template-popup-settings.module';
import { WidgetSettingsModule } from 'projects/shared-lib/src/lib/components/widget-settings/widget-settings.module';
import { DashboardTimescopePopupConfirmationSettingsModule } from 'projects/shared-lib/src/lib/components/dashboard-timescope-popup-confirmation-settings/dashboard-timescope-popup-confirmation-settings.module';
import { DashboardTimezonePopupConfirmationSettingsModule } from 'projects/shared-lib/src/lib/components/dashboard-timezone-popup-confirmation-settings/dashboard-timezone-popup-confirmation-settings.module';
import { TacticalDashboardIntervalPopupConfirmationSettingsModule } from 'projects/shared-lib/src/lib/components/tactical-dashboard-interval-popup-confirmation-settings/tactical-dashboard-interval-popup-confirmation-settings.module';
import { AddWidgetToDashboardModule } from 'projects/shared-lib/src/lib/components/add-widget-to-dashboard/add-widget-to-dashboard.module';
import { RecursiveDataNavigatorModule } from 'projects/shared-lib/src/lib/components/recursive-data-navigator/recursive-data-navigator.module';
import { DynamicFormModule } from 'projects/shared-lib/src/lib/components/dynamic-form/dynamic-form.module';

import { DashboardEditModule } from 'projects/shared-lib/src/lib/components/dashboard-edit/dashboard-edit.module';
import { DashboardCopyModule } from 'projects/shared-lib/src/lib/components/dashboard-copy/dashboard-copy.module';
import { NinjaStatisticsContentModule } from 'projects/shared-lib/src/lib/components/ninja-statistics-content/ninja-statistics-content.module';
import { MulticheckFilterModule } from 'projects/shared-lib/src/lib/components/multicheck-filter/multicheck-filter.module';
import { LoadingSpinnerModule } from 'projects/shared-lib/src/lib/components/loading-spinner/loading-spinner.module';
import { FileUploadListModule } from 'projects/shared-lib/src/lib/components/file-upload-list/file-upload-list.module';
import { SignalRTestModule } from 'projects/shared-lib/src/lib/components/signalr-test/signalr-test.module';
import { LocateAllGseWidgetModule } from 'projects/shared-lib/src/lib/components/locate-all-gse-widget/locate-all-gse-widget.module';
import { TrainingCoursesModule } from 'projects/shared-lib/src/lib/components/training-courses/training-courses.module';
import { TrainingLessonsModule } from 'projects/shared-lib/src/lib/components/training-lessons/training-lessons.module';
import { AddTemplateToCanvasModule } from 'projects/shared-lib/src/lib/components/add-template-to-canvas/add-template-to-canvas.module';
import { NavigationModule } from 'projects/shared-lib/src/lib/components/navigation/navigation.module';
import { DirectivesModule } from 'projects/shared-lib/src/lib/_directives/directives.module';
import { SharedDashboardModule } from 'projects/shared-lib/src/lib/components/shared-dashboard/shared-dashboard.module';
import { VideoPlayerModule } from 'projects/shared-lib/src/lib/components/video-player/video-player.module';
import { CustomerIssuesListModule } from 'projects/shared-lib/src/lib/components/customer-issues-list/customer-issues-list.module';
import { CustomerIssuesItemModule } from 'projects/shared-lib/src/lib/components/customer-issues-item/customer-issues-item.module';
import { LogbookModule } from "projects/shared-lib/src/lib/components/logbook/logbook.module";
import { DashboardCustomTimescopePopupConfirmationSettingsModule } from 'projects/shared-lib/src/lib/components/dashboard-custom-timescope-popup-confirmation-settings/dashboard-custom-timescope-popup-confirmation-settings.module';
import { TacticalWidgetSummaryTilePopupModule } from 'projects/shared-lib/src/lib/components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.module';
import { BreadCrumbsEventsTemplatePopupModule } from 'projects/shared-lib/src/lib/components/breadcrumbs-events-template-popup/breadcrumbs-events-template-popup.module';
import { UserEngagementReportModule } from "projects/shared-lib/src/lib/components/user-engagement-report/user-engagement-report.module";
import { CanvasTabsListModule } from "../../../../shared-lib/src/lib/components/canvas-tabs-list/canvas-tabs-list.module";
@NgModule({ declarations: [
        LayoutComponent,
        DashboardComponent,
        SidebarComponent,
        NavbarComponent,
        FooterComponent,
        RecipesComponent,
        SystemTablesComponent,
        DashboardListComponent,
        DashboardDemoComponent,
        AdminReportsComponent,
        LinearMeterComponent,
        LocateAllGSEComponent,
        UserSettingsComponent,
        IssuesComponent,
        ResizableDirective,
        CustomSpinnerDirective,
        CustomProgressbarDirective,
        CustomerReportsComponent,
        RdnDestinationComponent,
        NinjaStatisticsComponent,
        SignalrCoreTestComponent,
    ],
    exports: [
        LayoutComponent,
        DashboardComponent,
        DashboardDemoComponent,
        SidebarComponent,
        NavbarComponent,
        FooterComponent,
        // FixedPluginComponent,
        // RtlNavbarComponent,
        // RtlSidebarComponent,
        // RtlLayoutComponent,
        UserSettingsComponent,
    ], imports: [
		CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        ProgressbarModule.forRoot(),
        TooltipModule.forRoot(),
        TimepickerModule.forRoot(),
        PopoverModule.forRoot(),
        CollapseModule.forRoot(),
        TagInputModule,
        AngularMultiSelectModule,
        TabsModule.forRoot(),
        PaginationModule.forRoot(),
        AlertModule.forRoot(),
        BsDatepickerModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot(),
        RouterModule.forChild(layoutRoutes),
        UserModule,
        GridsterModule,
        DragDropModule,
        MaterialModule,
        HighchartsChartModule,
        GridModule,
        GaugesModule,
        ExcelModule,
        PDFModule,
        PopupModule,
        MultiSelectModule,
        StoreModule.forRoot({
            loc: locationReducer,
        }),
        ChartModule,
        StockChartModule,
        ProgressBarModule,
        SparklineModule,
        TelerikReportingModule,
        SchedulerModule,
        DateInputsModule,
        InputsModule,
        ColorPickerModule,
        NgbModule,
        SliderModule,
        LabelModule,
        LiveDataModule,
        DialogModalParentComponentModule,
        CanvasPopupSettingsModule,
        CanvasGsePopupSettingsModule,
		JbtStandardObservationIdTemplateModule,
		JbtStandardObservationIdTemplateTagsModule,
        CanvasWidgetTemplatePopupSettingsModule,
        WidgetSettingsModule,
        DashboardTimescopePopupConfirmationSettingsModule,
        DashboardTimezonePopupConfirmationSettingsModule,
        TacticalDashboardIntervalPopupConfirmationSettingsModule,
        BreadCrumbsEventsTemplatePopupModule,
        AddWidgetToDashboardModule,
        RecursiveDataNavigatorModule,
        DynamicFormModule,
        DashboardEditModule,
        DashboardCopyModule,
        NinjaStatisticsContentModule,
        MulticheckFilterModule,
        LoadingSpinnerModule,
        FileUploadListModule,
        SignalRTestModule,
        PotdModule,
        LocateAllGseWidgetModule,
        TrainingCoursesModule,
        TrainingLessonsModule,
        AddTemplateToCanvasModule,
        NavigationModule,
        SharedDashboardModule,
        DirectivesModule,
        VideoPlayerModule,
        IconsModule,
        IndicatorsModule,
        CustomerIssuesListModule,
        CustomerIssuesItemModule,
        LogbookModule,
        DashboardCustomTimescopePopupConfirmationSettingsModule,
        TacticalWidgetSummaryTilePopupModule,
        BreadCrumbsEventsTemplatePopupModule,
        UserEngagementReportModule,
        CanvasTabsListModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SiteLayoutModule {}
