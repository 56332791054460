/**
 * Converts values to a key values map.
 * @param source Iterable
 * @param keySelector Async Key Selector for Map
 * @returns Map for Key to Values
 */
export const asKeyMapAsync = async (source, keySelector) => {
  const map = new Map();
  for (const item of source) {
    const key = await keySelector(item);
    const currentMapping = map.get(key);
    if (currentMapping) {
      currentMapping.push(item);
    } else {
      map.set(key, [item]);
    }
  }
  return map;
};