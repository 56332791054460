import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
export const select = (source, selector) => {
  if (typeof selector === "function") {
    if (selector.length === 1) {
      return select1(source, selector);
    } else {
      return select2(source, selector);
    }
  } else {
    return select3(source, selector);
  }
};
const select1 = (source, selector) => {
  async function* iterator() {
    for await (const value of source) {
      yield selector(value);
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const select2 = (source, selector) => {
  async function* iterator() {
    let index = 0;
    for await (const value of source) {
      yield selector(value, index);
      index++;
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const select3 = (source, key) => {
  async function* iterator() {
    for await (const value of source) {
      yield value[key];
    }
  }
  return new BasicAsyncEnumerable(iterator);
};