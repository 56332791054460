import { ErrorString, InvalidOperationException } from "../../shared";
/**
 * Invokes an async transform function on each element of a sequence and returns the maximum value.
 * @param source A sequence of values to determine the maximum value of.
 * @param selector A transform function to apply to each element.
 * @throws {InvalidOperationException} source contains no elements.
 * @returns The maximum value in the sequence.
 */
export const maxAsync = async (source, selector) => {
  let max = null;
  for await (const item of source) {
    max = Math.max(max || Number.NEGATIVE_INFINITY, await selector(item));
  }
  if (max === null) {
    throw new InvalidOperationException(ErrorString.NoElements);
  } else {
    return max;
  }
};