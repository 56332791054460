/**
 * Converts the Iteration to an Object. Duplicate values will be overriden.
 * @param source An Iterable<T> to filter.
 * @param selector A async function to determine the Key based on the value.
 * @returns Promise for Mapping of Key to Value derived from the source iterable
 */
export const toObjectAsync = async (source, selector) => {
  const map = {};
  for (const value of source) {
    map[await selector(value)] = value;
  }
  return map;
};