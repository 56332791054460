/* eslint-disable  */
/**
 * @private Next Iteration for Parallel Enumerable
 */
export const nextIterationAsync = (source, onfulfilled) => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const generator = async () => {
          const results = await dataFunc.generator();
          const newPromises = new Array(results.length);
          for (let i = 0; i < results.length; i++) {
            newPromises[i] = onfulfilled(results[i]);
          }
          return newPromises;
        };
        return {
          generator,
          type: 2 /* PromiseOfPromises */
        };
      }
    case 1 /* ArrayOfPromises */:
      {
        const generator = () => {
          const promises = dataFunc.generator();
          return promises.map(async promise => {
            const value = await promise;
            return await onfulfilled(value);
          });
        };
        return {
          generator,
          type: 1 /* ArrayOfPromises */
        };
      }
    case 2 /* PromiseOfPromises */:
      {
        const generator = async () => {
          const promises = await dataFunc.generator();
          return promises.map(promise => promise.then(onfulfilled));
        };
        return {
          generator,
          type: 2 /* PromiseOfPromises */
        };
      }
  }
};