/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */
/**
 * The class behind IAsyncEnumerable<T>
 * @private
 */
export class BasicAsyncEnumerable {
  constructor(iterator) {
    this.iterator = iterator;
    //
  }
  [Symbol.asyncIterator]() {
    return this.iterator();
  }
}