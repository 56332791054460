import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import _ from 'lodash';
import swal from 'sweetalert2';
import { UtilityService } from '../../services/utility.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Global } from "../../_constants/global.variables";
import { Observable, of } from "rxjs";
import { SelectorService } from '../../services/selector.service';
import { catchError, debounceTime, filter, map, startWith, switchMap, tap } from "rxjs/operators";
@Component({
	selector: 'lib-tactical-widget-summary-tile-popup',
	templateUrl: './breadcrumbs-events-template-popup.component.html',
	styleUrls: ['./breadcrumbs-events-template-popup.component.scss'],
})
export class BreadCrumbsEventsTemplatePopupComponent implements OnInit {
	public unsavedChanges = [];
	private swalWithBootstrapButtons: any;
	public lastSearchTerm: any;
	public showAddNewRecord: boolean = false;
	public JBTStandardObservationId: any;
	public conditionValue;
	widgetId: any;
	summaryTileIndex: any;
	breadcrumbsTemplateForm: FormGroup;
	noTemplateName: any;
	templateName: any;
	templateId: any;
	widgetTemplateForm: FormGroup;
	existingTemplateForm: FormGroup;
	public filteredList$: Observable<any[]>;
	allJBTStandardObservations: Array<any>;
	idsArray: Array<any>;
	existingTemplatesForUser: Array<any>;
	JBTStandardIdsFromTemplate: Array<any>;
	retrieveTemplates: boolean = false;
	selectedTemplateId: any;
	templateSelected: boolean;
	JBTStandardObservationIdAvailableForSelectedTemplate: boolean;
	jbtStandardObservationList: Array<any>;

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public inputObject: any,
		private dataService: DataService,
		private utilityService: UtilityService,
		private selectorService: SelectorService,
		public dialogRef: MatDialogRef<BreadCrumbsEventsTemplatePopupComponent>
	) {
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-success',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {
		this.retrieveTemplates = this.inputObject?.retrieveTemplate;
		this.breadcrumbsTemplateForm = new FormGroup({
			// templateId: new FormControl(''),
			// assetTypeId: new FormControl(''),
			// assetModelId: new FormControl(''),
			// assetId: new FormControl(''),
			templateName: new FormControl(''),
		});
		if (this.retrieveTemplates) {
			let statement =
				'API.BreadCrumbs_RetriveBreadCrumbsEventsTemplateRecord ' +
				'@CreatorUserId = ' +
				Global.User.currentUser.Id;
			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					this.existingTemplatesForUser = data;
				});
		}

		this.noTemplateName = true;
		this.templateName = null;
		this.templateId = null;
		this.idsArray = new Array();
		this.widgetTemplateForm = new FormGroup({
			JBTStandardObservationId: new FormControl('')
		});
		this.existingTemplateForm = new FormGroup({
			selectedTemplateId: new FormControl('')
		});
		this.initialize();
		this.JBTStandardObservationId = "JBTStandardObservationId";
		(this.filteredList$ = this.widgetTemplateForm.controls[this.JBTStandardObservationId].valueChanges.pipe(
			debounceTime(300),
			startWith(""),
			tap((value) => {
				this.lastSearchTerm = value;
				//this.showAddForm = !this.itemSelected && typeof this.lastSearchTerm !== "object" && this.lastSearchTerm.length > 2 && this.currentSet.selector.allowAdd;
			}),
			switchMap((name: any) => {
				if (typeof name === "string") {
					return this.search(this.lastSearchTerm);
				} else {

				}
			}),
			catchError((_) => {
				return of(null);
			})
		)),
			(err: Error) => Global.User.DebugMode && console.log(`generic-selector: ${err}`);
	}

	public displayFn(jbtStandardObservation: any): string {
		return jbtStandardObservation && jbtStandardObservation.SelectorName;
	}

	public saveSelectedValue(event: Event) {
		console.log("saveSelectedEvent: %O", event);
	}
	search(value: string): Observable<any> {
		return this.getList(value).pipe(
			// map the data.body as our return object
			map((returnedData) => {
				Global.User.DebugMode && console.log("returnedData from getList = %O", returnedData);
				return returnedData;
			}),
			// catch errors
			catchError((_) => {
				return of(null);
			})
		);
	}

	getList(searchText?: any): Observable<any> {
		var sql = "API.RDN_SelectorLookup_JBTStandardObservation" + " @SearchText=" + (searchText != null ? "'" + searchText + "'" : "null");


		if (searchText.Id != undefined) {
			const returnValue = new Observable((observer: any) => {
				return observer.next(searchText);
			});
			return returnValue;
		} else {
			Global.User.DebugMode && console.log("sql = " + sql);
			return this.selectorService.SQLActionAsObservable(sql);
		}
	}

	checkForTemplateSelected(event) {
		this.selectedTemplateId = event.target.value;
		this.templateSelected = true;
		this.JBTStandardObservationIdAvailableForSelectedTemplate = false;
		this.jbtStandardObservationList = new Array();
		let statement =
			'API.BreadCrumbs_RetriveBreadCrumbsEventsTemplateJBTStandardObservations ' +
			'@BreadCrumbsEventsTemplateId = ' +
			this.selectedTemplateId;
		this.dataService
			.SQLActionAsPromise(statement)
			.then((data: any) => {
				if (data.length > 0) {
					data.forEach((record) => {
						record.Name = this.dataService.cache.jbtStandardObservationsObject[record.JBTStandardObservationId].Name;
						this.jbtStandardObservationList.push(record.JBTStandardObservationId);
					});
						this.JBTStandardIdsFromTemplate = data;
						this.JBTStandardObservationIdAvailableForSelectedTemplate = true;
					}
				});


	}

	initialize() {
		this.allJBTStandardObservations = this.dataService.cache.jbtStandardObservations.toArray();
		this.allJBTStandardObservations = _.orderBy(this.allJBTStandardObservations, 'Name', 'asc');
	}
	submitExistingTemplateForm(templateForm) {
		this.dialogRef.close(this.jbtStandardObservationList);

	}
	submitBreadcrumbsTemplateForm(breadcrumbsTemplateForm) {
		console.log(breadcrumbsTemplateForm);
		let obj = {
			templateName: breadcrumbsTemplateForm.value.templateName,
			userId: Global.User.currentUser.Id,
		};
		var templateName = obj.templateName.split("'").join("''");
		let statement =
			'API.BreadCrumbs_AddBreadCrumbsEventsTemplateRecord ' +
			'@CreatorUserId = ' +
			obj.userId +
			", @Name = '" +
			templateName +
			"', @V2Compatible = " +
			true +
			', @IsSharableWithinOrganization = ' +
			true;
		this.dataService
			.SQLActionAsPromise(statement)
			.then((data: any) => {
				this.templateId = data[0].Id;
				this.templateName = data[0].Name;
				this.noTemplateName = false;
			});
	}

	checkForTemplateName(event) {
		this.templateName = event.target.value;
	}

	addConditionRecord() {
		this.showAddNewRecord = true;
	}

	submitForm(widgetTemplateForm) {

		let obj = {
			templateId: this.templateId,
			JBTStandardObservationId: widgetTemplateForm.value.JBTStandardObservationId ? parseInt(widgetTemplateForm.value.JBTStandardObservationId.Id) : null,
		}
		if (obj?.JBTStandardObservationId != null && obj?.templateId != null) {

			let statement = "API.BreadCrumbs_AddBreadCrumbsEventsTemplateJBTStandardObservationRecord	 "
				+ "@BreadCrumbsEventsTemplateId = " +
				obj.templateId +
				", @JBTStandardObservationId = " +
				obj.JBTStandardObservationId;

			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					this.utilityService.showToastMessageShared({
						type: 'success',
						message: "'" + obj.JBTStandardObservationId + "' Condition has been added.",
					});
					// add record to inputObject
					let idInfo = {
						JBTStandardObservationId: data[0].JBTStandardObservationId,
						Name: this.dataService.cache.jbtStandardObservationsObject[data[0].JBTStandardObservationId].Name,
						Id: data[0].Id,
					}

					this.idsArray[this.idsArray.length] = idInfo;
					this.showAddNewRecord = false;
					this.JBTStandardObservationId = "JBTStandardObservationId";
				});

		}
		else {
			this.utilityService.showToastMessageShared({
				type: 'error',
				message: "Please select a JBTStandardObservationId",
			});
		}

	}

	onDelete(breadCrumbsEventsTemplateJBTStandardObservationRecord: any) {

		this.swalWithBootstrapButtons
			.fire({
				title: 'Are you sure?',
				html: 'This will remove <strong>' + breadCrumbsEventsTemplateJBTStandardObservationRecord.JBTStandardObservationId + ' ' + ' - ' + breadCrumbsEventsTemplateJBTStandardObservationRecord.Name +
					'</strong> record',
				showCancelButton: true,
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				reverseButtons: false,
			})
			.then((result) => {
				if (result.value) {

					let statement =
						'API.BreadCrumbs_DeleteBreadCrumbsEventsTemplateJBTStandardObservationRecord @Id = ' +
						breadCrumbsEventsTemplateJBTStandardObservationRecord.Id;
					this.dataService
						.SQLActionAsPromise(statement)
						.then((data) => {
							console.log(data);

							let message = breadCrumbsEventsTemplateJBTStandardObservationRecord.JBTStandardObservationId + ' ' + ' - ' + breadCrumbsEventsTemplateJBTStandardObservationRecord.Name + " :  has been deleted.";
							this.utilityService.showToastMessageShared({
								type: 'success',
								message: message,
							});
							// remove record from inputObject
							this.idsArray = this.idsArray.filter(i => i.Id != breadCrumbsEventsTemplateJBTStandardObservationRecord.Id);
						});
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.utilityService.showToastMessageShared({
						type: 'info',
						message: "'" + breadCrumbsEventsTemplateJBTStandardObservationRecord.JBTStandardObservationId + "' has NOT been deleted.",
					});

				}
			});
	}



	public closeDialogWindow(): void {
		this.closeWindow(false);
	}

	public closeWindow(modalInfo): void {
		if (modalInfo !== false) {
			this.dialogRef.close(modalInfo);
		} else {
			this.dialogRef.close();
		}
	}
}
