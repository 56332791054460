import { BasicEnumerable } from "../BasicEnumerable";
/**
 * Produces the set union of two sequences by using scrict equality comparison or a specified IEqualityComparer<T>.
 * @param first An Iterable<T> whose distinct elements form the first set for the union.
 * @param second An Iterable<T> whose distinct elements form the second set for the union.
 * @param comparer The IEqualityComparer<T> to compare values. Optional.
 * @returns An IEnumerable<T> that contains the elements from both input sequences, excluding duplicates.
 */
export const union = (first, second, comparer) => {
  if (comparer) {
    return union2(first, second, comparer);
  } else {
    return union1(first, second);
  }
};
const union1 = (first, second) => {
  function* iterator() {
    const set = new Set();
    for (const item of first) {
      if (set.has(item) === false) {
        yield item;
        set.add(item);
      }
    }
    for (const item of second) {
      if (set.has(item) === false) {
        yield item;
        set.add(item);
      }
    }
  }
  return new BasicEnumerable(iterator);
};
const union2 = (first, second, comparer) => {
  function* iterator() {
    const result = [];
    for (const source of [first, second]) {
      for (const value of source) {
        let exists = false;
        for (const resultValue of result) {
          if (comparer(value, resultValue) === true) {
            exists = true;
            break;
          }
        }
        if (exists === false) {
          yield value;
          result.push(value);
        }
      }
    }
  }
  return new BasicEnumerable(iterator);
};