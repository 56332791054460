import { asAsyncSortedKeyValues } from "./_ordered/asAsyncSortedKeyValues";
import { asAsyncSortedKeyValuesSync } from "./_ordered/asAsyncSortedKeyValuesSync";
import { asSortedKeyValues } from "./_ordered/asSortedKeyValues";
import { asSortedKeyValuesSync } from "./_ordered/asSortedKeyValuesSync";
import { BasicAsyncEnumerable } from "./BasicAsyncEnumerable";
/**
 * Ordered Async Enumerable
 */
export class OrderedAsyncEnumerable extends BasicAsyncEnumerable {
  constructor(orderedPairs) {
    super(async function* () {
      for await (const orderedPair of orderedPairs()) {
        yield* orderedPair;
      }
    });
    this.orderedPairs = orderedPairs;
  }
  static generateAsync(source, keySelector, ascending, comparer) {
    let orderedPairs;
    if (source instanceof OrderedAsyncEnumerable) {
      orderedPairs = async function* () {
        for await (const pair of source.orderedPairs()) {
          yield* asAsyncSortedKeyValuesSync(pair, keySelector, ascending, comparer);
        }
      };
    } else {
      orderedPairs = () => asAsyncSortedKeyValues(source, keySelector, ascending, comparer);
    }
    return new OrderedAsyncEnumerable(orderedPairs);
  }
  static generate(source, keySelector, ascending, comparer) {
    let orderedPairs;
    if (source instanceof OrderedAsyncEnumerable) {
      orderedPairs = async function* () {
        for await (const pair of source.orderedPairs()) {
          yield* asSortedKeyValuesSync(pair, keySelector, ascending, comparer);
        }
      };
    } else {
      orderedPairs = () => asSortedKeyValues(source, keySelector, ascending, comparer);
    }
    return new OrderedAsyncEnumerable(orderedPairs);
  }
  thenBy(keySelector, comparer) {
    return OrderedAsyncEnumerable.generate(this, keySelector, true, comparer);
  }
  thenByAsync(keySelector, comparer) {
    return OrderedAsyncEnumerable.generateAsync(this, keySelector, true, comparer);
  }
  thenByDescending(keySelector, comparer) {
    return OrderedAsyncEnumerable.generate(this, keySelector, false, comparer);
  }
  thenByDescendingAsync(keySelector, comparer) {
    return OrderedAsyncEnumerable.generateAsync(this, keySelector, false, comparer);
  }
}