import { StrictEqualityComparer } from "../../shared";
import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
/**
 * Correlates the elements of two sequences based on matching keys.
 * A specified IEqualityComparer<T> is used to compare keys or the strict equality comparer.
 * @param outer The first sequence to join.
 * @param inner The sequence to join to the first sequence.
 * @param outerKeySelector A function to extract the join key from each element of the first sequence.
 * @param innerKeySelector A function to extract the join key from each element of the second sequence.
 * @param resultSelector A function to create a result element from two matching elements.
 * @param comparer An IEqualityComparer<T> to hash and compare keys. Optional.
 * @returns An IParallelEnumerable<T> that has elements of type TResult that
 * are obtained by performing an inner join on two sequences.
 */
export const join = (outer, inner, outerKeySelector, innerKeySelector, resultSelector, comparer = StrictEqualityComparer) => {
  const generator = async () => {
    const [innerArray, outerArray] = await Promise.all([inner.toArray(), outer.toArray()]);
    const results = new Array();
    for (const o of outerArray) {
      const outerKey = outerKeySelector(o);
      for (const i of innerArray) {
        const innerKey = innerKeySelector(i);
        if (comparer(outerKey, innerKey) === true) {
          results.push(resultSelector(o, i));
        }
      }
    }
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};