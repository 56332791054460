import { nextIterationAsync } from "./_nextIterationAsync";
import { typeDataToArray } from "./_typeDataToArray";
/**
 * Paritions the AsyncIterable<T> into a tuple of failing and passing arrays
 * based on the predicate.
 * @param source Elements to Partition
 * @param predicate Pass / Fail condition
 * @returns [pass, fail]
 */
export const partitionAsync = async (source, predicate) => {
  const dataFunc = nextIterationAsync(source, async value => {
    const passed = await predicate(value);
    return [passed, value];
  });
  const values = await typeDataToArray(dataFunc);
  const fail = [];
  const pass = [];
  for (const [passed, value] of values) {
    if (passed) {
      pass.push(value);
    } else {
      fail.push(value);
    }
  }
  return [pass, fail];
};