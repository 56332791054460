import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
import { nextIterationAsync } from "./_nextIterationAsync";
import { nextIterationWithIndexAsync } from "./_nextIterationWithIndexAsync";
export const selectAsync = (source, keyOrSelector) => {
  let generator;
  if (typeof keyOrSelector === "function") {
    if (keyOrSelector.length === 1) {
      generator = nextIterationAsync(source, keyOrSelector);
    } else {
      generator = nextIterationWithIndexAsync(source, keyOrSelector);
    }
  } else {
    generator = nextIterationAsync(source, x => x[keyOrSelector]);
  }
  return new BasicParallelEnumerable(generator);
};