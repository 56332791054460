import { OrderedAsyncEnumerable } from "../async/OrderedAsyncEnumerable";
import { asSortedKeyValues } from "./_ordered/asSortedKeyValues";
import { asSortedKeyValuesAsync } from "./_ordered/asSortedKeyValuesAsync";
import { BasicEnumerable } from "./BasicEnumerable";
/**
 * Represents Ordered Enumeration
 * @private
 */
export class OrderedEnumerable extends BasicEnumerable {
  constructor(orderedPairs) {
    super(function* () {
      for (const orderedPair of orderedPairs()) {
        yield* orderedPair;
      }
    });
    this.orderedPairs = orderedPairs;
  }
  // #region Sync
  static generate(source, keySelector, ascending, comparer) {
    let orderedPairs;
    if (source instanceof OrderedEnumerable) {
      orderedPairs = function* () {
        for (const pair of source.orderedPairs()) {
          yield* asSortedKeyValues(pair, keySelector, ascending, comparer);
        }
      };
    } else {
      orderedPairs = () => asSortedKeyValues(source, keySelector, ascending, comparer);
    }
    return new OrderedEnumerable(orderedPairs);
  }
  // #endregion
  // #region Async
  static generateAsync(source, keySelector, ascending, comparer) {
    let orderedPairs;
    if (source instanceof OrderedEnumerable) {
      orderedPairs = async function* () {
        for (const pair of source.orderedPairs()) {
          yield* asSortedKeyValuesAsync(pair, keySelector, ascending, comparer);
        }
      };
    } else {
      orderedPairs = () => asSortedKeyValuesAsync(source, keySelector, ascending, comparer);
    }
    return new OrderedAsyncEnumerable(orderedPairs);
  }
  // #endregion
  thenBy(keySelector, comparer) {
    return OrderedEnumerable.generate(this, keySelector, true, comparer);
  }
  thenByAsync(keySelector, comparer) {
    return OrderedEnumerable.generateAsync(this, keySelector, true, comparer);
  }
  thenByDescending(keySelector, comparer) {
    return OrderedEnumerable.generate(this, keySelector, false, comparer);
  }
  thenByDescendingAsync(keySelector, comparer) {
    return OrderedEnumerable.generateAsync(this, keySelector, false, comparer);
  }
}