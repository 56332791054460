/**
 * Returns the number of elements in a sequence
 * or represents how many elements in the specified sequence satisfy a condition
 * if the predicate is specified.
 * @param source A sequence that contains elements to be counted.
 * @param predicate A function to test each element for a condition. Optional.
 * @returns The number of elements in the input sequence.
 */
export const count = (source, predicate) => {
  if (predicate) {
    return count2(source, predicate);
  } else {
    return count1(source);
  }
};
const count1 = async source => {
  let total = 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for await (const _ of source) {
    total++;
  }
  return total;
};
const count2 = async (source, predicate) => {
  let total = 0;
  for await (const value of source) {
    if (predicate(value) === true) {
      total++;
    }
  }
  return total;
};