import { ErrorString, InvalidOperationException } from "../../shared";
import { nextIteration } from "./_nextIteration";
import { typeDataToArray } from "./_typeDataToArray";
export const min = async (source, selector) => {
  let dataFunc;
  if (selector) {
    dataFunc = nextIteration(source, selector);
  } else {
    dataFunc = source.dataFunc;
  }
  const data = await typeDataToArray(dataFunc);
  if (data.length === 0) {
    throw new InvalidOperationException(ErrorString.NoElements);
  }
  return Math.min.apply(null, data);
};