import { fromAsync } from "../../async/static/fromAsync";
/**
 * Converts a IEnumerable enumerable to an async one.
 * @param source A parallel IEnumerable
 * @returns IAsyncEnumerable<TSource>
 */
export const asAsync = source => {
  async function* generator() {
    for await (const value of source) {
      yield value;
    }
  }
  return fromAsync(generator);
};