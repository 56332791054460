import { typeDataToArray } from "./_typeDataToArray";
/**
 * Converts the Async Itertion to a Set
 * @param source IParallelEnumerable
 * @returns Set containing the iteration values
 */
export const toSet = async source => {
  const dataFunc = source.dataFunc;
  const values = await typeDataToArray(dataFunc);
  return new Set(values);
};