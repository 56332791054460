import { asAsyncSortedKeyValues } from "./_ordered/asAsyncSortedKeyValues";
import { asAsyncSortedKeyValuesSync } from "./_ordered/asAsyncSortedKeyValuesSync";
import { asSortedKeyValues } from "./_ordered/asSortedKeyValues";
import { asSortedKeyValuesSync } from "./_ordered/asSortedKeyValuesSync";
import { BasicParallelEnumerable } from "./BasicParallelEnumerable";
/**
 * Ordered Parallel Enumerable
 * @private
 */
export class OrderedParallelEnumerable extends BasicParallelEnumerable {
  constructor(orderedPairs) {
    super({
      generator: async () => {
        const asyncVals = orderedPairs();
        const array = [];
        for await (const val of asyncVals) {
          array.push(...val);
        }
        return array;
      },
      type: 0 /* PromiseToArray */
    });
    this.orderedPairs = orderedPairs;
  }
  static generateAsync(source, keySelector, ascending, comparer) {
    let orderedPairs;
    if (source instanceof OrderedParallelEnumerable) {
      orderedPairs = async function* () {
        for await (const pair of source.orderedPairs()) {
          yield* asAsyncSortedKeyValuesSync(pair, keySelector, ascending, comparer);
        }
      };
    } else {
      orderedPairs = () => asAsyncSortedKeyValues(source, keySelector, ascending, comparer);
    }
    return new OrderedParallelEnumerable(orderedPairs);
  }
  static generate(source, keySelector, ascending, comparer) {
    let orderedPairs;
    if (source instanceof OrderedParallelEnumerable) {
      orderedPairs = async function* () {
        for await (const pair of source.orderedPairs()) {
          yield* asSortedKeyValuesSync(pair, keySelector, ascending, comparer);
        }
      };
    } else {
      orderedPairs = () => asSortedKeyValues(source, keySelector, ascending, comparer);
    }
    return new OrderedParallelEnumerable(orderedPairs);
  }
  thenBy(keySelector, comparer) {
    return OrderedParallelEnumerable.generate(this, keySelector, true, comparer);
  }
  thenByAsync(keySelector, comparer) {
    return OrderedParallelEnumerable.generateAsync(this, keySelector, true, comparer);
  }
  thenByDescending(keySelector, comparer) {
    return OrderedParallelEnumerable.generate(this, keySelector, false, comparer);
  }
  thenByDescendingAsync(keySelector, comparer) {
    return OrderedParallelEnumerable.generateAsync(this, keySelector, false, comparer);
  }
}