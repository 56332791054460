import { ArgumentOutOfRangeException } from "../../shared";
/**
 * Returns the element at a specified index in a sequence.
 * @param source An IEnumerable<T> to return an element from.
 * @param index The zero-based index of the element to retrieve.
 * @throws {ArgumentOutOfRangeException}
 * index is less than 0 or greater than or equal to the number of elements in source.
 * @returns The element at the specified index in the sequence.
 */
export const elementAt = async (source, index) => {
  if (index < 0) {
    throw new ArgumentOutOfRangeException("index");
  }
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const values = await dataFunc.generator();
        if (index >= values.length) {
          throw new ArgumentOutOfRangeException("index");
        } else {
          return values[index];
        }
      }
    case 1 /* ArrayOfPromises */:
      {
        const promises = dataFunc.generator();
        if (index >= promises.length) {
          throw new ArgumentOutOfRangeException("index");
        } else {
          return await promises[index];
        }
      }
    case 2 /* PromiseOfPromises */:
      {
        const promises = await dataFunc.generator();
        if (index >= promises.length) {
          throw new ArgumentOutOfRangeException("index");
        } else {
          return await promises[index];
        }
      }
  }
};