import { ErrorString, InvalidOperationException } from "../../shared";
export const average = (source, selector) => {
  if (selector) {
    return average2(source, selector);
  } else {
    return average1(source);
  }
};
const average1 = async source => {
  let value;
  let count;
  for await (const item of source) {
    value = (value || 0) + item;
    count = (count || 0) + 1;
  }
  if (value === undefined) {
    throw new InvalidOperationException(ErrorString.NoElements);
  }
  return value / count;
};
const average2 = async (source, func) => {
  let value;
  let count;
  for await (const item of source) {
    value = (value || 0) + func(item);
    count = (count || 0) + 1;
  }
  if (value === undefined) {
    throw new InvalidOperationException(ErrorString.NoElements);
  }
  return value / count;
};