import { ErrorString, InvalidOperationException } from "../../shared";
export const aggregate = (source, seedOrFunc, func, resultSelector) => {
  if (resultSelector) {
    if (!func) {
      throw new ReferenceError(`TAccumulate function is undefined`);
    }
    return aggregate3(source, seedOrFunc, func, resultSelector);
  } else if (func) {
    return aggregate2(source, seedOrFunc, func);
  } else {
    return aggregate1(source, seedOrFunc);
  }
};
const aggregate1 = (source, func) => {
  let aggregateValue;
  for (const value of source) {
    if (aggregateValue) {
      aggregateValue = func(aggregateValue, value);
    } else {
      aggregateValue = value;
    }
  }
  if (aggregateValue === undefined) {
    throw new InvalidOperationException(ErrorString.NoElements);
  }
  return aggregateValue;
};
const aggregate2 = (source, seed, func) => {
  let aggregateValue = seed;
  for (const value of source) {
    aggregateValue = func(aggregateValue, value);
  }
  return aggregateValue;
};
const aggregate3 = (source, seed, func, resultSelector) => {
  let aggregateValue = seed;
  for (const value of source) {
    aggregateValue = func(aggregateValue, value);
  }
  return resultSelector(aggregateValue);
};