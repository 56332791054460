import { nextIteration } from "./_nextIteration";
/**
 * Determines whether a sequence contains any elements.
 * If predicate is specified, determines whether any element of a sequence satisfies a condition.
 * @param source The IEnumerable<T> to check for emptiness or apply the predicate to.
 * @param predicate A function to test each element for a condition.
 * @returns Whether or not the sequence contains any elements or contains any elements matching the predicate
 */
export const any = (source, predicate) => {
  if (predicate) {
    return any2(source, predicate);
  } else {
    return any1(source);
  }
};
const any1 = async source => {
  const dataFunc = source.dataFunc;
  let values;
  switch (dataFunc.type) {
    case 1 /* ArrayOfPromises */:
      values = dataFunc.generator();
      return values.length !== 0;
    case 0 /* PromiseToArray */:
    case 2 /* PromiseOfPromises */:
      values = await dataFunc.generator();
      return values.length !== 0;
  }
};
const any2 = async (source, predicate) => {
  const dataFunc = nextIteration(source, predicate);
  let values;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      values = await dataFunc.generator();
      return values.includes(true);
    case 1 /* ArrayOfPromises */:
      values = await Promise.all(dataFunc.generator());
      return values.includes(true);
    case 2 /* PromiseOfPromises */:
      values = await Promise.all(await dataFunc.generator());
      return values.includes(true);
  }
};