import { ErrorString, InvalidOperationException } from "../../shared";
import { nextIteration } from "./_nextIteration";
import { typeDataToArray } from "./_typeDataToArray";
export const average = async (source, selector) => {
  let data;
  if (selector) {
    data = nextIteration(source, selector);
  } else {
    data = source.dataFunc;
  }
  const values = await typeDataToArray(data);
  if (values.length === 0) {
    throw new InvalidOperationException(ErrorString.NoElements);
  }
  let sum = 0;
  for (const item of values) {
    sum += item;
  }
  return sum / values.length;
};