/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface */
/**
 * Base implementation of IParallelEnumerable<T>
 * @private
 */
export class BasicParallelEnumerable {
  constructor(dataFunc) {
    this.dataFunc = dataFunc;
  }
  [Symbol.asyncIterator]() {
    const {
      dataFunc
    } = this;
    async function* iterator() {
      switch (dataFunc.type) {
        case 1 /* ArrayOfPromises */:
          for (const value of dataFunc.generator()) {
            yield value;
          }
          break;
        case 2 /* PromiseOfPromises */:
          for (const value of await dataFunc.generator()) {
            yield value;
          }
          break;
        case 0 /* PromiseToArray */:
        default:
          for (const value of await dataFunc.generator()) {
            yield value;
          }
          break;
      }
    }
    return iterator();
  }
}