import { ErrorString, InvalidOperationException } from "../../shared";
/**
 * Returns the last element of a sequence that satisfies a specified condition.
 * @param source An Iterable<T> to return the last element of.
 * @param predicate A function to test each element for a condition.
 * @throws {InvalidOperationException} The source sequence is empty.
 * @returns The last element in the sequence that passes the test in the specified predicate function.
 */
export const lastAsync = async (source, predicate) => {
  let last;
  for (const value of source) {
    if ((await predicate(value)) === true) {
      last = value;
    }
  }
  if (!last) {
    throw new InvalidOperationException(ErrorString.NoMatch);
  }
  return last;
};