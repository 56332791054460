import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { PotdComponent } from "./potd.component";

@NgModule({ declarations: [PotdComponent],
    exports: [PotdComponent], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DateInputsModule,
        InputsModule,
        LabelModule,
        ButtonsModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class PotdModule { }
