import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
/**
 * Produces the set union of two sequences by using a specified IAsyncEqualityComparer<T>.
 * @param first An AsyncIterable<T> whose distinct elements form the first set for the union.
 * @param second An AsyncIterable<T> whose distinct elements form the second set for the union.
 * @param comparer The IAsyncEqualityComparer<T> to compare values.
 * @returns An IAsyncEnumerable<T> that contains the elements from both input sequences, excluding duplicates.
 */
export const unionAsync = (
// eslint-disable-next-line no-shadow
first, second, comparer) => {
  const generator = async () => {
    const result = [];
    const values = await Promise.all([first.toArray(), second.toArray()]);
    for (const source of values) {
      for (const value of source) {
        let exists = false;
        for (const resultValue of result) {
          if ((await comparer(value, resultValue)) === true) {
            exists = true;
            break;
          }
        }
        if (exists === false) {
          result.push(value);
        }
      }
    }
    return result;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};