import { BasicEnumerable } from "../BasicEnumerable";
/**
 * Returns elements from a sequence as long as a specified condition is true.
 * The element's index is used in the logic of the predicate function.
 * @param source The sequence to return elements from.
 * @param predicate A function to test each source element for a condition;
 * the second parameter of the function represents the index of the source element.
 * @returns An IEnumerable<T> that contains elements from the input sequence
 * that occur before the element at which the test no longer passes.
 */
export const takeWhile = (source, predicate) => {
  if (predicate.length === 1) {
    return takeWhile1(source, predicate);
  } else {
    return takeWhile2(source, predicate);
  }
};
const takeWhile1 = (source, predicate) => {
  /**
   * @internal
   */
  function* iterator() {
    for (const item of source) {
      if (predicate(item)) {
        yield item;
      } else {
        break;
      }
    }
  }
  return new BasicEnumerable(iterator);
};
const takeWhile2 = (source, predicate) => {
  function* iterator() {
    let index = 0;
    for (const item of source) {
      if (predicate(item, index++)) {
        yield item;
      } else {
        break;
      }
    }
  }
  return new BasicEnumerable(iterator);
};