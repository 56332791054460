import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
export const selectMany = (source, selector) => {
  if (typeof selector === "function") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (selector.length === 1) {
      return selectMany1(source, selector);
    } else {
      return selectMany2(source, selector);
    }
  } else {
    return selectMany3(source, selector);
  }
};
const selectMany1 = (source, selector) => {
  async function* iterator() {
    for await (const value of source) {
      for (const selectorValue of selector(value)) {
        yield selectorValue;
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const selectMany2 = (source, selector) => {
  async function* iterator() {
    let index = 0;
    for await (const value of source) {
      for (const selectorValue of selector(value, index)) {
        yield selectorValue;
      }
      index++;
    }
  }
  return new BasicAsyncEnumerable(iterator);
};
const selectMany3 = (source, selector) => {
  async function* iterator() {
    for await (const value of source) {
      for (const selectorValue of value[selector]) {
        yield selectorValue;
      }
    }
  }
  return new BasicAsyncEnumerable(iterator);
};