import { ErrorString, InvalidOperationException } from "../../shared";
import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
export const fromAsync = promisesOrIterable => {
  if (Array.isArray(promisesOrIterable)) {
    if (promisesOrIterable.length === 0) {
      throw new InvalidOperationException(ErrorString.NoElements);
    }
    return new BasicAsyncEnumerable(async function* () {
      for await (const value of promisesOrIterable) {
        yield value;
      }
    });
  } else {
    return new BasicAsyncEnumerable(promisesOrIterable);
  }
};