import { ArrayEnumerable } from "../sync/ArrayEnumerable";
/**
 * Binds LINQ method to a built in array type
 * @param jsArray Built In JS Array Type
 */
export const bindArray = jsArray => {
  var _a;
  const arrayEnumerablePrototype = ArrayEnumerable.prototype;
  const bindToPrototype = jsArray.prototype;
  const propertyNames = Object.getOwnPropertyNames(arrayEnumerablePrototype);
  for (const prop of propertyNames) {
    bindToPrototype[prop] = (_a = bindToPrototype[prop]) !== null && _a !== void 0 ? _a : arrayEnumerablePrototype[prop];
  }
};