import { StrictEqualityComparer } from "../../shared";
/**
 * Determines whether or not two sequences are equal
 * @param first first iterable
 * @param second second iterable
 * @param comparer Compare function to use, by default is @see {StrictEqualityComparer}
 * @returns Whether or not the two iterables are equal
 */
export const sequenceEquals = (first, second, comparer = StrictEqualityComparer) => {
  const firstIterator = first[Symbol.iterator]();
  const secondIterator = second[Symbol.iterator]();
  let firstResult = firstIterator.next();
  let secondResult = secondIterator.next();
  while (!firstResult.done && !secondResult.done) {
    if (!comparer(firstResult.value, secondResult.value)) {
      return false;
    }
    firstResult = firstIterator.next();
    secondResult = secondIterator.next();
  }
  return firstResult.done === true && secondResult.done === true;
};