import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
/**
 * Returns elements from a sequence as long as a specified condition is true.
 * The element's index is used in the logic of the predicate function.
 * @param source The sequence to return elements from.
 * @param predicate A function to test each source element for a condition;
 * the second parameter of the function represents the index of the source element.
 * @returns An IAsyncEnumerable<T> that contains elements from the input sequence
 * that occur before the element at which the test no longer passes.
 */
export const takeWhile = (source, predicate) => {
  const generator = async () => {
    const values = await source.toArray();
    const results = new Array();
    if (predicate.length === 1) {
      for (const value of values) {
        if (predicate(value) === true) {
          results.push(value);
        } else {
          break;
        }
      }
    } else {
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        if (predicate(value, i) === true) {
          results.push(value);
        } else {
          break;
        }
      }
    }
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};