import { ErrorString, InvalidOperationException } from "../../shared";
export const max = (source, selector) => {
  if (selector) {
    return max2(source, selector);
  } else {
    return max1(source);
  }
};
const max1 = source => {
  let maxItem = null;
  for (const item of source) {
    maxItem = Math.max(maxItem || Number.NEGATIVE_INFINITY, item);
  }
  if (maxItem === null) {
    throw new InvalidOperationException(ErrorString.NoElements);
  } else {
    return maxItem;
  }
};
const max2 = (source, selector) => {
  let maxItem = null;
  for (const item of source) {
    maxItem = Math.max(maxItem || Number.NEGATIVE_INFINITY, selector(item));
  }
  if (maxItem === null) {
    throw new InvalidOperationException(ErrorString.NoElements);
  } else {
    return maxItem;
  }
};