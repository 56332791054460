/**
 * @private
 */
export const typeDataToArray = async dataFunc => {
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      return await dataFunc.generator();
    case 1 /* ArrayOfPromises */:
      return await Promise.all(dataFunc.generator());
    case 2 /* PromiseOfPromises */:
      const data = await dataFunc.generator();
      return await Promise.all(data);
  }
};