import { ArrayEnumerable } from "./ArrayEnumerable";
/**
 * Key to Values Enumeration
 * @private
 */
export class Grouping extends ArrayEnumerable {
  constructor(key, startingItem) {
    super(1);
    this.key = key;
    this[0] = startingItem;
  }
}