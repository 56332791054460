import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
export const zip = (first, second, resultSelector) => {
  if (resultSelector) {
    return zip2(first, second, resultSelector);
  } else {
    return zip1(first, second);
  }
};
const zip1 = (source, second) => {
  const generator = async () => {
    const [left, right] = await Promise.all([source.toArray(), second.toArray()]);
    const maxLength = left.length > right.length ? left.length : right.length;
    const results = new Array(maxLength);
    for (let i = 0; i < maxLength; i++) {
      const a = left[i];
      const b = right[i];
      results[i] = [a, b];
    }
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};
const zip2 = (source, second, resultSelector) => {
  const generator = async () => {
    const [left, right] = await Promise.all([source.toArray(), second.toArray()]);
    const maxLength = left.length > right.length ? left.length : right.length;
    const results = new Array(maxLength);
    for (let i = 0; i < maxLength; i++) {
      const a = left[i];
      const b = right[i];
      results[i] = resultSelector(a, b);
    }
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};