export const sum = (source, selector) => {
  if (selector) {
    return sum2(source, selector);
  } else {
    return sum1(source);
  }
};
const sum1 = async source => {
  let totalSum = 0;
  for (const value of await source.toArray()) {
    totalSum += value;
  }
  return totalSum;
};
const sum2 = async (source, selector) => {
  let total = 0;
  for (const value of await source.toArray()) {
    total += selector(value);
  }
  return total;
};