import { Grouping } from "../../sync/Grouping";
import { BasicParallelEnumerable } from "../BasicParallelEnumerable";
import { nextIterationAsync } from "./_nextIterationAsync";
export const groupByAsync = (source, keySelector, comparer) => {
  if (comparer) {
    return groupByAsync_0(source, keySelector, comparer);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return groupByAsync_0_Simple(source, keySelector);
  }
};
const groupByAsync_0 = (source, keySelector, comparer) => {
  const generator = async () => {
    const typedData = nextIterationAsync(source, async value => {
      const key = await keySelector(value);
      return [key, value];
    });
    let values;
    switch (typedData.type) {
      case 1 /* ArrayOfPromises */:
        values = await Promise.all(typedData.generator());
        break;
      case 2 /* PromiseOfPromises */:
        values = await Promise.all(await typedData.generator());
        break;
    }
    const keyMap = new Array();
    for (const [key, value] of values) {
      let found = false;
      for (let i = 0; i < keyMap.length; i++) {
        const group = keyMap[i];
        if ((await comparer(group.key, key)) === true) {
          group.push(value);
          found = true;
          break;
        }
      }
      if (found === false) {
        keyMap.push(new Grouping(key, value));
      }
    }
    const results = new Array();
    for (const g of keyMap) {
      results.push(g);
    }
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};
const groupByAsync_0_Simple = (source, keySelector) => {
  const generator = async () => {
    const typedData = nextIterationAsync(source, async value => {
      const key = await keySelector(value);
      return [key, value];
    });
    let values;
    switch (typedData.type) {
      case 1 /* ArrayOfPromises */:
        values = await Promise.all(typedData.generator());
        break;
      case 2 /* PromiseOfPromises */:
        values = await Promise.all(await typedData.generator());
        break;
    }
    const keyMap = {};
    for (const [key, value] of values) {
      const grouping = keyMap[key];
      if (grouping) {
        grouping.push(value);
      } else {
        keyMap[key] = new Grouping(key, value);
      }
    }
    const results = new Array();
    /* eslint-disable guard-for-in */
    for (const value in keyMap) {
      results.push(keyMap[value]);
    }
    /* eslint-enable guard-for-in */
    return results;
  };
  return new BasicParallelEnumerable({
    generator,
    type: 0 /* PromiseToArray */
  });
};