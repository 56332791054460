/* eslint-disable  */
export const nextIterationWithIndex = (source, onfulfilled) => {
  const dataFunc = source.dataFunc;
  switch (dataFunc.type) {
    case 0 /* PromiseToArray */:
      {
        const generator = () => dataFunc.generator().then(x => {
          const convValues = new Array(x.length);
          for (let i = 0; i < x.length; i++) {
            convValues[i] = onfulfilled(x[i], i);
          }
          return convValues;
        });
        return {
          generator,
          type: 0 /* PromiseToArray */
        };
      }
    case 1 /* ArrayOfPromises */:
      {
        const generator = () => {
          const previousData = dataFunc.generator();
          const newPromises = new Array(previousData.length);
          for (let i = 0; i < previousData.length; i++) {
            newPromises[i] = previousData[i].then(value => {
              return onfulfilled(value, i);
            });
          }
          return newPromises;
        };
        return {
          generator,
          type: 1 /* ArrayOfPromises */
        };
      }
    case 2 /* PromiseOfPromises */:
      {
        const generator = async () => {
          const previousData = await dataFunc.generator();
          const newPromises = new Array(previousData.length);
          for (let i = 0; i < previousData.length; i++) {
            newPromises[i] = previousData[i].then(value => onfulfilled(value, i));
          }
          return newPromises;
        };
        return {
          generator,
          type: 2 /* PromiseOfPromises */
        };
      }
  }
};