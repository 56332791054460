import { Grouping } from "../../sync/Grouping";
import { BasicAsyncEnumerable } from "../BasicAsyncEnumerable";
export const groupByWithSel = (source, keySelector, elementSelector, comparer) => {
  if (comparer) {
    return groupBy1(source, keySelector, elementSelector, comparer);
  } else {
    return groupBy1Simple(source, keySelector, elementSelector);
  }
};
const groupBy1Simple = (source, keySelector, elementSelector) => {
  async function* generate() {
    const keyMap = {};
    for await (const value of source) {
      const key = keySelector(value);
      const grouping = keyMap[key];
      const element = elementSelector(value);
      if (grouping) {
        grouping.push(element);
      } else {
        keyMap[key] = new Grouping(key, element);
      }
    }
    // eslint-disable-next-line guard-for-in
    for (const value in keyMap) {
      yield keyMap[value];
    }
  }
  return new BasicAsyncEnumerable(generate);
};
const groupBy1 = (source, keySelector, elementSelector, comparer) => {
  async function* generate() {
    const keyMap = new Array();
    for await (const value of source) {
      const key = keySelector(value);
      let found = false;
      for (let i = 0; i < keyMap.length; i++) {
        const group = keyMap[i];
        if (comparer(group.key, key)) {
          group.push(elementSelector(value));
          found = true;
          break;
        }
      }
      if (found === false) {
        const element = elementSelector(value);
        keyMap.push(new Grouping(key, element)); // TODO
      }
    }
    for (const value of keyMap) {
      yield value;
    }
  }
  return new BasicAsyncEnumerable(generate);
};