import { fromAsync } from "../../async/static/fromAsync";
/**
 * Projects each element of a sequence to an IAsyncEnumerable<T> and flattens the resulting sequences into one sequence.
 * @param source A sequence of values to project.
 * @param selector A transform function to apply to each element.
 * @returns An IAsyncEnumerable<T> whose elements are the result of invoking the
 * one-to-many transform function on each element of the input sequence.
 */
export const selectManyAsync = (source, selector) => {
  if (selector.length === 1) {
    return selectManyAsync1(source, selector);
  } else {
    return selectManyAsync2(source, selector);
  }
};
const selectManyAsync1 = (source, selector) => {
  async function* generator() {
    for (const value of source) {
      const innerValues = await selector(value);
      for (const innerValue of innerValues) {
        yield innerValue;
      }
    }
  }
  return fromAsync(generator);
};
const selectManyAsync2 = (source, selector) => {
  async function* generator() {
    let index = 0;
    for (const value of source) {
      const innerValues = await selector(value, index);
      for (const innerValue of innerValues) {
        yield innerValue;
      }
      index++;
    }
  }
  return fromAsync(generator);
};