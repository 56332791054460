/**
 * Converts an Async Iterable to a key value pair object
 * @param source Iteration to Convert to an Object
 * @param selector Key Selector
 * @returns KVP Object Promise
 */
export const toObject = async (source, selector) => {
  const map = {};
  for await (const value of source) {
    map[selector(value)] = value;
  }
  return map;
};